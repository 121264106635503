import { formatNumber, getFormattedDate } from "@/utils";

const BrokerOrders = ({ hkbOrderId,orders }) => {

  console.log('BrokerOrders hkbOrderId :>> ', hkbOrderId);
  console.log('BrokerOrders :>> ', orders);
  
  return (
    <table className="portfolio-table">
      <thead>
        <tr>
          <th>Symbol</th>
          <th className="text-center">S</th>
          <th>QTY</th>
          <th>AP</th>
          <th>Type</th>
          <th>LP</th>
          <th>Status</th>
          <th>CT</th>
          <th>ET</th>
        </tr>
      </thead>
      <tbody>
        {orders &&
          orders.map((order) => (
            <tr key={order.tradingSymbol}>
              <td>{order.tradingSymbol}</td>
              <td className="text-center">
                {order.orderSide && order.orderSide[0]}
              </td>
              <td className="text-right">{order.quantity-order.pendingQuantity}/{order.quantity}</td>
              <td className="text-right">{formatNumber(order.averagePrice)}</td>
              <td>{order.orderType}</td>
              <td className="text-right">{formatNumber(order.price)}</td>
                    
              <td className="text-right">
                {order.status}
              </td>
              <td className="text-right">{getFormattedDate(order.userEnteredTimestamp)}</td>
              <td className="text-right">{getFormattedDate(order.orderExecutedTime)}</td>
        
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default BrokerOrders;
