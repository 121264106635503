import { atom } from "recoil";

export const strategyContainerState = atom({
  key: "strategyContainerState",
  default: { expanded: true, fullScreen: true },
});

// An atom representing the currently expanded rows by their keys
export const expandedRowsState = atom({
  key: "expandedRowsState",
  default: new Set(), // using a Set to store unique row keys
});

// An atom representing whether the view is in fullscreen mode
export const fullscreenState = atom({
  key: "fullscreenState",
  default: false,
});

export const positionViewType = atom({
  key: 'positionViewType',
  default: 'strategy', // Initial value
});