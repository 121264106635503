import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { Notification, NotificationType, notificationState } from '@/state/positionstate';

interface UseNotificationReturn {
  notification: Notification;
  showNotification: (type: NotificationType, text: string, duration?: number) => void;
}

const useNotification = (): UseNotificationReturn => {
  const [notification, setNotification] = useRecoilState(notificationState);

  const showNotification = (type: NotificationType, text: string, duration: number = 100) => {
    setNotification({ type, text });

    setTimeout(() => {
      setNotification({ type, text: '' });
    }, duration);
  };

  return { notification, showNotification };
};

export default useNotification;
