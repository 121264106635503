import axios, { AxiosError, AxiosResponse } from "axios";
import { getAccessToken } from "./localStorageUtil";
import { Modal } from 'antd';

const handleAuthError = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("authState");
  window.location.href = "/";
};

axios.interceptors.response.use(
  (response: any) => {
    // Handle the Unauthorized error
    if (
      response &&
      response.data &&
      (response.data.httpStatusCode === 401 ||
        response.data.httpStatusCode === 403)
    ) {
      
      Modal.error({
        zIndex:10000,
        title: 'Authentication Error',
        content: `${response.data.message}. Please log in again.`,
        onOk: () => {
          handleAuthError();
        },
        onCancel: () => {
          handleAuthError();
        }
      });
    }

    if(response && response.data) {
      if(response.data.status !== "success") {
        const error = new Error(response.data.message);

        throw error;
      }
    }

    return response;
  },
  (error: AxiosError) => {
    throw error;
  }
);

const request = (endPoint: string, params?: any) => {
  if (!params) {
    params = {};
  }

  let host = process.env.HTTP_API_ORIGIN || window.location.origin;

  if (host.includes("localhost")) {
    host = host.replace(window.location.port, "8091");
  }

  const path = process.env.HTTP_API_PATH_PREFIX ?? "/v2";

  let storedAuthState = localStorage.getItem("authState");
  let email = "";
  if (storedAuthState) {
    const jsonData = JSON.parse(storedAuthState);
    email = jsonData.userData.email;
  }

  const uri = `${host}${path}/${endPoint}${
    endPoint.indexOf("?") > -1 ? "&" : "?"
  }authAccount=${email}`

  // Get the access token
  const token = getAccessToken();

  const headers = {
    "Content-Type": params?.contentType ?? "application/json",
    // Include the token in the Authorization header
    Authorization: `Bearer ${token}`,
    ...params.headers,
  };

  const method = params.method || "GET";

  const axiosConfig = {
    method,
    url: uri,
    data:
      method === "PATCH" ||
      method === "POST" ||
      method === "PUT" ||
      method === "DELETE"
        ? params.body
        : undefined,
    params: method === "GET" ? params.body : undefined,
    headers,
  };

  if (params?.abortSignal != undefined) {
    axiosConfig["signal"] = params["abortSignal"];
  }

  if (params?.timeout != undefined) {
    axiosConfig["timeout"] = params?.timeout;
  }

  return axios(axiosConfig).then((response: AxiosResponse) => {
    if (response.status >= 200 && response.status < 300) {

      return response;
    }
    const error = new Error(response.statusText);

    throw error;
  });
};

export default request;
