import { useState, useEffect } from 'react';
import { fetchAccounts } from '@/api/optionChain';
import useNotification from '@/hooks/useNotification';

export const useFetchAccounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [multipliers, setMultipliers] = useState([]);
  const [accountsFetchInProgress, setAccountsFetchInProgress] = useState(false);
  const { showNotification } = useNotification();

  useEffect(() => {
    const fetchAccountsData = async () => {
      setAccountsFetchInProgress(true);
      try {
        const fetchResult = await fetchAccounts();
        const enabledAccounts = fetchResult?.accountsList.filter(acc => acc?.enabled) || [];
        const initialMultipliers = enabledAccounts.map(acc => ({
          accountId: acc.accountId,
          multiplier: acc.defaultMultiplier,
          priority: acc.priority || 0,
          originalMultiplier: acc.defaultMultiplier,
        }));
        setAccounts(enabledAccounts);
        setMultipliers(initialMultipliers);
      } catch (error) {
        showNotification("error", error?.message);
      } finally {
        setAccountsFetchInProgress(false);
      }
    };

    fetchAccountsData();
  }, []);

  return { accounts, multipliers, accountsFetchInProgress };
};
