import { actionButtonClickCountState, allPositionsSelectedStrategyState, defaultActionState, lastClickedButtonActionState, selectedInstrumentState, selectedPositionsState, showStrategyCreationPanelState } from "@/state/positionstate";
import { Button, Flex, Tooltip } from "antd";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useNotification from "@/hooks/useNotification";

export const ActionButtonGroup = ({ strategyName, strategyInstanceName, parentHKBInstrumentId, disabled, allPositions }) => {
  const setLastClickedButtonAction = useSetRecoilState(lastClickedButtonActionState);
  const setAllPositionsSelectedStrategy = useSetRecoilState(allPositionsSelectedStrategyState);
  const setButtonClickCount = useSetRecoilState(actionButtonClickCountState);
  const setDefaultActionState = useSetRecoilState(defaultActionState);
  const setInstrumentState = useSetRecoilState(selectedInstrumentState);
  const setStrategyCreationPanel = useSetRecoilState(showStrategyCreationPanelState);
  const { showNotification } = useNotification();
  const positions = useRecoilValue(selectedPositionsState);

  const handleButtonClick = (action: 'Adjust' | 'Modify' | 'Close') => {

    let hkbInstrumentId = parentHKBInstrumentId;

    // console.log('parentHKBInstrumentId :>> ', parentHKBInstrumentId);


    if (positions && positions.length > 0) {
      const { strategyInstanceName } = positions[0];

      if (strategyInstanceName !== strategyInstanceName) {
        showNotification("error", "Selected positions belong to different strategies.");

        return
      }
      // hkbInstrumentId = parentHkbInstrumentId

      // setDefaultActionState({
      //   strategyInstanceName,
      //   hkbInstrumentId
      // })
    }

    // else if(action==='Modify') {
    //   setDefaultActionState({
    //     strategyInstanceName: strategyName,
    //     hkbInstrumentId
    //   })
    // }

    setLastClickedButtonAction(action);
    setAllPositionsSelectedStrategy(allPositions);
    setButtonClickCount(count => count + 1);

    setDefaultActionState({
      strategyInstanceName: strategyInstanceName,
      hkbInstrumentId,
      strategyName
    })

    setInstrumentState(hkbInstrumentId)
    setStrategyCreationPanel(false)
  };

  return (
    /* comment for now as Adjust is not implemented yet
      <Button disabled={disabled} type="primary" size="small" onClick={() => handleButtonClick('Adjust')}>
      A
    </Button>
      */
    <Flex gap="small" wrap="wrap">

      <Button disabled={disabled} type="primary" size="small" onClick={() => handleButtonClick('Modify')}>
        <Tooltip placement="topLeft" title="Modify Positions">M </Tooltip>
      </Button>
      <Button disabled={disabled} type="primary" size="small" onClick={() => handleButtonClick('Close')}>
        <Tooltip placement="topLeft" title="Close Positions">C</Tooltip>
      </Button>
    </Flex>
  );
};
