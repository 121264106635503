import { isDebugModeState } from "@/state/positionstate";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

const useDebugMode = () => {
  const setIsDebugMode = useSetRecoilState(isDebugModeState);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    console.log(
      "searchParams :>> ",
      searchParams.get("debug"),
      searchParams.get("debug") == "true"
    );
    setIsDebugMode(searchParams.get("debug") == "true");
  }, []);
};

export default useDebugMode;
