// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FinancialOverview.css */
.accountInfoCompact {
  display: grid;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.accountInforMarginRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0px; /* Reduced padding for a more compact layout */
  gap: 0px; /* Reduced gap for compactness */
  align-items: center; /* Centers items vertically in their grid area */
  margin: 0;
  border: 0;
}

.financialOverviewCompact {
    display: grid;
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  
  .gridLine {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates a 3-column layout */
    padding: 4px; /* Reduced padding for a more compact layout */
    gap: 4px; /* Reduced gap for compactness */
    align-items: center; /* Centers items vertically in their grid area */
  }
  
  .gridLine > span {
    text-align: right; /* Aligns text to the right for a neat appearance */
    white-space: nowrap; /* Prevents text wrapping */
  }
  
  .gridLine > span:first-child {
    text-align: right; /* Aligns the first item to the left */
  }
  
  .greyed {
    background-color: #f0f0f0; /* Grey background for the second line */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/PositionDetail/FinancialOverview.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,YAAY,EAAE,8CAA8C;EAC5D,QAAQ,EAAE,gCAAgC;EAC1C,mBAAmB,EAAE,gDAAgD;EACrE,SAAS;EACT,SAAS;AACX;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,qCAAqC,EAAE,8BAA8B;IACrE,YAAY,EAAE,8CAA8C;IAC5D,QAAQ,EAAE,gCAAgC;IAC1C,mBAAmB,EAAE,gDAAgD;EACvE;;EAEA;IACE,iBAAiB,EAAE,mDAAmD;IACtE,mBAAmB,EAAE,2BAA2B;EAClD;;EAEA;IACE,iBAAiB,EAAE,sCAAsC;EAC3D;;EAEA;IACE,yBAAyB,EAAE,wCAAwC;EACrE","sourcesContent":["/* FinancialOverview.css */\n.accountInfoCompact {\n  display: grid;\n  font-family: Arial, sans-serif;\n  font-size: 14px;\n}\n\n.accountInforMarginRow {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  padding: 0px; /* Reduced padding for a more compact layout */\n  gap: 0px; /* Reduced gap for compactness */\n  align-items: center; /* Centers items vertically in their grid area */\n  margin: 0;\n  border: 0;\n}\n\n.financialOverviewCompact {\n    display: grid;\n    font-family: Arial, sans-serif;\n    font-size: 14px;\n  }\n  \n  .gridLine {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr); /* Creates a 3-column layout */\n    padding: 4px; /* Reduced padding for a more compact layout */\n    gap: 4px; /* Reduced gap for compactness */\n    align-items: center; /* Centers items vertically in their grid area */\n  }\n  \n  .gridLine > span {\n    text-align: right; /* Aligns text to the right for a neat appearance */\n    white-space: nowrap; /* Prevents text wrapping */\n  }\n  \n  .gridLine > span:first-child {\n    text-align: right; /* Aligns the first item to the left */\n  }\n  \n  .greyed {\n    background-color: #f0f0f0; /* Grey background for the second line */\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
