// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.live-icon-container {
    justify-content: center;
}

.live-status-icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: block;
}

.live-color {
    background-color: #6ff15d;
}

.offline-color {
    color: 'saffron';
    animation-name: blink;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-iteration-count: Infinite;
}`, "",{"version":3,"sources":["webpack://./src/components/LiveStatusIcon/style.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,sBAAsB;IACtB,kCAAkC;IAClC,mCAAmC;AACvC","sourcesContent":[".live-icon-container {\n    justify-content: center;\n}\n\n.live-status-icon {\n    width: 16px;\n    height: 16px;\n    border-radius: 50%;\n    display: block;\n}\n\n.live-color {\n    background-color: #6ff15d;\n}\n\n.offline-color {\n    color: 'saffron';\n    animation-name: blink;\n    animation-duration: 1s;\n    animation-timing-function: ease-in;\n    animation-iteration-count: Infinite;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
