import fetchAccounts from "@/api/fetchAccounts";
import AccountContainer from "@/components/AccountContainer";
import StrategyContainer from "@/components/StrategyContainer";
import portfolioData from "@/components/StrategyContainer/portfolioData";
// import portfolioData from "@/components/StrategyContainer/portfolioData";
import useNotification from "@/hooks/useNotification";
import { useWebsocket } from "@/hooks/useWebsocket";
import { positionViewType } from "@/state/layoutState";
import { accountMultipliersState, accountsState, portfolioState, positionLastUpdatedState, positionLiveStatus } from "@/state/positionstate";
import { notification } from "antd";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

function sortData(data) {
  if (!data || !data.portfolios) return {};

  // Sort portfolios by accountId
  // data.portfolios.sort((a, b) => a.accountId.localeCompare(b.accountId));

  // Sort each portfolio's strategyInstances by strategyInstanceName
  data.portfolios.forEach((portfolio) => {
    portfolio.strategyInstances &&
      portfolio.strategyInstances.sort((a, b) => {
        const dateA = new Date(a.creationTime);
        const dateB = new Date(b.creationTime);
      
        return dateB.getTime() - dateA.getTime();
      });

    // Sort each strategyInstance's hkbPositions by tradingSymbol
    // portfolio.strategyInstances.forEach((strategyInstance) => {
    //   strategyInstance.hkbPositions &&
    //     strategyInstance.hkbPositions.sort((a, b) =>
    //       a.tradingSymbol.localeCompare(b.tradingSymbol)
    //     );
    // });
  });

  return data;
}

const PositionContainer = () => {
  const setAccounts = useSetRecoilState(accountsState);
  const setAccountMultipliers = useSetRecoilState(accountMultipliersState);

  const viewType = useRecoilValue(positionViewType);

  const [portfolios, setPortfolios] = useRecoilState(portfolioState);
  const setPositionLiveStatus = useSetRecoilState(
    positionLiveStatus
  );
  const setPositionLastUpdated = useSetRecoilState(
    positionLastUpdatedState
  );
  const { showNotification } = useNotification()
  
  const handleWsMessage = (data) => {
    const sortedData = sortData(data)
    // const sortedData = data

    if(data && data.lastUpdateTime) {
      setPositionLastUpdated(data.lastUpdateTime)
    }

    setPortfolios(sortedData.portfolios);
    // setPortfolios(portfolioData)
  };

  const handleError = () => {
    showNotification("error","Experiencing Issues with Live Position Updates")
  };

  useWebsocket(
    "ws/strategy/positions",
    handleWsMessage,
    handleError,
    setPositionLiveStatus,
  )

  // useEffect(() => {
  //   setPortfolios(portfolioData)
  // }, []);

  useEffect(() => {
    (async () => {

      try {
        const accounts = await fetchAccounts();
        const enabledAccounts = accounts.filter(account => account?.enabled) || [];
        const multipliersMap = new Map();

        enabledAccounts.forEach(account => {
          multipliersMap.set(account.accountId, {
            accountId: account.accountId,
            multiplier: account.defaultMultiplier,
            priority: account.priority || 0,
            originalMultiplier: account.defaultMultiplier,
          });
        });
  
        setAccountMultipliers(multipliersMap);
        setAccounts(enabledAccounts);
      } catch (error) {
        showNotification("error", error?.message);
      }

    })();
  }, []);

  switch (viewType) {
    case "strategy":
      return <StrategyContainer positions={portfolios} />;
    case "account":
      return <AccountContainer positions={portfolios} />;
    default:
      return <StrategyContainer positions={portfolios} />;
  }
};

export default PositionContainer;
