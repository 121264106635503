// useMarginRequirements.js
import { useState, useEffect } from "react";
import { fetchMarginRequired } from "@/api/optionChain";
import { createStrategyReqBodyAndType } from "./dtos";
import useNotification from "@/hooks/useNotification";

export const useMarginRequirements = (accounts, multipliers, strategyName) => {
  const [marginRequiredPerAccount, setMarginRequiredPerAccount] = useState({});
  const { showNotification } = useNotification();

  useEffect(() => {
    const fetchMarginRequiredData = async () => {
      if (!accounts.length || !multipliers.length || !strategyName) return;

      const marginRequiredAPIPayload = createStrategyReqBodyAndType(
        accounts,
        [],
        multipliers,
        strategyName
      );
      try {
        const marginInfo = await fetchMarginRequired(marginRequiredAPIPayload);
        const perAccountMarginRequired = marginInfo?.marginInfo?.reduce(
          (acc, mi) => {
            acc[mi.accountId] = {
              ...mi,
              marginRequiredRounded: Number(mi.marginRequired.toFixed(2)),
            };
            return acc;
          },
          {}
        );

        setMarginRequiredPerAccount(perAccountMarginRequired);
      } catch (error) {
        showNotification("warning", "Failed to fetch MarginRequired");
      }
    };

    fetchMarginRequiredData();
  }, [accounts, multipliers, strategyName]);

  return marginRequiredPerAccount;
};
