import {
  CloseOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Flex, Popover, Space } from "antd";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import Tabs from "../Tabs";
import "./styles.css";


const StrategyRow = React.memo(
  ({ strategyId, strategyDetails, onExpandClick, isExpanded }) => (
    <div className="div-table-row justify-start">
      <div
        className="div-table-cell div-table-cell-fixed div-table-cell-center"
        onClick={() => onExpandClick(strategyId)}
      >
        {isExpanded ? <MinusOutlined /> : <PlusOutlined />}
      </div>
      <div className="div-table-cell div-table-cell-nowrap ">
        <Flex gap="small" wrap="wrap" justify="space-between">
          <span onClick={() => onExpandClick(strategyId)}>
            {strategyId || "-"}
          </span>{" "}
        </Flex>
      </div>
      <div className="div-table-cell">{strategyDetails.approxPL}%</div>
      {/* <div className="div-table-cell div-table-cell-center">
      </div> */}
    </div>
  )
);
const StrategyDetails = React.memo(
  ({
    strategyId,
    strategyDetails,
    onColumnExpand,
    isColumnExpanded,
    onFullScreenClick,
    isFullScreen,
  }) => {

    const [selectedTab, setSelectedTab] = useState();

    const handleTabChange = (selectedTab) => {
      setSelectedTab(selectedTab);
    };

    // const popoverRef = useRef(null);

    // useEffect(() => {
    //   if (isColumnExpanded) {
    //     const adjustPopoverPosition = () => {
    //       // Assuming the popover has a unique class or ID you can target
    //       const popover = document.querySelector('.ant-popover') as HTMLElement;
    //       if (popover) {
    //         const bounding = popover.getBoundingClientRect();
    //         if (bounding.top <= 0) {
    //           popover.style.top = `10px`;
    //           // Adjust using transform if you need to maintain the original positioning logic
    //           // popover.style.transform = `translateY(${Math.abs(bounding.top)}px)`;
    //         }
    //       }
    //     console.log('popover :>> ', popover.style.top);

    //     };


    //     const timeoutId = setTimeout(adjustPopoverPosition, 10); // Adjust delay as needed

    //   // return () => clearTimeout(timeoutId);

    //     adjustPopoverPosition();
    //     // Optional: Listen to resize events to re-adjust if necessary
    //     // window.addEventListener('resize', adjustPopoverPosition);

    //     return () => {
    //       window.removeEventListener('resize', adjustPopoverPosition);
    //       clearTimeout(timeoutId);
    //     };
    //   }
    // }, [isColumnExpanded]); 


    return (
      <Flex justify="flex-start">
        {!isColumnExpanded && (
          <div
            style={{ marginLeft: "14px", cursor: "pointer" }}
            onClick={() => onColumnExpand(strategyId)}
          >
            <PlusOutlined />
          </div>
        )}
        {!isColumnExpanded && (
          <div className="div-table-row detail-row">
            <div className="div-table-flex-column">
              {/* <div style={{ marginLeft: "5px" }} onClick={() => onColumnExpand(strategyId)} > <PlusOutlined /> </div> */}
              <Tabs
                data={strategyDetails}
                isColumnExpanded={isColumnExpanded}
                onChange={handleTabChange}
                selectedTab={selectedTab}
              />
            </div>
          </div>
        )}
        {isColumnExpanded && (
          <Popover
            overlayClassName="popover"
            placement="bottomLeft"
            content={
              <div
                className={`expanded-content ${isFullScreen ? "full-screen" : ""
                  }`}
              >
                {/* {isFullScreen && (
                <div
                  className="full-screen-exit-btn"
                  onClick={() => onFullScreenClick(strategyId)}
                >
                  <FullscreenExitOutlined />
                </div>
              )} */}

                {isFullScreen && (
                  <Flex style={{ padding: "5px" }}>
                    <Flex>
                      <Space direction="horizontal">
                        {strategyId || "-"}{" "}
                      </Space>
                    </Flex>

                    <div
                      className="full-screen-exit-btn"
                      onClick={() => onFullScreenClick(strategyId)}
                    >
                      <Space direction="horizontal">
                      <FullscreenExitOutlined />
                      <div>
                        <CloseOutlined
                          onClick={() => onColumnExpand(strategyId)}
                        />
                      </div>
                      </Space>
                    </div>
                  </Flex>
                )}
                {!isFullScreen && (
                  <Flex style={{ padding: "5px" }}>
                    <Flex>
                      <Space direction="horizontal">
                        {strategyId || "-"}{" "}
                      </Space>
                    </Flex>
                    <div className="full-screen-btn">
                      <Flex>
                        <Space direction="horizontal">
                          <FullscreenOutlined
                            onClick={() => onFullScreenClick(strategyId)}
                          />
                          <div>
                            <CloseOutlined
                              onClick={() => onColumnExpand(strategyId)}
                            />
                          </div>
                        </Space>
                      </Flex>
                    </div>
                  </Flex>
                )}
                <div className="div-table-flex-column">
                  {/* {!isFullScreen && ( <div style={{ marginLeft: "5px" }} onClick={() => onColumnExpand(strategyId)} > <MinusOutlined /> </div> )} */}
                  <Tabs
                    data={strategyDetails}
                    isColumnExpanded={isColumnExpanded}
                    onChange={handleTabChange}
                    selectedTab={selectedTab}
                  />
                </div>
              </div>
            }
            trigger={[]}
            open={isColumnExpanded}
          ></Popover>
        )}
      </Flex>
    );
  }
);
const StrategiesTable = ({ data }) => {
  const [fullscreenContent, setFullscreenContent] = useState(null);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [expandedRowsByColumn, setExpandedRowsByColumn] = useState(new Set());
  const handleExpandClick = useCallback(
    (strategyId) => {
      setExpandedRows((prevExpandedRows) => {
        const newExpandedRows = new Set(prevExpandedRows);
        if (newExpandedRows.has(strategyId)) {
          newExpandedRows.delete(strategyId);
          if (fullscreenContent === strategyId) {
            setFullscreenContent(null);
          }
        } else {
          newExpandedRows.add(strategyId);
        }
        return newExpandedRows;
      });
    },
    [fullscreenContent]
  );
  const handleColumnExpand = useCallback((strategyId) => {
    setExpandedRowsByColumn((prevExpandedRows) => {
      const newExpandedRows = new Set(prevExpandedRows);
      if (newExpandedRows.has(strategyId)) {
        newExpandedRows.delete(strategyId);
      } else {
        newExpandedRows.add(strategyId);
      }
      return newExpandedRows;
    });
  }, []);
  const handleFullScreenClick = useCallback((strategyId) => {
    setFullscreenContent((prevFullScreenContent) =>
      prevFullScreenContent === strategyId ? null : strategyId
    );
  }, []);

  return (
    <div className="div-table">
      <div className="div-table-header">
        <div className="div-table-cell div-table-cell-fixed" />
        <div className="div-table-cell div-table-cell-nowrap div-table-cell-center">
          Strategy
        </div>
        <div className="div-table-cell div-table-cell-center">Approx PL %</div>
        {/* <div className="div-table-cell div-table-cell-center">Status</div> */}
      </div>
      {Object.entries(data).map(([strategyId, strategyDetails]) => {
        const isExpanded = expandedRows.has(strategyId);
        const isColumnExpanded = expandedRowsByColumn.has(strategyId);
        return (
          <React.Fragment key={strategyId}>
            <StrategyRow
              strategyId={strategyId}
              strategyDetails={strategyDetails}
              onExpandClick={handleExpandClick}
              isExpanded={isExpanded}
            />
            {isExpanded && (
              <StrategyDetails
                strategyId={strategyId}
                strategyDetails={strategyDetails}
                onColumnExpand={handleColumnExpand}
                isColumnExpanded={isColumnExpanded}
                onFullScreenClick={handleFullScreenClick}
                isFullScreen={fullscreenContent === strategyId}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
export default StrategiesTable;
