import request from "@/utils/request";

import { reqTypeParamName, reqTypeMethodMap } from "@/constants/optionChain";

export async function fetchOptionChain(instrumentId, abortSignal) {
  try {
    const { data: result } = await request(
      `optionChain?hkbInstrumentId=${instrumentId}`,
      {
        abortSignal,
        timeout: 3000,
      }
    );

    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function fetchAccounts() {
  const { data: result } = await request(`accounts`, {
    timeout: 3000,
  });

  if (result?.status == "error") {
    throw new Error(result?.message);
  }

  return result;
}

// E.g.:
// {
//   "debugInfo": [
//     "string"
//   ],
//   "status": "string",
//   "message": "string",
//   "errorcode": "string",
//   "marginInfo": [
//     {
//       "status": "string",
//       "message": "string",
//       "accountId": "string",
//       "marginUsed": 0,
//       "marginRequired": 0
//     }
//   ]
// }

export async function fetchMarginRequired(payload) {
  const { data: result } = await request(`margin`, {
    method: "POST",
    body: payload,
    timeout: 3000,
  });

  if (result?.status == "error") {
    throw new Error(result?.message);
  }

  return result;
}

// Create, Modify or Close Strategy
export async function cmcStrategy(data, requestType) {
  const method = reqTypeMethodMap[requestType];

  const paramName = reqTypeParamName[requestType];

  try {
    const { data: result } = await request(
      `strategy/instance?${paramName}=true`,
      {
        method,
        body: data,
      }
    );

    if (result?.status == "error") {
      throw new Error(result?.message);
    }

    return result;
  } catch (error) {
    console.log(error);
    //alert(error);
    throw error;
  }
}

export async function fetchDepth(instrumentId) {
  try {
    const { data: result } = await request(`tickers?hkbIds=${instrumentId}`, {
      // abortSignal,
      timeout: 3000,
    });

    if (result?.status == "error") {
      throw new Error(result?.message);
    }

    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function parseRawOrders(instrumentId, rawText) {
  try {
    const { data: result } = await request(
      `orders/parseRawOrdersData?parentHkbInstrumentId=${instrumentId}`,
      {
        // abortSignal,
        method: "POST",
        body: rawText,
        timeout: 3000,
        contentType: "text/plain",
      }
    );

    if (result?.status == "error") {
      throw new Error(result?.message);
    }

    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
