import { Flex, Space } from "antd";
import OrderDetails from "../OrderDetails";
import StrategyPositionTable from "./StrategyPositionTable";
import StatusImage from "../StatusImage";
import { ActionButtonGroup } from "../ActionButtonGroup";

const StrategyDetails = ({ strategy, accountId, activeTab }) => {

  return (
    <div className="card">
      {activeTab === "position" ? (
        <>
          <Flex align="center" justify="space-between">
            <h4 className="card-title">{strategy.strategyInstanceName} - {strategy.accountLevelMultipler}</h4>
            <Space>
              <ActionButtonGroup
                strategyName={strategy.strategyName}
                strategyInstanceName={strategy.strategyInstanceName}
                parentHKBInstrumentId={strategy?.parentHKBInstrumentId}
                disabled={strategy.isReadOnly}
                allPositions={strategy?.hkbPositions}
              />
              <StatusImage
                status={strategy.strategyStatus}
                errorStatus={strategy.errorStatus}
              />
            </Space>
          </Flex>

          <StrategyPositionTable strategy={strategy} accountId={accountId} />
        </>
      ) : (
        <OrderDetails
          title={strategy.strategyInstanceName}
          positions={strategy?.hkbPositions}
          accountId={accountId}
          strategyInstanceName={strategy?.strategyInstanceName}
          accountLevelMultipler={strategy?.accountLevelMultipler}
          isReadOnly={strategy?.isReadOnly}

        />
      )}
    </div>
  );
};

export default StrategyDetails;
