import React, { memo, useCallback } from "react";
import "./styles.css";

type TabButtonProps = {
    title: string;
    activeTab: string;
    setActiveTab: (tab: string) => void;
  };
  
const TabButton: React.FC<TabButtonProps> = memo(
  ({ title, activeTab, setActiveTab }) => {
    const handleClick = useCallback(() => {
      setActiveTab(title.toLowerCase());
    }, [title, setActiveTab]);

    return (
      <button
        className={`tab-button ${
          activeTab === title.toLowerCase() ? "active" : ""
        }`}
        onClick={handleClick}
        disabled={activeTab === title.toLowerCase()}
      >
        {title}
      </button>
    );
  }
);

export default TabButton;
