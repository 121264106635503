import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Flex, Popover, Space } from "antd";
import React, { useCallback, useState } from "react";
import Tabs from "../Tabs";

const AccountRow = React.memo(
  ({ accountDetails, onExpandClick, isExpanded }) => (
    <div className="div-table-row">
      <div
        className="div-table-cell div-table-cell-fixed div-table-cell-center"
        onClick={() => onExpandClick(accountDetails.accountId)}
      >
        {isExpanded ? <MinusOutlined /> : <PlusOutlined />}
      </div>
      <div className="div-table-cell div-table-cell-nowrap ">
        <Flex gap="small" wrap="wrap" justify="space-between">
          {accountDetails.accountName || "-"}
        </Flex>
      </div>
      <div className="div-table-cell">{accountDetails.approxPL}%</div>
      <div className="div-table-cell div-table-cell-center">
        {accountDetails.isSynced ? (
          <CheckCircleOutlined style={{ color: "green" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red" }} />
        )}
      </div>
    </div>
  )
);

const AccountDetails = React.memo(
  ({
    accountId,
    accountDetails,
    onColumnExpand,
    isColumnExpanded,
    onFullScreenClick,
    isFullScreen,
  }) => {
    const [selectedTab, setSelectedTab] = useState();

    const handleTabChange = (selectedTab) => {
      setSelectedTab(selectedTab);
    };

    return (
      <>
        {!isColumnExpanded && (
          <div className="div-table-row detail-row">
            <div className="div-table-flex-column">
              <div
                style={{ marginLeft: "5px" }}
                onClick={() => onColumnExpand(accountId)}
              >
                <PlusOutlined />
              </div>
              <Tabs
                isColumnExpanded={isColumnExpanded}
                accountId={accountId}
                data={accountDetails}
                onChange={handleTabChange}
                selectedTab={selectedTab}
              />
            </div>
          </div>
        )}
        {/* {isColumnExpanded && (
        <div
          className={`expanded-content ${isFullScreen ? "full-screen" : ""}`}
        >
          {isFullScreen && (
            <div
              className="full-screen-exit-btn"
              onClick={() => onFullScreenClick(accountId)}
            >
              <FullscreenExitOutlined />
            </div>
          )}
          {!isFullScreen && (
            <div
              className="full-screen-btn"
              onClick={() => onFullScreenClick(accountId)}
            >
              <FullscreenOutlined />
            </div>
          )}

          <div className="div-table-flex-column">
            {!isFullScreen && (
              <div
                style={{ marginLeft: "5px" }}
                onClick={() => onColumnExpand(accountId)}
              >
                <MinusOutlined />
              </div>
            )}
            <Tabs isColumnExpanded={isColumnExpanded} accountId={accountId} data={accountDetails} />
          </div>
        </div>
      )} */}

        {isColumnExpanded && (
          <Popover
            overlayClassName="popover"
            placement="bottomLeft"
            content={
              <div
                className={`expanded-content ${
                  isFullScreen ? "full-screen" : ""
                }`}
              >
                {isFullScreen && (
                  <Flex style={{ padding: "5px" }}>
                    <Flex>
                      <Space direction="horizontal">
                        {accountDetails.accountName || "-"}
                      </Space>
                    </Flex>

                    <div
                      className="full-screen-exit-btn"
                      onClick={() => onFullScreenClick(accountId)}
                    >
                      <FullscreenExitOutlined />
                    </div>
                  </Flex>
                )}
                {!isFullScreen && (
                  <Flex style={{ padding: "5px", maxHeight: "600px" }}>
                    <Flex>
                      <Space direction="horizontal">
                        {accountDetails.accountName || "-"}
                      </Space>
                    </Flex>
                    <div className="full-screen-btn">
                      <Flex>
                        <Space direction="horizontal">
                          <FullscreenOutlined
                            onClick={() => onFullScreenClick(accountId)}
                          />
                          <div>
                            <CloseOutlined
                              onClick={() => onColumnExpand(accountId)}
                            />
                          </div>
                        </Space>
                      </Flex>
                    </div>
                  </Flex>
                )}
                <div>
                  {/* {!isFullScreen && ( <div style={{ marginLeft: "5px" }} onClick={() => onColumnExpand(strategyId)} > <MinusOutlined /> </div> )} */}
                  <Tabs
                    data={accountDetails}
                    isColumnExpanded={isColumnExpanded}
                    onChange={handleTabChange}
                    selectedTab={selectedTab}
                  />
                </div>
              </div>
            }
            trigger={[]}
            open={isColumnExpanded}
          ></Popover>
        )}
      </>
    );
  }
);

const AccountsTable = ({ data }) => {
  const [fullscreenContent, setFullscreenContent] = useState(null);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [expandedRowsByColumn, setExpandedRowsByColumn] = useState(new Set());

  const handleExpandClick = useCallback(
    (accountId) => {
      setExpandedRows((prevExpandedRows) => {
        const newExpandedRows = new Set(prevExpandedRows);
        if (newExpandedRows.has(accountId)) {
          newExpandedRows.delete(accountId);
          if (fullscreenContent === accountId) {
            setFullscreenContent(null);
          }
        } else {
          newExpandedRows.add(accountId);
        }
        return newExpandedRows;
      });
    },
    [fullscreenContent]
  );

  const handleColumnExpand = useCallback((accountId) => {
    setExpandedRowsByColumn((prevExpandedRows) => {
      const newExpandedRows = new Set(prevExpandedRows);
      if (newExpandedRows.has(accountId)) {
        newExpandedRows.delete(accountId);
      } else {
        newExpandedRows.add(accountId);
      }
      return newExpandedRows;
    });
  }, []);

  const handleFullScreenClick = useCallback((accountId) => {
    setFullscreenContent((prevFullScreenContent) =>
      prevFullScreenContent === accountId ? null : accountId
    );
  }, []);

  return (
    <div className="div-table">
      <div className="div-table-header">
        <div className="div-table-cell div-table-cell-fixed" />
        <div className="div-table-cell div-table-cell-nowrap div-table-cell-center">
          Account
        </div>
        <div className="div-table-cell div-table-cell-center">Approx PL %</div>
        <div className="div-table-cell div-table-cell-center">Sync Status</div>
      </div>
      {data &&
        data.map((portfolio) => {
          const isExpanded = expandedRows.has(portfolio.accountId);
          const isColumnExpanded = expandedRowsByColumn.has(
            portfolio.accountId
          );
          return (
            <React.Fragment key={portfolio.accountId}>
              <AccountRow
                accountDetails={portfolio}
                onExpandClick={handleExpandClick}
                isExpanded={isExpanded}
              />
              {isExpanded && (
                <AccountDetails
                  accountId={portfolio.accountId}
                  accountDetails={portfolio}
                  onColumnExpand={handleColumnExpand}
                  isColumnExpanded={isColumnExpanded}
                  onFullScreenClick={handleFullScreenClick}
                  isFullScreen={fullscreenContent === portfolio.accountId}
                />
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default AccountsTable;
