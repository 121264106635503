import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import loadingGif from "@assets/gif/loading.gif";
import bulkOrderUploadIcon from "@assets/images/bulk_upload.png";

import { Modal, Button } from "antd";

import { useDepthWebsocketSetup } from "./hooks";

const emptyArray = [],
  emptyObj = {};

const ocTableHeader = (props) => {
  const {
    stockName,
    atm,
    liveUpdateStatus,
    lastUpdateTime,
    setShowBulkOrdersPane,
  } = props;

  let liveIconColor = "saffron";
  let liveIconTitle = "Live updates not occurring";

  if (liveUpdateStatus) {
    liveIconColor = "green";
    liveIconTitle = "Live updates enabled";
  }

  return (
    <div className="oc-table-header">
      <div className="oc-hr-1">
        <div className="oc-hr-1-cell oc-hr-1-cell-sn flex-start">
          Stock: &nbsp; <b>{stockName}</b>
        </div>
        <div className="oc-hr-1-cell oc-hr-1-cell-c">Call</div>
        <div className="oc-hr-1-cell oc-hr-1-cell-s">
          <span>Strike</span>&nbsp;<span>( ATM Distance )</span>
        </div>
        <div className="oc-hr-1-cell oc-hr-1-cell-p">Put</div>
        <div className="oc-hr-1-cell oc-hr-1-cell-atm flex-end LUT">
          Updated: &nbsp; <b>{lastUpdateTime}</b> &nbsp;{" "}
          <span
            className={`oc-hr-live-icon ${liveIconColor}`}
            title={liveIconTitle}
          ></span>
        </div>
      </div>
      <div className="oc-hr-2">
        <div className="oc-hr-2-cell oc-hr-2-cell-opbs  text-only-button-with-bg"></div>

        <div className="oc-hr-2-cell oc-hr-2-cell-o">OI</div>
        <div className="oc-hr-2-cell oc-hr-2-cell-a">Ask</div>
        <div className="oc-hr-2-cell oc-hr-2-cell-b">Bid</div>
        <div className="oc-hr-2-cell oc-hr-2-cell-l">LTP ( &Delta; )</div>
        <div className="oc-hr-2-cell oc-hr-2-cell-s" title="Spot">
          Spot &nbsp; <b>{atm}</b>
        </div>
        <div className="oc-hr-2-cell oc-hr-2-cell-l">LTP ( &Delta; )</div>
        <div className="oc-hr-2-cell oc-hr-2-cell-a">Ask</div>
        <div className="oc-hr-2-cell oc-hr-2-cell-b">Bid</div>
        <div className="oc-hr-2-cell oc-hr-2-cell-o">OI</div>
        <div className="oc-hr-2-cell oc-hr-2-cell-opbs live-icon">
          <img
            width="40px"
            height="40px"
            src={bulkOrderUploadIcon}
            onClick={() => setShowBulkOrdersPane(true)}
          />
        </div>
      </div>
    </div>
  );
};

const ordersTableHeader = (props) => {
  const { numOfOrders, checkedOrdersCount, checkOrUncheckAllTrades } = props;
  return (
    <div className="orders-table-header">
      <button
        className={`orders-table-hcell orders-table-hcell-cb ${
          numOfOrders > 0 && checkedOrdersCount === numOfOrders ? "checked" : ""
        }`}
        disabled={numOfOrders < 1}
        onClick={checkOrUncheckAllTrades}
      ></button>
      <div className="orders-table-hcell orders-table-hcell-bs">B/S</div>
      <div className="orders-table-hcell orders-table-hcell-exp">Expiry</div>
      <div className="orders-table-hcell orders-table-hcell-stk">Strike</div>
      <div className="orders-table-hcell orders-table-hcell-type">Type</div>
      <div className="orders-table-hcell orders-table-hcell-lots">Lots</div>
      <div className="orders-table-hcell orders-table-hcell-ltp">LTP</div>
      <div className="orders-table-hcell orders-table-hcell-lp">
        Limit Price
      </div>
      <div className="orders-table-hcell orders-table-hcell-mp">M-Protect</div>
      <div className="orders-table-hcell orders-table-hcell-delete"></div>
    </div>
  );
};

const acpTableHeader = (props) => {
  return (
    <div className="oc-acp-header">
      <div className="oc-acp-hcell oc-acp-hcell-a">Account </div>
      <div className="oc-acp-hcell oc-acp-hcell-ma"> Margin Available</div>
      <div className="oc-acp-hcell oc-acp-hcell-mtplr">Multiplier</div>
      <div className="oc-acp-hcell oc-acp-hcell-pr">Priority</div>
      <div className="oc-acp-hcell oc-acp-hcell-mrq">Margin Required</div>
      <div className="oc-acp-hcell oc-acp-hcell-mao">Remaining Margin</div>
      <div className="oc-acp-hcell oc-acp-hcell-check"> </div>
    </div>
  );
};

const loaderPopup = (props) => {
  return (
    <div className="oc-blocking-popup">
      <img className="oc-loader-popup-icon" src={loadingGif} />
    </div>
  );
};

const confirmationPopup = (props) => {
  const { warningText = "Do you want to proceed?", yesCb, noCb } = props;
  return (
    <div className="oc-blocking-popup darker-1">
      <div className="oc-confirm-box">
        {warningText}
        <div className="oc-confirm-box-btns">
          <button className="oc-confirm-box-yes-btn" onClick={yesCb}>
            Proceed
          </button>
          <button className="oc-confirm-box-no-btn" onClick={noCb}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const depthPopup = (props) => {
  const { container, setIsModalVisible, depthInfo } = props;

  const {
    isModalVisible,
    data: tickerPriceList = emptyArray,
    instrumentId,
  } = depthInfo;

  const [tpl, setTpl] = useState(tickerPriceList);
  const tpl1 = tpl[0] || emptyObj;

  const tpl1Memo = useMemo(() => {
    const askDepth = tpl1?.askDepth ?? emptyArray;
    const bidDepth = tpl1?.bidDepth ?? emptyArray;

    const askDepthMemo = askDepth.map((asd) => {
      return {
        ...asd,
        price: Number(asd?.price).toFixed(2),
      };
    });

    const bidDepthMemo = bidDepth.map((asd) => {
      return {
        ...asd,
        price: Number(asd?.price).toFixed(2),
      };
    });

    return { askDepth: askDepthMemo, bidDepth: bidDepthMemo };
  }, [tpl1]);

  const { askDepth, bidDepth } = tpl1Memo;

  useDepthWebsocketSetup(instrumentId, setTpl);

  if (!isModalVisible || askDepth?.length != bidDepth?.length) {
    return null;
  }

  return (
    <>
      <Modal
        // closeIcon={null}
        // maskClosable={false}
        title="Depth"
        open
        onCancel={() => {
          setIsModalVisible(false);
        }}
        footer={null}
      >
        {askDepth?.length == 0 ? (
          <b>
            No Depth data available (This is most probably because current time
            is outside market hours)
          </b>
        ) : (
          <table>
            <thead>
              <tr>
                <th colSpan={3}> Ask </th>
                <td style={{ width: "20px" }} />
                <th colSpan={3}> Bid</th>
              </tr>
              <tr>
                <th> Price </th>
                <th>Quantity</th>
                <th>Orders</th>
                <td style={{ width: "20px" }}></td>
                <th> Price </th>
                <th>Quantity</th>
                <th>Orders</th>
              </tr>
            </thead>
            <tbody>
              {askDepth.map((asd, i) => {
                const bdp = bidDepth[i];
                return (
                  <tr>
                    <td className="oc-dp-cell">{`${asd["price"]}`}</td>
                    <td className="oc-dp-cell">{`${asd["quantity"]}`}</td>
                    <td className="oc-dp-cell">{`${asd["orders"]}`}</td>
                    <td />
                    <td className="oc-dp-cell">{`${bdp["price"]}`}</td>
                    <td className="oc-dp-cell">{`${bdp["quantity"]}`}</td>
                    <td className="oc-dp-cell">{`${bdp["orders"]}`}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </Modal>
    </>
  );
};

const bulkOrdersPopup = (props) => {
  const { addBulkOrders, ordersPaneHeight, setShowBulkOrdersPane } = props;

  const [taVal, setTaVal] = useState("");
  const bulkTextChange = useCallback((e) => {
    setTaVal(e.target.value?.trim());
  }, []);

  const addOrders = useCallback(
    async (text) => {
      const addedOrders = await addBulkOrders(text);
      if (addedOrders) {
        setTaVal("");
      }
    },
    [addBulkOrders]
  );

  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (!ref.current) return;

    // Function to update width
    const updateWidth = () => {
      setWidth(ref.current.parentElement.offsetWidth);
    };

    // Initialize ResizeObserver
    const resizeObserver = new ResizeObserver(updateWidth);
    resizeObserver.observe(ref.current.parentElement);

    // Call once to set initial width
    updateWidth();

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);

  return (
    <div
      ref={ref}
      className="oc-asp"
      style={{
        height: `calc(100vh - ${ordersPaneHeight})`,
        width: width ? `${width}px` : "100%",

      }}
    >
      <button
        className="oc-acp-close-btn"
        onClick={() => {
          setShowBulkOrdersPane(false);
        }}
      >
        <span className="oc-acp-close-btn-icon">&#x276C;</span> Close
      </button>
      <div className="oc-acp-title-and-name" style={{ marginLeft: 0 }}>
        <div className="oc-acp-title">Add Bulk Orders</div>
      </div>
      <textarea
        rows={20}
        cols={100}
        placeholder="Paste text to be parsed as orders..."
        value={taVal}
        onInput={bulkTextChange}
      ></textarea>
      <div className="oc-acp-submit-row">
        <button
          className="oc-acp-submit"
          disabled={taVal.length < 1}
          onClick={() => addOrders(taVal)}
        >
          Add
        </button>
      </div>
    </div>
  );
};

const memOcTableHeader = React.memo(ocTableHeader);
const memOrdersTableHeader = React.memo(ordersTableHeader);
const memAcpTableHeader = React.memo(acpTableHeader);
const memDepthPopup = React.memo(depthPopup);
const memBulkOrdersPopup = React.memo(bulkOrdersPopup);

export {
  confirmationPopup as ConfirmationPopup,
  loaderPopup as LoaderPopup,
  memAcpTableHeader as AcpTableHeader,
  memOcTableHeader as OcTableHeader,
  memOrdersTableHeader as OrdersTableHeader,
  memDepthPopup as DepthPopup,
  memBulkOrdersPopup as BulkOrdersPopup,
};
