// useDebug.ts
import { DebugEntry, debugInfoState, isDebugModeState } from '@/state/positionstate';
import { useRecoilState, useRecoilValue } from 'recoil';

const useDebug = () => {
  const [debugInfo, setDebugInfo] = useRecoilState(debugInfoState);
  const isDebugMode = useRecoilValue(isDebugModeState);


  const appendDebugInfo = (info: Record<string, any>) => {
    if (!isDebugMode) return;

    const entry: DebugEntry = {
      timestamp: new Date(),
      info,
    };

    console.log('entry :>> ', entry);
    setDebugInfo([...debugInfo, entry]);
  };

  return { appendDebugInfo };
};

export default useDebug
