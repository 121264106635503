import { positionViewType } from "@/state/layoutState";
import {
  positionLastUpdatedState,
  positionLiveStatus,
  selectedInstrumentState,
  showStrategyCreationPanelState,
} from "@/state/positionstate";
import { Button, Flex, Select, Space } from "antd";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import LiveStatusIcon from "../LiveStatusIcon";
import useNotification from "@/hooks/useNotification";

const PositionViewSelector = () => {
  const [viewType, setViewType] = useRecoilState(positionViewType);
  const lastUpdated = useRecoilValue(positionLastUpdatedState);
  const isLive = useRecoilValue(positionLiveStatus);
  const setShowStrategyCreationPanel = useSetRecoilState(showStrategyCreationPanelState);
  const { showNotification } = useNotification();
  const selectedInstrument = useRecoilValue(selectedInstrumentState);

  const handleChange = (value) => {
    setViewType(value);
  };

  const addNewStategy = () => {
    if(!selectedInstrument || selectedInstrument === "") {
      showNotification("error", "Please select an Instrument");
      
      return
    }
    setShowStrategyCreationPanel(true);
  };

  return (
    <Flex justify="space-between" style={{ padding: "4px 8px" }}>
      <Select value={viewType} onChange={handleChange} style={{ width: 200 }}>
        <Select.Option value="strategy">Group by Strategy</Select.Option>
        <Select.Option value="account">Group by Account</Select.Option>
      </Select>
      <Space>
        <Button type="primary" size="small" onClick={addNewStategy}>
          +
        </Button>
        <div className="last-update-time">
          Last Updated:{" "}
          {lastUpdated && new Date(lastUpdated).toLocaleTimeString()}
        </div>
        <LiveStatusIcon isLive={isLive} />
      </Space>
    </Flex>
  );
};

export default PositionViewSelector;
