import { useEffect, useState } from "react";
import "./styles.css";
import { useRecoilState } from "recoil";
import { selectedPositionsState } from "@/state/positionstate";
import { Checkbox, Flex, Space, Tooltip } from "antd";
import {
  formatNumber,
  getFormattedQuantity,
  getNetQuantity,
  hasStrategyMismatch,
} from "@/utils";
import { notification } from "antd";
import StatusImage from "../StatusImage";
import useNotification from "@/hooks/useNotification";

const PositionTable = ({ account: { positions, accountId,strategy } }) => {
  const [selectedPositionsArray, setSelectedPositionsState] = useRecoilState(
    selectedPositionsState
  );
  const [selectedPositions, setSelectedPositions] = useState(new Set());
  const { showNotification } = useNotification();

  useEffect(() => {
    // Load initial selections if any
    const initialSelections = selectedPositionsArray
      .filter((p) => p.accountId === accountId)
      .map(
        (p) => `${p.accountId}-${p.tradingSymbol}-${p.strategyInstanceName}`
      );
    setSelectedPositions(new Set(initialSelections));
  }, [accountId, selectedPositionsArray]);

  const isSelected = (position) => {
    return selectedPositions.has(
      `${accountId}-${position.tradingSymbol}-${position.strategyInstanceName}`
    );
  };

  const handleCheckboxChange = (position, isChecked) => {
    const positionKey = `${accountId}-${position.tradingSymbol}-${position.strategyInstanceName}`;
    let updatedPositions = [...selectedPositionsArray];

    if (
      isChecked &&
      hasStrategyMismatch(position.strategyInstanceName, updatedPositions)
    ) {
      // notification.error({
      //   message: "Strategy Mismatch",
      //   description: "You can only select positions with the same strategy.",
      // });
      showNotification(
        "error",
        "You can only select positions with the same strategy."
      );
      return;
    }

    if (isChecked) {
      setSelectedPositions((prev) => new Set(prev.add(positionKey)));
      updatedPositions.push({
        ...position,
        accountId,
        quantity: getNetQuantity(position),
      });
    } else {
      setSelectedPositions(
        (prev) => new Set([...prev].filter((key) => key !== positionKey))
      );
      updatedPositions = updatedPositions.filter(
        (p) =>
          p.tradingSymbol !== position.tradingSymbol ||
          p.strategyInstanceName !== position.strategyInstanceName ||
          p.accountId !== accountId
      );
    }

    setSelectedPositionsState(updatedPositions);
  };

  const handleSelectAll = (isChecked) => {
    let updatedPositions = [...selectedPositionsArray];
    let newSelectedPositions = new Set(selectedPositions);

    if (isChecked) {
      if (
        positions.some((position) =>
          hasStrategyMismatch(position.strategyInstanceName, updatedPositions)
        )
      ) {
        // notification.error({
        //   message: "Strategy Mismatch",
        //   description: "You can only select positions with the same strategy.",
        // });
        showNotification(
          "error",
          "You can only select positions with the same strategy."
        );
        return;
      }
      positions.forEach((position) => {
        const positionKey = `${accountId}-${position.tradingSymbol}-${position.strategyInstanceName}`;

        if (!newSelectedPositions.has(positionKey)) {
          newSelectedPositions.add(positionKey);
          updatedPositions.push({
            ...position,
            quantity: getNetQuantity(position),
            accountId: accountId,
          });
        }
      });
    } else {
      newSelectedPositions = new Set();
      updatedPositions = updatedPositions.filter(
        (p) => p.accountId !== accountId
      );
    }

    setSelectedPositions(newSelectedPositions);
    setSelectedPositionsState(updatedPositions);
  };

  return (
    <table className="portfolio-table">
      <thead>
        <tr>
          <th>
            <Checkbox
             disabled={strategy.isReadOnly}
              checked={
                positions &&
                positions.length > 0 &&
                positions.every((position) => isSelected(position))
              }
              onChange={(e) => handleSelectAll(e.target.checked)}
            />
          </th>
          <th>Symbol</th>
          <th>LTP</th>
          <th><Tooltip placement="topLeft" title="Avg Price (Buy/Sell)">AP</Tooltip></th>
          <th>QTY</th>
          <th>P/L</th>
        </tr>
      </thead>
      <tbody>
        {positions &&
          positions.map((position, index) => {
            const formattedQuantity = getFormattedQuantity(position);
            const isQuantityZero = formattedQuantity === 0;

            return (
              <tr
                key={`${index}-${position.strategyInstanceName}-${accountId}`}
                className={isQuantityZero ? "grey-out" : ""}
              >
                <td>
                  <Checkbox
                    disabled={strategy.isReadOnly}
                    checked={isSelected(position)}
                    onChange={(e) =>
                      handleCheckboxChange(position, e.target.checked)
                    }
                  />
                </td>
                <td>
                  <Space>
                    {position.tradingSymbol}
                    <StatusImage
                      status={position.positionStatus}
                      errorStatus={position.errorStatus}
                    />
                  </Space>
                </td>
                <td className="text-right">{formatNumber(position.ltp)}</td>
                <td className="text-right">
                  {formatNumber(position.averageBuyPrice)}/
                  {formatNumber(position.averageSellPrice)}
                </td>
                <td className="text-right">{getFormattedQuantity(position)}</td>
                <td className="text-right">{formatNumber(position.pnl)}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default PositionTable;
