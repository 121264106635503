import React from "react";
import "./style.css";

const LiveStatusIcon = ({ isLive }) => {
  const liveIconColor = isLive ? "live-color" : "offline-color";
  const title = isLive ? "Live" : "Offline";

  return (
    <div className="live-icon-container">
      <span className={`live-status-icon ${liveIconColor}`} title={title}></span>
    </div>
  );
};

export default LiveStatusIcon;
