import { Layout, theme } from "antd";
import React from "react";
import { Outlet } from "react-router-dom";

const { Content } = Layout;

const AppLayout: React.FC = () => {
  return (
    <Layout style={{ minHeight: "100%" }}>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default AppLayout;
