import React from 'react';
import { Tooltip } from 'antd';

const StatusImage = ({ status, errorStatus }) => {
  if (!status) {
   status = "Queued"
  }

  const getImageWithPath = (imageName) => {
    try {
      const image = require(`../../../assets/images/${imageName}.png`);
      return image.default || image;
    } catch (error) {
      return ''; 
    }
  };

  let imageFileName = status;
  if (status === "In-Process") {
    imageFileName = errorStatus && errorStatus.trim() !== ''
      ? "in-progress-with-error"
      : "in-progress-no-error";
  }

  const imageSrc = getImageWithPath(imageFileName);

  const shouldDisplayTooltip = errorStatus && errorStatus.trim() !== '';

  return (
    <div style={{marginTop:"2px" }}>
      {shouldDisplayTooltip ? (
        <Tooltip title={errorStatus}>
          <img 
            src={imageSrc} 
            alt={status} 
          style={{ marginLeft: '2px',}}

          />
        </Tooltip>
      ) : (
        <img 
          src={imageSrc} 
          alt={status} 
          style={{ marginLeft: '2px' }}
        />
      )}
    </div>
  );
};

export default StatusImage;
