import { userState } from '@/state/authState';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const user = useRecoilValue(userState);

    if (!user.isLoggedIn) {
        return <Navigate to="/login" />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
