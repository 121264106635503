import { wsManager } from "@/utils/websocket";
import { notification } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { Timeout } from "react-number-format/types/types";
import { wsMsgMaxFrequency } from "@/constants/optionChain";
import { useRecoilState } from "recoil";

export function useWebsocket(url, handleData, handleWsError, updateLiveStatus) {
  // const [liveUpdateStatus, setLiveUpdateStatus] = useRecoilState(liveUpdateStatusState);

  // Use a ref to store the setTimeout identifier to persist across renders.
  const timeoutRef = useRef<Timeout | null>(null);

  // Function to handle WebSocket open event.
  const handleWsOpen = useCallback(() => {
    console.log("open websocket :>> ");
    // notification.info({
    //   message: "Success!",
    //   description: "Live updates enabled",
    // });
    resetTimeout();
  }, []);

  // Function to reset and start the timeout.
  const resetTimeout = useCallback(() => {
    updateLiveStatus(true);
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      console.log(
        `No WebSocket data received for the past ${wsMsgMaxFrequency} seconds`
      );
      updateLiveStatus(false);
      wsManager.reconnect(url, handleWsMessage, handleWsError, handleWsOpen);
    }, wsMsgMaxFrequency * 1000);
  }, []);

  // Function to handle WebSocket messages.
  const handleWsMessage = useCallback((event) => {
    const data = JSON.parse(event.data);

    if (data.status === "success") {
      handleData(data);
    } else {
      console.log("Server error on websocket data", data?.debugInfo ?? "");
    }
    resetTimeout();
  }, []);

  useEffect(() => {
    console.log("wsManager :>> ", url);
    wsManager.connect({
      endpoint: url,
      onMessage: handleWsMessage,
      errorHandler: handleWsError,
      openHandler: handleWsOpen,
    });

    return () => {
      clearTimeout(timeoutRef.current);
      updateLiveStatus(false);
      wsManager.disconnect(url);
    };
  }, []);
}
