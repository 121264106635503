import { userState } from "@/state/authState";
import { saveAccessToken, saveAuthState } from "@/utils/localStorageUtil";
import { GoogleLogin } from "@react-oauth/google";
import { message } from "antd";
import React from "react";
import { useSetRecoilState } from "recoil";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const GoogleSignIn: React.FC = () => {
  const setUser = useSetRecoilState(userState);
  const [messageApi] = message.useMessage();
  const navigate = useNavigate();



  const handleLoginSuccess = (response: any) => {
    saveAccessToken(response.credential);
    const decoded: any = jwtDecode(response.credential);

    const userData = {
      email: decoded.email,
      name: decoded.name,
    };

    setUser({ isLoggedIn: true, userData });
    saveAuthState({ isLoggedIn: true, userData });

    navigate('/');

  };

  const handleLoginFailure = () => {
    console.error("Google Sign-In Error");
    messageApi.open({
      type: "error",
      content: "Google Sign-In Error",
    });
  };

  return (
    <GoogleLogin
      onSuccess={(credentialResponse) => handleLoginSuccess(credentialResponse)}
      onError={() => handleLoginFailure()}
    />
  );
};

export default GoogleSignIn;
