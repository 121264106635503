import iv from 'implied-volatility';
import bs from 'black-scholes';


import React, { useState, useEffect, forwardRef } from 'react';
import { Card, Input, Button, Form, Col, Row, Select, Table, Typography } from 'antd';
//const { Title} = Typography;
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

import {
    optionTypeAbbr,
} from "@/constants/optionChain";
import Item from 'antd/es/list/Item';
const { Option } = Select;
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const millsecondsInYr = 31536 * 1000 * 1000;
const secondsOnExpiryDay = 15 * 60 * 60 + 30 * 60;

const getTTEInYrs = (expires: Date) => {


    expires.setSeconds(expires.getSeconds() + secondsOnExpiryDay);
    const nowDt = new Date();
    const diffTime = (expires - nowDt);
    if (Math.random() < 0.001) {
        console.log("tte(in-hr)", diffTime / (1000 * 60 * 60), "exp:", expires, "timenow:", nowDt, "exp:", expires.toDateString);
    };
    if (diffTime <= 0) {
        return 0;
    };
    return diffTime / millsecondsInYr;

}

const calculateIv = (stockprice, option) => {
    /*expectedCost - The market price of the option
    s - Current price of the underlying
    k - Strike price
    t - Time to experiation in years
    r - Anual risk-free interest rate as a decimal
    callPut - The type of option priced - "call" or "put"
        [estimate=.1] - An initial estimate of implied volatility
    */
    const s = stockprice;
    const currentPrice = option.premium;
    const k = option.strike;
    const t = getTTEInYrs(option.expires);
    const r = 0.075;
    const callPut = option.type;
    const estimate = 0.1;
    if (t > 0) {
        //getImpliedVolatility(expectedCost, s, k, t, r, callPut, estimate)
        const ivVal = iv.getImpliedVolatility(currentPrice, s, k, t, r, callPut, estimate); // 0.11406250000000001 (11.4%)
        //if (Math.abs(stockprice-currentStockPrice) < 99) {
        //  console.log("iv:", ivVal, "premium:", currentPrice,"strike:", k, "tte:", t, "roi:", r, callPut, estimate);
        //}
        return ivVal;
    };
    return 0;
};

const calculatePremium = (stockPrice, option, vol) => {
    /* blackScholes(s, k, t, v, r, callPut)

     s - Current price of the underlying
     k - Strike price
     t - Time to expiration in years
     v - Volatility as a decimal
     r - Annual risk-free interest rate as a decimal
     callPut - The type of option to be priced - "call" or "put"
     */

    const s = stockPrice;
    const k = option.strike;
    const t = getTTEInYrs(option.expires);
    const v = vol;
    const r = 0.075;
    const callPut = option.type;

    if (t > 0) {
        const expectedPrice = bs.blackScholes(s, k, t, v, r, callPut);
        return expectedPrice;
    } else {
        if ((option.type === 'call' && stockPrice > option.strike) || (option.type === 'put' && stockPrice < option.strike)) {
            const intrinsicValue = Math.abs(stockPrice - option.strike);
            return intrinsicValue;
        }
    };

    return 0;
};

const calculateNetCashFlowNow = (stockPrice, currentStockPrice, options) => {
    return options.reduce((total, option) => {
        const ivval = calculateIv(currentStockPrice, option);
        const multiplier = option.position === 'short' ? -option.lotSize : option.lotSize;
        const expectedPremium = calculatePremium(stockPrice, option, ivval);
        if (Math.abs(stockPrice - currentStockPrice) < 99 && Math.random() < 0.1) {
            console.log("iv:", ivval, "expectedPrice:", expectedPremium, "actualPrice", option.premium, option);
        };
        let payoff = (expectedPremium - option.premiumAtOpen) * multiplier;

        /*if ((option.type === 'call' && stockPrice > option.strike) || (option.type === 'put' && stockPrice < option.strike)) {
            const intrinsicValue = Math.abs(stockPrice - option.strike);
            payoff = (intrinsicValue - adjustedPremium) * multiplier;
        }*/
        return total + payoff;
    }, 0);
};

const calculateNetCashFlowAtExpiry = (stockPrice, options) => {
    return options.reduce((total, option) => {
        const lotSize = 15;
        const multiplier = option.position === 'short' ? -lotSize : lotSize;

        const adjustedPremium = option.premiumAtOpen;
        let payoff = -option.premiumAtOpen * multiplier;

        if ((option.type === 'call' && stockPrice > option.strike) || (option.type === 'put' && stockPrice < option.strike)) {
            const intrinsicValue = Math.abs(stockPrice - option.strike);
            payoff = (intrinsicValue - adjustedPremium) * multiplier;
        }


        return total + payoff;
    }, 0);
};

const calculateBreakevens = (stockPrices, currentStockPrice, options) => {
    const breakEventresults = [];
    const cashflowresult = [];
    let dataPointsAtExpiry = [];
    let dataPointsForNow = [];

    // -1 is to get pervios cash flow which is used for comparison in the loop
    let lastCashFlowAtExpiry = calculateNetCashFlowAtExpiry(stockPrices[0] - 1, options);
    cashflowresult.push(lastCashFlowAtExpiry);
    let stockPriceSlice = []

    for (let index = 0; index <= stockPrices.length; index += 1) {
        const stockPrice = stockPrices[index];
        const currentCashFlow = calculateNetCashFlowNow(stockPrice, currentStockPrice, options);
        const currentCashFlowAtExpiry = calculateNetCashFlowAtExpiry(stockPrice, options);
        stockPriceSlice.push(stockPrice);
        dataPointsForNow.push(currentCashFlow);
        dataPointsAtExpiry.push(currentCashFlowAtExpiry);

        if ((lastCashFlowAtExpiry < 0 && currentCashFlowAtExpiry >= 0) || (lastCashFlowAtExpiry > 0 && currentCashFlowAtExpiry <= 0)) {
            breakEventresults.push(stockPrice);
        }
        cashflowresult.push(lastCashFlowAtExpiry);
        lastCashFlowAtExpiry = currentCashFlowAtExpiry;
    }

    return [breakEventresults, cashflowresult, dataPointsAtExpiry, dataPointsForNow, stockPriceSlice];
}

const PayoffGraph = ({ orders, changingDataByHkbId, currentStockPrice, stockPrices }) => {
    // console.log("orders", orders);
    //const stockPriceRange = { min: stockMinVal, max: stockMaxVal, step: stockStep };
    //const [breakevens, setBreakevens] = useState([]);
    //const [chartData, setChartData] = useState({});

    const newOrders = [];
    for (let i = 0; i < orders.length; i++) {
        let o = {};
        let ltp = 0;
        if (changingDataByHkbId[orders[i]?.hkbInstrumentId] != undefined) {
            ltp = changingDataByHkbId[orders[i]?.hkbInstrumentId].ltp
        }
        const limitPrice = Number(orders[i]?.limitPrice);
        if (isNaN(+ltp) || orders[i]?.lots === 0) {
            // return (
            //     <div style={{ color: 'red' }}>
            //         ltp is invalid! ltp: {ltp}, hkbid: {orders[i]?.hkbInstrumentId}
            //     </div>
            // )
            continue
        }
        //check ltp is a number(float)
        o.type = optionTypeAbbr[orders[i]?.type]["req"];
        o.strike = orders[i].strike;
        o.premium = ltp;
        o.premiumAtOpen = limitPrice === 0 ? ltp : limitPrice;
        o.position = orders[i]?.bs === "B" ? 'long' : 'short';
        o.expires = new Date(orders[i]?.dtKey);
        o.lotSize = orders[i]?.lotSize * orders[i]?.lots;

        newOrders.push(o);
    }
    if (newOrders.length == 0) {
        return (
            <div style={{ color: 'red' }}>
                Orders are empty!
            </div>
        )
    }
    //console.log("stockPrices", stockPrices);
    // currentStockPrice = 24271
    // orders = [
    //     { type: 'call', strike: 24300, premium: 173.75, premiumAtOpen: 173.75, position: 'short', expires: new Date("07/11/2024"), lotSize: 25 },
    //     { type: 'call', strike: 24400, premium: 125.20, premiumAtOpen: 126.20, position: 'long', expires: new Date("07/11/2024"), lotSize: 25 },
    //     //{ type: 'call', strike: 24550, premium: 65, premiumAtOpen: 65, position: 'long', expires: new Date("07/11/2024"), lotSize: 25 },
    //     { type: 'put', strike: 24300, premium: 170.70, premiumAtOpen: 170.7, position: 'short', expires: new Date("07/11/2024"), lotSize: 25 },
    //     { type: 'put', strike: 24200, premium: 128.85, premiumAtOpen: 128.85, position: 'long', expires: new Date("07/11/2024"), lotSize: 25 },
    // ];

    const [breakEventresults, cashflowresult, dataPointsAtExpiry, dataPointsForNow, stockPriceSlice] = calculateBreakevens(stockPrices, currentStockPrice, newOrders);
    console.log("dataPointsForNow", dataPointsForNow);
    const datasetAtExpiry = {
        label: 'AtExpiry',
        data: dataPointsAtExpiry, // Assuming you have a method to generate these data points
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        showLine: true,
        tension: 0,
        fill: false,
    }
    const datasetForNow = {
        label: 'ForNow',
        data: dataPointsForNow, // Assuming you have a method to generate these data points
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        showLine: true,
        tension: 0,
        fill: false,
    }

    const chartData = {
        labels: stockPriceSlice,
        datasets: [datasetForNow, datasetAtExpiry]
    };

    //console.log("chartData", chartData);

    return (

        <div style={{ width: 1024, height: 512 }}>

            {chartData?.labels && (
                <Scatter data={chartData}
                    options={{
                        scales: {
                            x: {
                                type: 'linear',
                            },
                            y: {
                                beginAtZero: false,
                                grid: {
                                    color: function (context) {
                                        if (context.tick.value == 0) {
                                            return 'rgba(160,160,160,1)';
                                        }
                                        return 'rgba(0, 0, 0, 0.1)';
                                    },
                                    lineWidth: function (context) {
                                        if (context.tick.value == 0) {
                                            return 1;
                                        }
                                        return 0.5;
                                    },
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                position: 'top',
                            }
                        }
                    }}
                />
            )}

        </div>
    )
};

export default PayoffGraph;
