import { atom, selector, selectorFamily, useRecoilValue } from "recoil";

export const selectedPositionsState = atom({
  key: "selectedPositionsState",
  default: [],
});

export const allPositionsSelectedStrategyState = atom({
  key: "allPositionsSelectedStrategyState",
  default: [],
});

export const selectedInstrumentState = atom({
  key: "selectedInstrumentState",
  default: "",
});

export const filterQueryState = atom({
  key: "filterQueryState",
  default: "",
});

export const lastClickedButtonActionState = atom<
  "Adjust" | "Modify" | "Close" | null
>({
  key: "lastClickedButtonActionState",
  default: null,
});

export const actionButtonClickCountState = atom({
  key: "actionButtonClickCountState",
  default: 0,
});

export const selectedOrdersState = atom({
  key: "selectedOrdersState",
  default: [],
});

export const instrumentIdToNameMapState = atom({
  key: "instrumentIdToNameMapState",
  default: new Map(),
});

export const portfolioState = atom({
  key: "portfolioState",
  default: [],
});

export const portfolioSelector = selector({
  key: "portfolioSelector",
  get: ({ get }) => {
    const portfolios = get(portfolioState);
    return (accountId) =>
      portfolios &&
      portfolios.find((portfolio) => portfolio.accountId === accountId);
  },
});

export const positionLiveStatus = atom({
  key: "positionLIveStatus",
  default: false,
});

export const priceLiveStatus = atom({
  key: "priceLiveStatus",
  default: false,
});

export const positionLastUpdatedState = atom({
  key: "positionLastUpdatedState",
  default: null,
});

export const defaultActionState = atom({
  key: "defaultActionState",
  default: {},
});

export type NotificationType = "error" | "warning" | "success";

export interface Notification {
  type: NotificationType;
  text: string;
}

export const notificationState = atom<Notification>({
  key: "notificationState",
  default: { type: "error", text: "" }, // Default to 'error' or choose an empty string or another default
});

export const orderValuesState = atom({
  key: "orderValuesState",
  default: {},
});

export const showStrategyCreationPanelState = atom({
  key: "showStrategyCreationPanelState",
  default: false,
});

export const accountsState = atom<Account[]>({
  key: 'accountsState',
  default: [],
});

export const accountMultipliersState = atom<Map<string, number>>({
  key: 'accountMultipliersState',
  default: new Map(),
});

export const accountByIdState = selectorFamily<Account | undefined, string>({
  key: 'accountByIdState',
  get: (accountId) => ({ get }) => {
    const accounts = get(accountsState);
    return accounts.find((account) => account.accountId === accountId);
  },
});

export interface DebugEntry {
  timestamp: Date;
  info: Record<string, any>;
}

export const debugInfoState = atom<DebugEntry[]>({
  key: 'debugInfoState',
  default: [],
});

export const isDebugModeState = atom({
  key: 'isDebugModeState', 
  default: false,
});