// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 1000;
  overflow: auto;
  padding: 20px;
  box-sizing: border-box;
}

.full-screen-btn,
.full-screen-exit-btn {
  position: absolute;
  /* display: flex;
  justify-content: flex-end;
  top: 10px; */
  right: 0px;
  cursor: pointer;
  z-index: 1100;
}


/* Sticky styling */
.sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
}

/* Icon styles */
.anticon {
  /* font-size: 16px; */
  vertical-align: middle;
  margin-right: 8px;
}

.check-circle-icon {
  color: green;
}

.close-circle-icon {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/StrategyContainer/styles.css"],"names":[],"mappings":";AACA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,aAAa;EACb,cAAc;EACd,aAAa;EACb,sBAAsB;AACxB;;AAEA;;EAEE,kBAAkB;EAClB;;cAEY;EACZ,UAAU;EACV,eAAe;EACf,aAAa;AACf;;;AAGA,mBAAmB;AACnB;EACE,gBAAgB;EAChB,MAAM;EACN,uBAAuB;EACvB,WAAW;AACb;;AAEA,gBAAgB;AAChB;EACE,qBAAqB;EACrB,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;AACZ","sourcesContent":["\n.full-screen {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: white;\n  z-index: 1000;\n  overflow: auto;\n  padding: 20px;\n  box-sizing: border-box;\n}\n\n.full-screen-btn,\n.full-screen-exit-btn {\n  position: absolute;\n  /* display: flex;\n  justify-content: flex-end;\n  top: 10px; */\n  right: 0px;\n  cursor: pointer;\n  z-index: 1100;\n}\n\n\n/* Sticky styling */\n.sticky-header {\n  position: sticky;\n  top: 0;\n  background-color: white;\n  z-index: 10;\n}\n\n/* Icon styles */\n.anticon {\n  /* font-size: 16px; */\n  vertical-align: middle;\n  margin-right: 8px;\n}\n\n.check-circle-icon {\n  color: green;\n}\n\n.close-circle-icon {\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
