import {
  useCallback,
  useMemo,
  useRef,
  useState,
  useContext,
  useEffect,
} from "react";
import {Modal, notification, Tabs} from "antd";
const { TabPane } = Tabs;
import { useRecoilValue, useSetRecoilState } from "recoil";

import "@fortawesome/fontawesome-free/css/solid.css";

import {
  bsProps,
  closeIcon,
  emptyArray,
  monthAbbrev,
  numericFieldsRegExp,
  optionTypeAbbr,
  paddedDates,
  reqTypeMsgMap,
  stockNameMap,
} from "@/constants/optionChain";
import {
  emptyStrategyCounterState, isDebugModeState,
  portfolioSelector,
  selectedPositionsState,
  showStrategyCreationPanelState,
} from "@/state/positionstate";

import {
  cmcStrategy,
  fetchAccounts,
  fetchDepth,
  fetchMarginRequired,
  parseRawOrders,
} from "@/api/optionChain";

import {
  useAddPositionOrder,
  addOrdersForPayOffGraphFromPositions,
  useBlueStripNav,
  useDateNavWidth,
  useWebsocketSetup,
} from "./hooks";
import { useStockName } from "@/hooks/useStockName";
import { createStrategyReqBodyAndType } from "./dtos";
import { ApiResponseContext } from "./contexts";
import {
  AcpTableHeader,
  BulkOrdersPopup,
  ConfirmationPopup,
  DepthPopup,
  LoaderPopup,
  OcTableHeader,
  OrdersTableHeader,
} from "./descendants";
import useNotification from "@/hooks/useNotification";
import Notification from "../Notification";
import StrategyCreationPanel from "./StrategyCreationPanel";
import OverwriteInput from "../OverwriteInput";
import {formatCurrency} from "../../utils";
import PayoffGraph from "@/components/PayoffGraph";
import { Flex, Select } from "antd";

const depthInfoInit = {
  isModalVisible: false,
  data: [],
  instrumentId: null,
};

const confirmDuplicateAction = () => {
  return new Promise((resolve) => {
    Modal.confirm({
      title: "Duplicate Instruments Found",
      content: "Duplicates detected. Choose how you want to proceed.",
      okText: "Add",
      cancelText: "Ignore",
      onOk() {
        resolve(false); // User chooses to Add duplicates
      },
      onCancel() {
        resolve(true); // User chooses to Ignore duplicates
      },
    });
  });
};


const OptionChainAndOrderPane = (props) => {
  const {
    hkbInstrumentId: instrumentId,
    positions: selectedPositions = emptyArray,
    allPositions: receivedAllPositions = emptyArray,
    lastClickedButtonAction,
    strategyInstanceName: receivedStrategyName = "",
    strategyName,
  } = props;
  const [userPreference, setUserPreference] = useState("run");
  const setSelectedPositions = useSetRecoilState(selectedPositionsState);
  const showStrategyPanel = useRecoilValue(showStrategyCreationPanelState);

  const stockName =
    useStockName(instrumentId) || stockNameMap[instrumentId] || "Stock";

  const prevInstumentIdRef = useRef(null);
  const [showOrderPane, setShowOrderPane] = useState(false);

  const [debugMode, setDebugMode] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isdebugparamPresent = queryParams.has("debug")
    setDebugMode(isdebugparamPresent);
  }, []); // Empty dependency array to run only once on mount

  const [requestType, setRequestType] = useState("create");

  const isNotCreateRequest = requestType !== "create";
  const optionChainData = useContext(ApiResponseContext);
  // Nothing to do with orders/positions; Cant cause crash

  useEffect(() => {
    if (showStrategyPanel === true) {
      setShowOrderPane(false);
    }
  }, [showStrategyPanel]);

  const handleUserPreferenceChange = (value) => {
    setUserPreference(value);
  };

  const expDates = useMemo(() => {
    if (optionChainData?.expiryDateList == undefined) {
      return [];
    }

    return optionChainData.expiryDateList.map((d) => {
      const dt = new Date(d);
      const date = dt.getDate();
      const month = monthAbbrev[dt.getMonth()]["name"];
      const year = dt.getFullYear();
      const year2Digit = year % 1000;

      return {
        dt,
        dtKey: `${date}-${month}-${year2Digit}`,
        dtText: `${date} ${month} '${year2Digit}`,
      };
    });
  }, [optionChainData]);

  const [osDatesClassName, setOsDatesClassName] = useState("oc-exp-dates");
  const [curDateNavIndex, setCurDateNavIndex] = useState(0);
  const datesElemLeftRef = useRef("0px");
  const dateElemWidthRef = useRef(0);
  const datesElemMaxWidthRef = useRef("100%");
  const totalDatesFitRef = useRef(99999);
  const totalDateElemsRef = useRef(0);
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);

  const [websocketData, setWebsocketData] = useState({});
  // Nothing to do with orders/positions; Cant cause crash
  const lastUpdateTime = useMemo(() => {
    if (optionChainData != undefined || websocketData != undefined) {
      const dt = new Date(
        websocketData?.lastUpdateTime || optionChainData.lastUpdateTime
      );

      let hour = dt.getHours();
      let minute = dt.getMinutes();
      let sec = dt.getSeconds();

      let amPm = hour < 12 ? "AM" : "PM";
      if (hour != 12) {
        hour = hour % 12;
      }

      // if (hour < 10) {
      //   hour = `0${hour}`;
      // Better not do this for 12 hour format
      // }

      if (minute < 10) {
        minute = `0${minute}`;
      }

      if (sec < 10) {
        sec = `0${sec}`;
      }

      return `${hour}:${minute}:${sec} ${amPm}`;
    }
  }, [optionChainData, websocketData]);

  useDateNavWidth(
    optionChainData,
    dateElemWidthRef,
    datesElemMaxWidthRef,
    setOsDatesClassName,
    totalDateElemsRef,
    totalDatesFitRef
  );

  // Nothing to do with orders/positions; Cant cause crash
  const datesNavRightClick = useCallback(() => {
    setCurDateNavIndex((curDateNavIndex) => {
      const newIndex = curDateNavIndex + 1;
      const leftVal = `-${newIndex * dateElemWidthRef.current}px`;
      datesElemLeftRef.current = leftVal;
      return newIndex;
    });
  }, []);

  // Nothing to do with orders/positions; Cant cause crash
  const datesNavLeftClick = useCallback(() => {
    setCurDateNavIndex((curDateNavIndex) => {
      const newIndex = curDateNavIndex - 1;
      const leftVal = `-${newIndex * dateElemWidthRef.current}px`;
      datesElemLeftRef.current = leftVal;
      return newIndex;
    });
  }, []);

  // Example structure:
  // {
  //   date1: {
  //     strike11:{C: {}, P: {}}, ...,
  //     strike12:{C: {}, P: {}}, ...,
  //     sortedStikes: {},
  //   }
  // }

  // Data can change with websocket response
  const changingDataByHkbIdRef = useRef({});

  const modalContainerRef = useRef(null);

  const [depthInfo, setDepthInfo] = useState(depthInfoInit);

  const { showNotification } = useNotification();

  const ordersLtpClick = useCallback(async (target, instrumentId) => {
    try {
      const data = await fetchDepth(instrumentId);
      // modalContainerRef.current = target;

      setDepthInfo({
        isModalVisible: true,
        data: data?.tickerPriceList || [],
        instrumentId,
      });
    } catch (e) {
      // notification.warning({
      //   message: "Failed to fetch Depth Info.",
      //   description: e?.message,
      // });
      showNotification("warning", e?.message);
    }
  }, []);

  // Nothing to do with orders/positions; Cant cause crash
  const dataGrouped = useMemo(() => {
    if (optionChainData == undefined) return {};
    changingDataByHkbIdRef.current = {};

    const changingDataByHkbId = changingDataByHkbIdRef.current;
    const oil = optionChainData.optionInfoList;
    const atm = Number(optionChainData?.underlyingLtp.toFixed(2)) || 0;
    const final = {},
      len = oil.length,
      byHkbId = {};

    for (let i = 0; i < len; i++) {
      const oi = oil[i];
      const optionType = oi.optionType;

      const optionTypeMatch = optionType == "c" || optionType == "p";

      if (optionTypeMatch) {
        const stk = oi?.strike;

        const dt = new Date(oi?.expiryDate);
        const date = dt.getDate();
        const month = monthAbbrev[dt.getMonth()]["name"];
        const year = dt.getFullYear();
        const year2Digit = year % 1000;

        const dtKey = `${date}-${month}-${year2Digit}`;
        if (final[dtKey] == undefined) {
          final[dtKey] = {};
        }

        if (final[dtKey][stk] == undefined) {
          final[dtKey][stk] = {};
        }

        if (final[dtKey][stk][optionType] == undefined) {
          final[dtKey][stk][optionType] = [];
        }

        const dist = Math.floor(stk - atm);

        const hkbInstrumentId = oi?.hkbInstrumentId;
        final[dtKey][stk][optionType] = byHkbId[hkbInstrumentId] = {
          initialIndex: i,
          dtKey,
          hkbInstrumentId,
        };

        let oiRounded = oi?.openInterest || 0;
        if (oiRounded >= 10000000) {
          oiRounded = oiRounded / 10000000;
          oiRounded = `${oiRounded.toFixed(2)}C`;
        } else if (oiRounded >= 100000) {
          oiRounded = oiRounded / 100000;
          oiRounded = `${oiRounded.toFixed(2)}L`;
        } else if (oiRounded >= 1000) {
          oiRounded = oiRounded / 1000;
          oiRounded = `${oiRounded.toFixed(2)}K`;
        } else {
          oiRounded = oiRounded.toFixed(2);
        }

        changingDataByHkbId[hkbInstrumentId] = {
          delta: oi.delta?.toFixed(2),
          askRounded: (oi?.ask || 0).toFixed(2),
          bidRounded: (oi?.bid || 0).toFixed(2),
          ltpRounded: (oi?.ltp || 0).toFixed(2),
          ltp: oi?.ltp,
          oiRounded,
          dist,
          distanceType: dist < 0 ? "neg" : dist > 0 ? "pos" : "z",
        };
      }
    }

    const dates = Object.keys(final);

    dates.forEach((dt) => {
      final[dt]["sortedStrikes"] = Object.keys(final[dt]).sort(
        (s1, s2) => s1 - s2
      );

      const sortedStrikes = final[dt]["sortedStrikes"];
      const blueStrikeIndex = sortedStrikes.findIndex((s) => s >= atm);

      if (blueStrikeIndex > -1) {
        const blueStrike = sortedStrikes[blueStrikeIndex];

        const blueStrikeCallData = final[dt][blueStrike]["c"];

        const blueStrikePutData = final[dt][blueStrike]["p"];

        if (
          blueStrikeCallData != undefined &&
          changingDataByHkbId != undefined
        ) {
          const hbkId = blueStrikeCallData?.hkbInstrumentId;
          console.log("hbkId", hbkId, changingDataByHkbId[hbkId]);
          if (changingDataByHkbId[hbkId] != undefined) {
            changingDataByHkbId[hbkId].distanceType = "z blue-strike";
          }
        }
        if (
          blueStrikePutData != undefined &&
          changingDataByHkbId != undefined
        ) {
          const hbkId = blueStrikePutData?.hkbInstrumentId;

          console.log("hbkId", hbkId, changingDataByHkbId[hbkId]);
          if (changingDataByHkbId[hbkId] != undefined) {
            changingDataByHkbId[hbkId].distanceType = "z blue-strike";
          }
        }
      }

      final[dt]["blueStrikeIndex"] = blueStrikeIndex;
    });

    return { byExpDatesAndStrikes: final, byHkbId };
  }, [optionChainData]);

  const underlyingLtp =
    websocketData?.underlyingLtp || optionChainData?.underlyingLtp;
  const atm = Number(underlyingLtp.toFixed(2)) || 0;

  const dataByExpDatesAndStrikes = dataGrouped?.byExpDatesAndStrikes;

  const dataByHkbId = dataGrouped?.byHkbId;

  const curExpDateKey = expDates[selectedDateIndex]?.dtKey;

  const curExpDateData = dataByExpDatesAndStrikes[curExpDateKey];

  const strikesSorted = curExpDateData?.["sortedStrikes"];

  const combineHttpAndWebsocketData = useCallback(
    (websocketData, wsInstrumentId, wsSelectedDateIndex) => {
      if (
        instrumentId === wsInstrumentId &&
        selectedDateIndex === wsSelectedDateIndex
      ) {
        const oil = websocketData?.optionInfoList;
        const atm = Number(websocketData?.underlyingLtp?.toFixed(2)) || 0;

        if (oil != undefined) {
          const changingDataByHkbId = changingDataByHkbIdRef.current;
          const len = oil.length;

          for (let i = 0; i < len; i++) {
            const oi = oil[i];
            const hkbInstrumentId = oi?.hkbInstrumentId;
            const currentItem = changingDataByHkbId[hkbInstrumentId];

            if (currentItem != undefined) {
              const strike = oi?.strike;
              const dist = Math.floor(strike - atm);

              let oiRounded = oi?.openInterest || 0;
              if (oiRounded >= 10000000) {
                oiRounded = oiRounded / 10000000;
                oiRounded = `${oiRounded.toFixed(2)}C`;
              } else if (oiRounded >= 100000) {
                oiRounded = oiRounded / 100000;
                oiRounded = `${oiRounded.toFixed(2)}L`;
              } else if (oiRounded >= 1000) {
                oiRounded = oiRounded / 1000;
                oiRounded = `${oiRounded.toFixed(2)}K`;
              } else {
                oiRounded = oiRounded.toFixed(2);
              }

              changingDataByHkbId[hkbInstrumentId] = {
                delta: oi.delta?.toFixed(2),
                askRounded: (oi?.ask || 0).toFixed(2),
                bidRounded: (oi?.bid || 0).toFixed(2),
                ltpRounded: (oi?.ltp || 0).toFixed(2),
                ltp: oi?.ltp,
                oiRounded,
                dist,
                distanceType: dist < 0 ? "neg" : dist > 0 ? "pos" : "z",
              };
            }
          }

          const dBEDAS = dataByExpDatesAndStrikes;
          const dt = curExpDateKey;

          const sortedStrikes = dBEDAS[dt]["sortedStrikes"];
          const blueStrikeIndex = sortedStrikes.findIndex((s) => s >= atm);

          if (blueStrikeIndex > -1) {
            const blueStrike = sortedStrikes[blueStrikeIndex];

            const blueStrikeCallData = dBEDAS[dt][blueStrike]["c"];

            const blueStrikePutData = dBEDAS[dt][blueStrike]["p"];

            if (
              blueStrikeCallData != undefined &&
              changingDataByHkbId != undefined
            ) {
              const hbkId = blueStrikeCallData?.hkbInstrumentId;
              console.log("hbkId", hbkId, changingDataByHkbId[hbkId]);
              if (changingDataByHkbId[hbkId] != undefined) {
                changingDataByHkbId[hbkId].distanceType = "z blue-strike";
              }
            }
            if (
              blueStrikePutData != undefined &&
              changingDataByHkbId != undefined
            ) {
              const hbkId = blueStrikePutData?.hkbInstrumentId;
              console.log("hbkId", hbkId, changingDataByHkbId[hbkId]);
              if (changingDataByHkbId[hbkId] != undefined) {
                changingDataByHkbId[hbkId].distanceType = "z blue-strike";
              }
            }
          }
        }
        setWebsocketData(websocketData);
      }
    },
    [instrumentId, selectedDateIndex, dataByExpDatesAndStrikes, curExpDateKey]
  );

  const [liveUpdateStatus, setLiveUpdateStatus] = useState(false);

  const [wsStatus, setWsStatus] = useState("");

  useWebsocketSetup(
    instrumentId,
    optionChainData,
    selectedDateIndex,
    combineHttpAndWebsocketData,
    setLiveUpdateStatus,
    setWsStatus
  );

  useEffect(() => {
    let tm;
    if (wsStatus != "") {
      tm = setTimeout(() => {
        setWsStatus("");
      }, 3000);
    }

    return () => {
      clearTimeout(tm);
    };
  }, [wsStatus]);

  const [orders, setOrders] = useState([]);
  const [ordersFromAllPositions, setOrdersFromAllPositions] = useState([]);
  const disableTradeAll = useRef(false);
  const checkedOrdersCount = useRef(0);

  const [strategyInstanceName, setStrategyInstanceName] = useState("");

  const strategyNameChange = useCallback((e) => {
    setStrategyInstanceName(e.target.value);
  }, []);

  const dataToOrdersMap = useMemo(() => {
    const odisMap = {};

    let anyInvalidOrder = false,
      checkedTradesCount = 0;

    orders.forEach((o, ind) => {
      const hkbInstrumentId = o.hkbInstrumentId;

      const odisMapEleVal = odisMap[hkbInstrumentId];
      if (odisMapEleVal && odisMapEleVal[o.bs] != undefined) {
        return odisMapEleVal;
      }

      if (!odisMapEleVal) {
        odisMap[hkbInstrumentId] = {};
      }

      odisMap[hkbInstrumentId][o.bs] = ind;

      if (o.checked) {
        checkedTradesCount++;

        if (
          !anyInvalidOrder &&
          (o.limitPrice == undefined || o.limitPrice === "")
        ) {
          anyInvalidOrder = true;
        }
      }
    });

    disableTradeAll.current = checkedTradesCount < 1 || anyInvalidOrder;
    checkedOrdersCount.current = checkedTradesCount;

    return odisMap;
  }, [orders]);

  const [accounts, setAccounts] = useState([]);

  const [showSelectAccountsPane, setShowSelectAccountsPane] = useState(false);

  useAddPositionOrder(
    lastClickedButtonAction,
    instrumentId,
    optionChainData,
    selectedPositions,
    setAccounts,
    setRequestType,
    setShowSelectAccountsPane,
    setShowOrderPane,
    setOrders,
    setStrategyInstanceName,
    dataByHkbId,
    prevInstumentIdRef,
    receivedStrategyName
  );

  addOrdersForPayOffGraphFromPositions(
    lastClickedButtonAction,
    instrumentId,
    optionChainData,
    receivedAllPositions,
    dataByHkbId,
    setOrdersFromAllPositions
  );

  const bsClick = useCallback(
    (hkbInstrumentId, bs) => {
      if (lastClickedButtonAction === "Close") return;
      const dataItemIndex = dataByHkbId[hkbInstrumentId]?.initialIndex;
      // const [strike, optionType] = dataItem;
      // const expDateKey = dataByHkbId[hkbInstrumentId]?.expDateKey;

      setOrders((orders) => {
        let newOrders = [],
          selectedOrderFound = false;

        let positionOrderExists = false;
        for (let i = 0, len = orders.length; i < len; i++) {
          const o = orders[i];
          const selectedOrder =
            o.hkbInstrumentId == hkbInstrumentId && o.bs == bs;

          if (selectedOrder) {
            selectedOrderFound = true;
            if (o?.isAPosition) {
              positionOrderExists = true;
            } else {
              continue;
            }
          }

          newOrders.push({
            ...o,
          });
        }

        if (!selectedOrderFound) {
          const expDateKey = dataByHkbId[hkbInstrumentId]?.dtKey;
          const optionInfoList = optionChainData?.optionInfoList;
          const dataItem = optionInfoList[dataItemIndex];
          const strike = dataItem?.strike;
          const optionType = dataItem?.optionType;
          const ticker = dataItem?.ticker;
          const lotSize = dataItem?.lotSize;

          const ltp = dataItem?.ltp;

          const expDate = dataItem["expiryDate"];
          const dt = new Date(expDate);
          const dtText = `${paddedDates[dt.getDate()]} ${
            monthAbbrev[dt.getMonth()]["name"]
          }  '${dt.getFullYear() % 1000}`;

          newOrders.push({
            hkbInstrumentId,
            bs,
            lots: 1,
            type: optionType,
            dtKey: expDateKey,
            expDateText: dtText,
            checked: true,
            strike,
            ticker,
            lotSize,
            mProtect: 1,
            limitPrice: (
              Math.ceil(bsProps[bs].defaultLPMultiplier * ltp * 20 - 0.5) / 20
            )?.toFixed(2),
          });
        }

        if (positionOrderExists) {
          // notification.error({
          //   message: "Action not allowed",
          //   description: "Position orders can not be deleted",
          // });
          showNotification("error", "Position orders can not be deleted");
        }
        setShowOrderPane(newOrders.length > 0);

        return newOrders;
      });
    },
    // dataByHkbId wont change until optionChainData itself changes.
    [dataByHkbId, optionChainData, lastClickedButtonAction]
  );

  const [accountsFetchInProgress, setAccountsFetchInProgress] = useState(false);
  const [strategySubmitInProgress, setStrategySubmitInProgress] =
    useState(false);

  // Add logic for clearing the numbers of Option Chain pane rows
  const clearAllNewTrades = useCallback(() => {
    setOrders((orders) => {
      const newOrders = orders.filter((o) => o?.isAPosition);
      setShowOrderPane(newOrders.length > 0);
      return newOrders;
    });
  }, []);

  // No need to change
  const marketOrdersClick = useCallback(() => {
    setOrders((orders) => {
      return orders.map((o) => ({
        ...o,
        limitPrice: o.changedPositionType != "M" ? 0 : o.limitPrice,
      }));
    });
  }, []);

  const orderBSClick = useCallback((ind) => {
    setOrders((orders) => {
      const newOrders = orders.map((o) => ({ ...o }));
      const newOrder = newOrders[ind];
      newOrder["bs"] = bsProps[newOrder["bs"]]["alternative"];
      return newOrders;
    });
  }, []);

  const strikeChange = useCallback(
    (orderIndex, actionType, param) => {
      // Param would be the new strike chosen by user if actionType is dropdown
      //If actionType IncDec using +/- icons, param will denote which button it is
      setOrders((orders) => {
        const newOrders = orders.map((order, ind) => {
          return { ...order };
        });

        const od = newOrders[orderIndex];
        const curStrike = String(od?.strike);
        const dtKey = od?.dtKey;
        const expDateData = dataByExpDatesAndStrikes[dtKey];
        const sortedStrikes = expDateData["sortedStrikes"];
        let newStrike;

        if (actionType == "dropdown") {
          newStrike = param;
        } else {
          const sign = param;
          const curStrikePosition = sortedStrikes.findIndex(
            (stk) => stk === curStrike
          );

          if (sign == "+") {
            newStrike = sortedStrikes[curStrikePosition + 1];
          } else {
            newStrike = sortedStrikes[curStrikePosition - 1];
          }
        }

        const curOptionType = od?.type;

        let typeToUse = curOptionType;

        if (!expDateData[newStrike][curOptionType]) {
          typeToUse = optionTypeAbbr[type]["alternative"];
        }

        const dataItemIndex = expDateData[newStrike][typeToUse]["initialIndex"];
        const optionInfoList = optionChainData?.optionInfoList;

        // We need dataByExpDatesAndStrikes here, not dataByHkbId
        // Because we need to find the data item corresponding to the new type, and so we dont have its hkbInstrumentId
        const dataItem = optionInfoList[dataItemIndex];
        const hkbInstrumentId = dataItem["hkbInstrumentId"];
        const lotSize = dataItem["lotSize"];

        const { bs, expDateText, limitPrice, lots, mProtect } = od;

        const dataToOrderMapForNewStrikeAndType =
          dataToOrdersMap[hkbInstrumentId];

        const clashingOrderIndex = dataToOrderMapForNewStrikeAndType?.[bs];
        if (clashingOrderIndex != undefined) {
          // notification.error({
          //   message: "Strike change failed",
          //   description: `Order with the same data exists in row ${
          //     clashingOrderIndex + 1
          //   } already`,
          // });
          showNotification(
            "error",
            `Order with the same data exists in row ${
              clashingOrderIndex + 1
            } already`
          );
        } else {
          // if (!expDateData[newStrike][curOptionType]) {
          // If the order for the corresponding type does nt exist already and the data for curOptionType does nt exist in optionInfoList , rerplace the existing order with the data of the alternative type
          const ticker = dataItem?.ticker;

          newOrders[orderIndex] = {
            hkbInstrumentId,
            bs,
            checked: true, //Make it true even if it is currently checked, because if the user is changing the values it means he is interested in checking it
            dtKey: dtKey,
            expDateText,
            limitPrice,
            lots,
            mProtect,
            strike: parseInt(newStrike, 10),
            type: typeToUse,
            lotSize,
            ticker,
          };
        }
        //  else {
        //   od.strike = newStrike;
        // }

        return newOrders;
      });
    },
    [dataByExpDatesAndStrikes, optionChainData, dataToOrdersMap]
  );

  const orderTypeToggle = useCallback(
    (ind) => {
      setOrders((orders) => {
        const newOrders = orders.map((o) => ({ ...o }));
        const newOrder = newOrders[ind];

        const {
          bs,
          dtKey,
          expDateText,
          limitPrice,
          lots,
          mProtect,
          strike,
          type,
        } = newOrder;

        // need to access using newType, so byHkbId cant be used
        const newType = optionTypeAbbr[type]["alternative"];
        const dataItemIndex =
          dataByExpDatesAndStrikes[dtKey][strike][newType]["initialIndex"];
        // We need dataByExpDatesAndStrikes here, not dataByHkbId
        // Because we need to find the data item corresponding to the new type, and so we dont have its hkbInstrumentId
        const optionInfoList = optionChainData?.optionInfoList;

        // Data of the optionInfo corresponding to the new order is gathered below
        const dataItem = optionInfoList[dataItemIndex];
        const hkbInstrumentId = dataItem["hkbInstrumentId"];
        const lotSize = dataItem["lotSize"];
        const ticker = dataItem["ticker"];

        const dataToOrderMapForAlternativeType =
          dataToOrdersMap[hkbInstrumentId];

        const clashingOrderIndex = dataToOrderMapForAlternativeType?.[bs];
        if (clashingOrderIndex != undefined) {
          // notification.error({
          //   message: "Type change failed",
          //   description: `Order with the same data exists in row ${
          //     clashingOrderIndex + 1
          //   } already`,
          // });
          showNotification(
            `Order with the same data exists in row ${
              clashingOrderIndex + 1
            } already`
          );
        } else {
          newOrders[ind] = {
            hkbInstrumentId,
            bs: bs,
            checked: true,
            dtKey: dtKey,
            expDateText,
            limitPrice,
            lots,
            mProtect,
            strike,
            type: newType,
            lotSize,
            ticker,
          };
        }

        return newOrders;
      });
    },
    [dataByExpDatesAndStrikes, optionChainData, dataToOrdersMap]
  );

  // No need to change
  const lotsChange = useCallback((oi, actionType, param) => {
    setOrders((orders) => {
      const od = orders[oi];

      if (actionType == "text") {
        const val = param.trim();
        if (numericFieldsRegExp.test(val) || val === "") {
          od.lots = Number(val);
        }
      } else {
        const sign = param;
        if (sign == "+") {
          od.lots += 1;
        } else if (od.lots > 1) {
          od.lots -= 1;
        }
      }
      od.checked = true;

      return orders.map((order, ind) => {
        return { ...order };
      });
    });
  }, []);

  // No need to change
  const lpMpChange = useCallback((ind, lpMp, val) => {
    const trimmedVal = val.trim();
    if (numericFieldsRegExp.test(trimmedVal) || trimmedVal === "") {
      setOrders((orders) => {
        const newOrders = orders.map((o) => ({ ...o }));

        const changedOrder = newOrders[ind];

        changedOrder[lpMp] = trimmedVal;
        changedOrder.checked = true;

        if (ind == 0) {
          newOrders.forEach((no) => {
            if (
              no.changedPositionType != "M" &&
              (lpMp === "mProtect" || /^(0+(.0*)?|0*.0+)$/.test(trimmedVal))
            ) {
              no[lpMp] = trimmedVal;
              no.checked = true;
            }
          });
        }

        return newOrders;
      });
    } else {
      // notification.error({
      //   message: "Error",
      //   description: "Only numeric values allowed",
      // });
      showNotification("error", "Only numeric values allowed");
    }
  }, []);

  // No need to change
  const checkOrUncheckTradeRow = useCallback((ind) => {
    setOrders((orders) => {
      const newOrders = orders.map((o) => ({ ...o }));

      newOrders[ind].checked = !orders[ind].checked;
      return newOrders;
    });
  }, []);

  // Add logic for changing the number on OptionChain Row
  const deleteOrder = useCallback((ind) => {
    setOrders((orders) => {
      const newOrders = orders.filter((o, i) => i != ind);
      setShowOrderPane(newOrders.length > 0);
      return newOrders;
    });
  }, []);

  // Add Logic for order pane value change validations
  const expDateDropdownClick = useCallback((orderIndex, expDateText) => {
    setOrders((orders) => {
      const newOrders = orders.map((o) => ({ ...o }));
      newOrders[orderIndex].expDateText = expDateText;
      return newOrders;
    });
  }, []);

  // No need to change

  const checkOrUncheckAllTrades = useCallback(() => {
    setOrders((orders) => {
      const checkBoxValue = orders.length === checkedOrdersCount.current;
      return orders.map((o) => ({
        ...o,
        checked: o?.isAPosition || !checkBoxValue,
      }));
    });
  }, []);

  const closeAccountPane = useCallback(() => {
    setShowSelectAccountsPane(false);
  }, []);
  const [multipliers, setMultipliers] = useState([]);

  const [marginRequiredPerAccount, setMarginRequiredPerAccount] =
    useState(null);

  const getPortfolio = useRecoilValue(portfolioSelector);

  const [showTradeConfirmationPopup, setShowTradeConfirmationPopup] =
    useState(false);

  const tradeAllCancelCb = useCallback(() => {
    setShowTradeConfirmationPopup(false);
  }, []);

  const tradeAllConfirmCb = useCallback(async () => {
    setAccountsFetchInProgress(true);
    tradeAllCancelCb();

    try {
      const fetchResult = await fetchAccounts();

      const fetchList = fetchResult?.accountsList;
      const enabledList = [];
      const multipliers = [];
      for (let i = 0, len = fetchList.length, mLen = 0; i < len; i++) {
        const acc = fetchList[i];
        if (acc?.enabled) {
          enabledList.push({ ...acc });

          let strategyInstance = {};
          let multiplier = acc.defaultMultiplier;
          if (isNotCreateRequest) {
            const portfolio = getPortfolio(acc?.accountId);
            const strategyInstances = portfolio?.strategyInstances || [];
            strategyInstance = strategyInstances.find(
              (si) => si.strategyInstanceName === strategyInstanceName
            );
            multiplier = strategyInstance?.accountLevelMultipler;
          }

          multipliers[mLen] = {
            multiplier,
            priority: acc?.priority || 0,
          };
          multipliers[mLen]["originalMultiplier"] =
            multipliers[mLen]["multiplier"];
          ++mLen;
        }
      }

      setAccounts(enabledList);
      setMultipliers(multipliers);

      const marginRequiredAPIPayload = createStrategyReqBodyAndType(
        enabledList,
        orders,
        multipliers,
        strategyInstanceName
      );
      marginRequiredAPIPayload.strategyName = strategyName;
      const fetchResult2 = fetchMarginRequired(marginRequiredAPIPayload)
        .then((fmr) => {
          const marginInfo = fmr?.marginInfo || [];
          const perAccountMarginRequired = {};
          marginInfo.forEach((mi) => {
            perAccountMarginRequired[mi?.accountId] = {
              ...mi,
              marginRequiredRounded: Number(mi?.marginRequired.toFixed(2)),
            };
          });
          setMarginRequiredPerAccount(perAccountMarginRequired);
        })
        .catch((e) => {
          // notification.warning({
          //   message: "Failed to fetch MarginRequired",
          //   description: e?.message,
          // });
          e?.message == "Network Error" ? showNotification("error", "Error, no Internet Connection") : showNotification("warning", "Failed to fetch MarginRequired" + e?.message);
        });

      await fetchResult2;
      setShowSelectAccountsPane(true);
    } catch (e) {
      // notification.error({
      //   message: "Failed to fetch Accounts",
      //   description: e?.message,
      // });
      e?.message == "Network Error" ? showNotification("error", "Error, no Internet Connection") : showNotification("error", e?.message);
      if (process.env.DEV_SERVER_USER == "developer") {
        const fetchResult = {
          debugInfo: ["string"],
          status: "string",
          message: "string",
          errorcode: "string",
          accountsList: [
            {
              accountName: "string",
              accountId: "oswal_EHYD507648",
              brokerName: "string",
              marginUsed: 0,
              maginAvailable: 0,
              defaultMultiplier: 12,
              priority: 56,
              enabled: true,
            },
          ],
        };

        const fetchList = fetchResult?.accountsList;

        const enabledList = [];
        const multipliers = [];
        for (let i = 0, len = fetchList.length, mLen = 0; i < len; i++) {
          const acc = fetchList[i];
          if (acc?.enabled) {
            enabledList.push({ ...acc });
            let strategyInstance = {};
            if (isNotCreateRequest) {
              const portfolio = getPortfolio(acc?.accountId);
              const strategyInstances = portfolio.strategyInstances;
              strategyInstance = strategyInstances.filter(
                (si) => si.strategyInstanceName === strategyInstanceName
              );
            }

            multipliers[mLen] = {
              multiplier: strategyInstance
                ? strategyInstance.accountLevelMultipler
                : acc?.defaultMultiplier,
              priority: acc?.priority || 0,
            };

            multipliers[mLen]["originalMultiplier"] =
              multipliers[mLen]["multiplier"];
            ++mLen;
          }
        }

        setAccounts(enabledList);

        setMultipliers(multipliers);
        const marginRequiredAPIPayload = createStrategyReqBodyAndType(
          enabledList,
          orders,
          multipliers,
          strategyInstanceName
        );
        marginRequiredAPIPayload.strategyName = strategyName;
        const fetchResult2 = fetchMarginRequired(marginRequiredAPIPayload)
          .then((fmr) => {
            const marginInfo = fmr?.marginInfo || [];
            const perAccountMarginRequired = {};
            marginInfo.forEach((mi) => {
              perAccountMarginRequired[mi?.accountId] = {
                ...mi,
                marginRequiredRounded: Number(mi?.marginRequired.toFixed(2)),
              };
            });
            setMarginRequiredPerAccount(perAccountMarginRequired);
          })
          .catch(() => {});

        await fetchResult2;
        setShowSelectAccountsPane(process.env.DEV_SERVER_USER == "developer");
      }
    } finally {
      setAccountsFetchInProgress(false);
    }
  }, [isNotCreateRequest, strategyInstanceName, orders]);

  const tradeAllClick = useCallback(() => {
    setOrders((orders) => {
      const anyModifyOrders = orders.some(
        (o) => o.isAPosition && o.changedPositionType == "M"
      );
      if (anyModifyOrders) {
        setShowTradeConfirmationPopup(true);
      } else {
        tradeAllConfirmCb();
      }
      return orders;
    });
    setShowBulkOrdersPane(false);
  }, [tradeAllConfirmCb]);

  const changeMultiplier = useCallback(
    async (ind, prop, val) => {
      const trimmedVal = val.trim();
  
      if (numericFieldsRegExp.test(trimmedVal) || trimmedVal === "") {
        try {
          const newMultipliers = [...multipliers];
          newMultipliers[ind] = { ...(newMultipliers[ind] || {}), [prop]: val };
  
          const marginRequiredAPIPayload = createStrategyReqBodyAndType(
            [accounts[ind]],
            orders,
            newMultipliers,
            strategyInstanceName
          );
          marginRequiredAPIPayload.strategyName = strategyName;
  
          const fmr = await fetchMarginRequired(marginRequiredAPIPayload);
          const marginInfo = fmr?.marginInfo || [];
          const perAccountMarginRequired = { ...marginRequiredPerAccount };
  
          marginInfo.forEach((mi) => {
            perAccountMarginRequired[mi.accountId] = {
              ...(perAccountMarginRequired[mi.accountId] || {}),
              ...mi,
              marginRequiredRounded: Number(mi.marginRequired.toFixed(2)),
            };
          });
  
          setMarginRequiredPerAccount(perAccountMarginRequired);
          setMultipliers(newMultipliers);

        } catch (error) {
          console.error("Failed to fetch margin info:", error);
          // Optionally, handle the error with a user notification
          e?.message == "Network Error" ? showNotification("error", "Error, no Internet Connection") : showNotification("error", "Failed to fetch margin info");
        }
      } else {
        showNotification("error", "Only numeric values are allowed.");
      }
    },
    [accounts, orders, multipliers, strategyInstanceName, strategyName, marginRequiredPerAccount]
  );
  

  const disableAcSubmit = useMemo(() => {
    // const inValidMultiplier = multipliers.some(
    //   (m, ind) =>
    //     m?.multiplier === "" ||
    //     m?.multiplier == undefined ||
    //     m?.priority === "" ||
    //     m?.priority == undefined
    // );

    return strategyInstanceName.length < 1;
  }, [strategyInstanceName, multipliers]);

  const createStrategySubmit = useCallback(async () => {
    setStrategySubmitInProgress(true);
    const reqBody = createStrategyReqBodyAndType(
      accounts,
      orders,
      multipliers,
      strategyInstanceName,
      requestType,
      userPreference
    );

    reqBody.strategyName = isNotCreateRequest
      ? strategyName
      : strategyInstanceName;


    let reqStatus, resType;
    try {
      const res = await cmcStrategy(reqBody, requestType);
      reqStatus = `Strategy ${reqTypeMsgMap[requestType]} Was Successful`;
      resType = "success";
      setOrders([]);
      setShowSelectAccountsPane(false);
      setShowOrderPane(false);
    } catch (e) {

      reqStatus = e?.message == "Network Error" ? "Error, no Internet Connection" : e?.message || "Error";
      resType = "fail";
    } finally {
      setStrategySubmitInProgress(false);
    }

    setSelectedPositions([]);

    if (resType == "success") {
      // notification.success({
      //   message: "Success",
      //   description: reqStatus,
      // });
      showNotification("success", reqStatus);
    } else {
      // notification.error({
      //   message: `Strategy ${reqTypeMsgMap[requestType]} failed`,
      //   description: reqStatus,
      // });
      showNotification("error", reqStatus);
    }
  }, [accounts, multipliers, strategyInstanceName, strategyName, orders,userPreference]);

  const numOfOrders = orders.length;
  const changingDataByHkbId = changingDataByHkbIdRef.current;

  const openOrdersPane = useCallback(() => {
    setShowOrderPane(numOfOrders > 0);
  }, [numOfOrders]);

  const osTableBodyRef = useRef(null);

  const ordersPaneRef = useRef(null);

  const [ordersPaneHeight, setOrdersPaneHeight] = useState("32px");

  useEffect(() => {
    if (showOrderPane && ordersPaneRef.current != null) {
      setOrdersPaneHeight(`${ordersPaneRef.current.offsetHeight}px`);
    } else if (!showOrderPane) {
      setOrdersPaneHeight(`0px`);
    }
  }, [numOfOrders, showOrderPane]);

  useBlueStripNav(curExpDateData, osTableBodyRef);

  const toggleCloseAndModify = useCallback((ind) => {
    setOrders((orders) => {
      const newOrders = orders.map((o) => ({ ...o }));

      const newOrder = newOrders[ind];
      newOrder["changedPositionType"] =
        closeIcon[newOrder["changedPositionType"]]?.alternative;
      newOrder["lots"] = newOrder["lotsOriginal"];
      newOrder["limitPrice"] = "";
      newOrder["mProtect"] = "";
      newOrder["checked"] = newOrder["changedPositionType"] != "M";
      return newOrders;
    });
  }, []);

  const closeOrdersPane = useCallback(() => {
    setOrdersPaneHeight("0px");
    setOrders([]);
    setShowOrderPane(false);
    setShowSelectAccountsPane(false);
    if (!isNotCreateRequest) {
      setStrategyInstanceName("");
    }
  }, [isNotCreateRequest]);

  const [showBulkOrdersPane, setShowBulkOrdersPane] = useState(false);

  const addBulkOrders = useCallback(
    async ({
      text: bulkOrdersText,
      instrumentId,
      dataToOrdersMap,
      optionChainData,
      dataByHkbId,
    }) => {
      try {
        const result = await parseRawOrders(instrumentId, bulkOrdersText);
        const parsedOrders = Array.isArray(result?.orders)
          ? result.orders
          : emptyArray;

        // Identify duplicates
        const duplicates = parsedOrders.filter(
          ({ hkbInstrumentId }) => dataToOrdersMap[hkbInstrumentId]
        );

        console.log(
          "duplicates :>> ",
          parsedOrders,
          dataToOrdersMap,
          duplicates
        );

        let ordersToAdd = parsedOrders;

        // If duplicates exist, confirm user decision
        if (duplicates.length) {
          const ignoreDuplicates = await confirmDuplicateAction();
          if (ignoreDuplicates) {
            // Filter out duplicates if user chooses to ignore them
            ordersToAdd = parsedOrders.filter(
              ({ hkbInstrumentId }) => !dataToOrdersMap[hkbInstrumentId]
            );
          }
        }

        const newOrders = [];

        ordersToAdd.forEach((no) => {
          const {
            hkbInstrumentId,
            price: limitPrice,
            marketProtect: mProtect,
            orderSide,
            quantity,
          } = no;

          const bs = (orderSide[0] ?? "").toUpperCase();
          // const dataToOrderMap = dataToOrdersMap[hkbInstrumentId];

          // const selectedOrderFound = dataToOrderMap && dataToOrderMap[bs];

          // if (bs?.length && selectedOrderFound) {
          //   return false;
          // } else {
          const dataItemIndex = dataByHkbId[hkbInstrumentId]?.initialIndex;
          const expDateKey = dataByHkbId[hkbInstrumentId]?.dtKey;
          const optionInfoList = optionChainData?.optionInfoList;
          const dataItem = optionInfoList[dataItemIndex];
          const strike = dataItem?.strike;
          const optionType = dataItem?.optionType;
          const ticker = dataItem?.ticker;
          const lotSize = dataItem?.lotSize;

          const ltp = dataItem?.ltp;

          const expDate = dataItem["expiryDate"];
          const dt = new Date(expDate);
          const dtText = `${paddedDates[dt.getDate()]} ${
            monthAbbrev[dt.getMonth()]["name"]
          }  '${dt.getFullYear() % 1000}`;

          const lots =
            quantity != undefined ? Math.abs(Number(quantity) / lotSize) : 1;

          newOrders.push({
            hkbInstrumentId,
            bs,
            lots,
            type: optionType,
            dtKey: expDateKey,
            expDateText: dtText,
            checked: true,
            strike,
            ticker,
            lotSize,
            mProtect: mProtect ?? 1,
            limitPrice:
              limitPrice ?? (bsProps[bs].defaultLPMultiplier * ltp)?.toFixed(2),
          });
          // }
        });

        setOrders((o) => {
          return [...o, ...newOrders];
        });
        // setShowOrderPane(newOrders.length > 0);
        setShowOrderPane(true);
        showNotification("success", "Added bulk orders");
        return true;
      } catch (e) {
        // notification.error({
        //   message: `Bulk orders creation failed`,
        //   description: e?.message,
        // });
        showNotification("error", e?.message);
        return false;
      }
    },
    []
  );

  const ordersTableBody = () => {
    return (
    <div className="orders-table-body">
      {orders.map((order, ind) => {
        const {
          bs,
          dtKey,
          hkbInstrumentId,
          isAPosition,
          limitPrice = "",
          lots,
          mProtect = "",
          strike,
          type,
          positionType,
          changedPositionType,
        } = order;

        const dataByExpDate = dataByExpDatesAndStrikes[dtKey];

        const dataByExpDateAndStrike = dataByExpDate[strike];
        const changingData = changingDataByHkbId[hkbInstrumentId];

        const dataToOrderMap = dataToOrdersMap[hkbInstrumentId];
        const disableBSToggle =
            isAPosition ||
            dataToOrderMap[bsProps[bs].alternative] != undefined;

        const alternativeType = optionTypeAbbr[type]["alternative"];
        const dataForAlternativeType =
            dataByExpDateAndStrike[alternativeType];

        const disableTypeToggle =
            isAPosition || dataForAlternativeType == undefined;

        const disableLotInc =isAPosition;

        const disableLotDec =
            //commenting we will allow reducing lots for positions during close time
            lots == 1; //|| (isAPosition && positionType == "C");

        const sortedStrikes = dataByExpDate["sortedStrikes"];

        return (
            <div
                className={`orders-table-br ${
                    isAPosition ? "position-order" : ""
                }`}
                key={`${hkbInstrumentId}-${bs}`}
            >
              {isAPosition && positionType === "C" ? (
                  <span
                      className={`orders-table-br-cell orders-table-br-cell-poc-cl-icon flex-center`}
                      title="Close for position"
                  ></span>
              ) : isAPosition ? (
                  <span
                      className={`orders-table-br-cell orders-table-br-cell-poc-cl-edit-icon flex-center ${changedPositionType}`}
                      title={closeIcon[changedPositionType]["title"]}
                      onClick={() => toggleCloseAndModify(ind)}
                  ></span>
              ) : (
                  <button
                      className={`orders-table-br-cell orders-table-br-cell-cb ${
                          order["checked"] ? "checked" : ""
                      }`}
                      onClick={() => checkOrUncheckTradeRow(ind)}
                      disabled={isAPosition}
                  ></button>
              )}
              <button
                  className={`orders-table-br-cell orders-table-br-cell-bs ${bsProps[bs]["cn"]}`}
                  disabled={disableBSToggle}
                  onClick={() => orderBSClick(ind)}
              >
                {bs}
              </button>
              <div className="orders-table-br-cell orders-table-br-cell-exp">
                <select disabled={isAPosition || true} value={dtKey}>
                  {/* We are disabling expiry date change for now */}
                  {expDates.map((exp) => {
                    return (
                        <option key={exp.dtKey} value={exp.dtKey}>
                          {exp.dtText}
                        </option>
                    );
                  })}
                </select>
              </div>
              <div className="orders-table-br-cell orders-table-br-cell-stk">
                <button
                    className={`strike-chg strike-chg-dec all-unset`}
                    disabled={isAPosition || strike == sortedStrikes[0]}
                    onClick={() => strikeChange(ind, "inc-dec", "-")}
                >
                  -
                </button>

                <select
                    className="all-unset stk-dropdown text-center"
                    disabled={isAPosition}
                    value={strike}
                    onChange={(e) =>
                        strikeChange(ind, "dropdown", e.target.value)
                    }
                >
                  {sortedStrikes.map((stk) => {
                    return (
                        <option key={stk} value={stk}>
                          {stk}
                        </option>
                    );
                  })}
                </select>
                <button
                    className="strike-chg strike-chg-inc all-unset"
                    onClick={() => strikeChange(ind, "inc-dec", "+")}
                    disabled={
                        isAPosition ||
                        strike == sortedStrikes[sortedStrikes.length - 1]
                    }
                >
                  +
                </button>
              </div>
              <button
                  className="orders-table-br-cell orders-table-br-cell-type"
                  disabled={disableTypeToggle}
                  onClick={() => orderTypeToggle(ind)}
              >
                {optionTypeAbbr[type]["name"]}
              </button>
              <div className="orders-table-br-cell orders-table-br-cell-lots">
                <button
                    className={`lots-chg lots-chg-dec`}
                    disabled={disableLotDec}
                    onClick={() => lotsChange(ind, "inc-dec", "-")}
                >
                  -
                </button>
                {/* <input
                          value={lots}
                          className="all-unset lots-chg-input text-center"
                          disabled={disableLotInc && disableLotDec}
                          onInput={(e) =>
                            lotsChange(ind, "text", e.target.value)
                          }
                        /> */}

                <OverwriteInput
                    onChange={(value) => lotsChange(ind, "text", value)}
                    value={lots}
                    className="all-unset lots-chg-input text-center"
                    disabled={disableLotInc && disableLotDec}
                />
                <button
                    className="lots-chg lots-chg-inc"
                    onClick={() => lotsChange(ind, "inc-dec", "+")}
                    disabled={disableLotInc}
                >
                  +
                </button>
              </div>
              <button
                  className="all-unset orders-table-br-cell orders-table-br-cell-ltp"
                  onClick={(e) => {
                    ordersLtpClick(e.target, hkbInstrumentId);
                  }}
              >
                {changingData["ltpRounded"]}
              </button>
              <div className="orders-table-br-cell orders-table-br-cell-lp">
                {/* <input
                          value={limitPrice}
                          onInput={(e) =>
                            lpMpChange(ind, "limitPrice", e.target.value)
                          }
                          placeholder="123"
                          className="all-unset text-center lp-input"
                          disabled={changedPositionType == "M"}
                        /> */}

                <OverwriteInput
                    onChange={(value) =>
                        lpMpChange(ind, "limitPrice", value)
                    }
                    value={limitPrice}
                    className="all-unset text-center lp-input"
                    disabled={changedPositionType == "M"}
                />
              </div>

              <div className="orders-table-br-cell orders-table-br-cell-mp">
                {/* <input
                          value={mProtect}
                          onInput={(e) =>
                            lpMpChange(ind, "mProtect", e.target.value)
                          }
                          placeholder="123"
                          className="all-unset text-center mp-input"
                          disabled={changedPositionType == "M"}
                        /> */}

                <OverwriteInput
                    onChange={(value) =>
                        lpMpChange(ind, "mProtect", value)
                    }
                    value={mProtect}
                    placeholder="123"
                    className="all-unset text-center mp-input"
                    disabled={changedPositionType == "M"}
                />
              </div>

              <div className="orders-table-br-cell orders-table-br-cell-delete">
                {!isAPosition && (
                    <button
                        className="all-unset orders-table-br-cell-delete-btn flex-center"
                        onClick={() => deleteOrder(ind)}
                        disabled={isAPosition}
                    >
                      &#x1f5d1;
                    </button>
                )}
              </div>
            </div>
        );
      })}
    </div>
    );
  };

  const ordersPane = () => {
    return (
    <div className="oc-orders-pane flex-column" ref={ordersPaneRef}>
      {showTradeConfirmationPopup && (
          <ConfirmationPopup
              warningText={`You have orders which you have not chosen to close or open. Do you still want to proceed?`}
              yesCb={tradeAllConfirmCb}
              noCb={tradeAllCancelCb}
          />
      )}
      {accountsFetchInProgress && <LoaderPopup />}
      <div className="oc-op-header">
        <div
            className={`oc-op-sn ${isNotCreateRequest ? "" : "invisible"}`}
        >
          Strategy:{" "}
          <span className="oc-op-strategy-name bold">
                  {isNotCreateRequest ? strategyInstanceName : ""}
                </span>
        </div>
        <div className="oc-op-ts flex">
          <div className="oc-op-ts-count bold">
            {checkedOrdersCount.current}
          </div>
          trade(s) selected
        </div>
        <button
            className="oc-op-cnt text-only-button"
            onClick={clearAllNewTrades}
        >
          Clear new trades
        </button>

        <button
            className="oc-op-mo text-only-button"
            onClick={marketOrdersClick}
        >
          Market orders
        </button>
        <div style={{ clear: "right" }} className="oc-op-lud">
          LUT : {lastUpdateTime}
        </div>

        <button
            className="oc-op-close-btn"
            onClick={closeOrdersPane}
            title="Close"
        >
          &#x2715;
        </button>
      </div>

      <div className="orders-table">
        <OrdersTableHeader
            numOfOrders={numOfOrders}
            checkedOrdersCount={checkedOrdersCount.current}
            checkOrUncheckAllTrades={checkOrUncheckAllTrades}
        />

        {ordersTableBody()}

      </div>
      <div className="oc-op-footer">
        {numOfOrders > 0 && (
            <button
                disabled={disableTradeAll.current}
                className="oc-op-ta flex-center"
                onClick={tradeAllClick}
            >
              Trade all
            </button>
        )}
      </div>
    </div>
    );
  };

  const chartRef = useRef(null);

  const addBulkOrdersMemo = useCallback(
    (text) => {
      return addBulkOrders({
        text,
        instrumentId,
        dataToOrdersMap,
        optionChainData,
        dataByHkbId,
      });
    },
    [instrumentId, dataToOrdersMap, optionChainData, dataByHkbId, addBulkOrders]
  );

  if (optionChainData == undefined) {
    return <div className="oc-whole loading">Loading...</div>;
  }


  let stockStrikes = [];
  let naerStockIndex = 0;
  for (let index = 0; index < strikesSorted.length ; index +=1 ){
    if (strikesSorted[index] <= atm  && atm < strikesSorted[index+1] ) {
       naerStockIndex = index;
    }
  }
  for (let index = 0; index < strikesSorted.length ; index +=1 ){
    if (index >= naerStockIndex - 7 &&  index < naerStockIndex + 7) {
        stockStrikes.push(strikesSorted[index]);
    }
  }
  stockStrikes.push(atm);
  stockStrikes = stockStrikes.sort((a, b) => a-b);


  return (
    <div className="oc-whole">
      <div className="oc-exp-dates-so-sec">
        <div className="oc-exp-dates-sec">
          <div
            className={`oc-exp-dates-prev oc-exp-dates-nav ${
              curDateNavIndex == 0 ? "disabled" : ""
            }`}
            onClick={datesNavLeftClick}
          >
            &#x276C;
          </div>
          <div
            className={osDatesClassName}
            style={{
              maxWidth: datesElemMaxWidthRef.current,
            }}
          >
            <div
              className="oc-exp-dates-moving-strip"
              style={{
                left: datesElemLeftRef.current,
              }}
            >
              {expDates.map((exp, i) => {
                return (
                  <div
                    key={exp.dtKey}
                    className={`${
                      i == selectedDateIndex ? "selected" : ""
                    } oc-exp-date flex-center text-only-button`}
                    onClick={(e) => setSelectedDateIndex(i)}
                  >
                    {exp.dtText}
                  </div>
                );
              })}
            </div>
          </div>

          {/* not disabling right side navigation as there is some bug, it's always enabled for now*/}
          <div
            className={`oc-exp-dates-next oc-exp-dates-nav ${
              curDateNavIndex + totalDatesFitRef.current ===
              totalDateElemsRef.current
                ? ""
                : ""
            }`}
            onClick={datesNavRightClick}
          >
            &#x276D;
          </div>
        </div>
        <div
          className={`ws-status-strip ${
            wsStatus?.length > 0 ? "" : "invisible"
          }`}
        >
          {wsStatus}
        </div>
        <Notification />
        <button
          onClick={openOrdersPane}
          className="oc-hcell-open-orders"
          disabled={numOfOrders < 1}
        >
          Orders
        </button>
      </div>

      <div className="oc-table-op-sec">
        <div className="oc-table">
          <OcTableHeader
            stockName={stockName}
            atm={atm}
            liveUpdateStatus={liveUpdateStatus}
            lastUpdateTime={lastUpdateTime}
            setShowBulkOrdersPane={setShowBulkOrdersPane}
          />
          <div
            id="oc-table-body"
            className="oc-table-body"
            ref={osTableBodyRef}
          >
            {strikesSorted.map((s, ind) => {
              const callData = curExpDateData[s]["c"] || {};
              const putData = curExpDateData[s]["p"] || {};

              const callHkbId = callData?.hkbInstrumentId;
              const putHkbId = putData?.hkbInstrumentId;

              const callOrders = dataToOrdersMap[callHkbId] || {};
              const callOrderIndexBuy = callOrders["B"];
              const callOrderIndexSell = callOrders["S"];

              const putOrders = dataToOrdersMap[putHkbId] || {};

              const putOrderIndexBuy = putOrders["B"];
              const putOrderIndexSell = putOrders["S"];

              let callOrderBuy = orders[callOrderIndexBuy];
              let callOrderSell = orders[callOrderIndexSell];

              let putOrderBuy = orders[putOrderIndexBuy];
              let putOrderSell = orders[putOrderIndexSell];

              const changingCallData = changingDataByHkbId[callHkbId];
              const changingPutData = changingDataByHkbId[putHkbId];

              return (
                <div
                  className={`oc-br  ${
                    changingCallData?.distanceType ||
                    changingPutData?.distanceType
                  }`}
                  id={`oc-br-${ind}`}
                  key={`${callHkbId}-${putHkbId}`}
                >
                  <div
                    className={`oc-br-opbs oc-br-cell oc-br-c-cell  oc-br-${ind}-cell-opbs`}
                  > 
                    <button
                      title="Buy"
                      className={`oc-br-opbs-bs oc-br-opbs-b ${
                        callOrderBuy?.lots > 0 && "has-orders"
                      }`}
                      onClick={() => bsClick(callData?.hkbInstrumentId, "B")}
                      disabled={!changingCallData}
                    >
                      {callData && "B"}
                      {callOrderBuy && (
                        <span className="oc-br-opbs-li b">
                          {callOrderBuy?.lots}
                        </span>
                      )}
                    </button>
                    <button
                      title="Sell"
                      className={`oc-br-opbs-bs oc-br-opbs-s ${
                        callOrderSell?.lots > 0 && "has-orders"
                      }`}
                      onClick={() => bsClick(callData?.hkbInstrumentId, "S")}
                      disabled={!changingCallData}
                    >
                      {callData && "S"}

                      {callOrderSell && (
                        <span className="oc-br-opbs-li s">
                          {callOrderSell?.lots}
                        </span>
                      )}
                    </button>
                  </div>
                  
                  <div
                    className={`oc-br-o oc-br-c-cell oc-br-cell oc-br-${ind}-cell-o`}
                  >
                    {changingCallData?.oiRounded || "---"}
                  </div>
                  <button
                    className={`oc-br-a oc-br-c-cell oc-br-cell oc-br-${ind}-cell-a text-only-button-with-bg`}
                    onClick={(e) => {
                      ordersLtpClick(e.target, callHkbId);
                    }}
                  >
                    {changingCallData?.askRounded || "---"}
                  </button>
                  <button
                    className={`oc-br-b oc-br-c-cell oc-br-cell oc-br-${ind}-cell-b text-only-button-with-bg`}
                    onClick={(e) => {
                      ordersLtpClick(e.target, callHkbId);
                    }}
                  >
                    {changingCallData?.bidRounded || "---"}
                  </button>
                  <div
                    className={`oc-br-l oc-br-c-cell oc-br-cell oc-br-${ind}-cell-l`}
                  >
                   {changingCallData?.ltpRounded || "---" } { changingCallData &&(  <span>( {changingCallData?.delta} ) </span>)}
                  </div>
                  <div className={`oc-br-s oc-br-cell oc-br-${ind}-cell-s`}>
                    {s} ( {changingCallData?.dist || changingPutData?.dist} )
                  </div>
                  <div
                    className={`oc-br-l oc-br-p-cell oc-br-cell oc-br-${ind}-cell-l`}
                  >
                    {changingPutData?.ltpRounded || "---" } { changingPutData &&(  <span>( {changingPutData?.delta} ) </span>)}
                  </div>
                  <button
                    className={`oc-br-a oc-br-p-cell oc-br-cell oc-br-${ind}-cell-a text-only-button-with-bg`}
                    onClick={(e) => {
                      ordersLtpClick(e.target, putHkbId);
                    }}
                  >
                    {changingPutData?.askRounded || "---"}
                  </button>
                  <button
                    className={`oc-br-b oc-br-p-cell oc-br-cell oc-br-${ind}-cell-b text-only-button-with-bg`}
                    onClick={(e) => {
                      ordersLtpClick(e.target, putHkbId);
                    }}
                  >
                    {changingPutData?.bidRounded || "---"}
                  </button>
                  <div
                    className={`oc-br-o oc-br-p-cell oc-br-cell oc-br-${ind}-cell-o`}
                  >
                    {changingPutData?.oiRounded || "---"}
                  </div>
                  <div
                    className={`oc-br-opbs oc-br-p-cell oc-br-cell oc-br-${ind}-cell-opbs`}
                  >
                    <button
                      title="Buy"
                      className={`oc-br-opbs-bs oc-br-opbs-b ${
                        putOrderBuy?.lots > 0 && "has-orders"
                      }`}
                      onClick={() => bsClick(putData?.hkbInstrumentId, "B")}
                      disabled={!changingPutData}
                    >
                      {putData && "B"}
                      {putOrderBuy && (
                        <span className="oc-br-opbs-li b">
                          {putOrderBuy?.lots}
                        </span>
                      )}
                    </button>
                    <button
                      title="Sell"
                      className={`oc-br-opbs-bs oc-br-opbs-s ${
                        putOrderSell?.lots > 0 && "has-orders"
                      }`}
                      onClick={() => bsClick(putData?.hkbInstrumentId, "S")}
                      disabled={!changingPutData}
                    >
                      {putData && "S"}

                      {putOrderSell && (
                        <span className="oc-br-opbs-li s">
                          {putOrderSell?.lots}
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {depthInfo?.isModalVisible && (
          <DepthPopup
            setIsModalVisible={(v) => {
              const d = { ...depthInfoInit, isModalVisible: v };
              setDepthInfo(d);
            }}
            depthInfo={depthInfo}
            container={modalContainerRef.current}
          />
        )}



        {showOrderPane === false ? null : (


          debugMode === false ? ordersPane() : (
            <Tabs>
              <TabPane tab="Strategy" key="1">

                <PayoffGraph orders={[...orders,...ordersFromAllPositions] } changingDataByHkbId = {changingDataByHkbId} currentStockPrice={atm} stockPrices = {stockStrikes}/>
              </TabPane>
              <TabPane tab="Orders" key="2">
                {ordersPane()}
                </TabPane>
                
            </Tabs>)

        )}

        {showStrategyPanel && (
          <StrategyCreationPanel ordersPaneHeight={ordersPaneHeight} />
        )}

        {showSelectAccountsPane && (
          <div
            className="oc-asp"
            style={{
              height: `calc(100vh - ${ordersPaneHeight})`,
            }}
          >
            {strategySubmitInProgress && <LoaderPopup />}

            <button className="oc-acp-close-btn" onClick={closeAccountPane}>
              {" "}
              <span className="oc-acp-close-btn-icon">&#x276C;</span> Back
            </button>
            <div className="oc-acp-title-and-name">
              <div className="oc-acp-title">Account Selection</div>
<Flex >
              <div className="oc-acp-strategy-name-sec">
                <label
                  htmlFor="strategy-name-inp"
                  className="oc-acp-strategy-name-label"
                >
                  Strategy name:
                </label>{" "}
                <input
                  id="strategy-name-inp"
                  className="oc-acp-strategy-name-inp"
                  onInput={strategyNameChange}
                  disabled={isNotCreateRequest}
                  defaultValue={strategyInstanceName}
                />
              </div>

              <div className="oc-acp-strategy-name-sec" style={{marginLeft:'15px'}}>
            <label
              htmlFor="strategy-name-inp"
              className="oc-acp-strategy-name-label"
            >
              Order exec mode:
            </label>{" "}
            <Select value={userPreference} onChange={handleUserPreferenceChange} style={{marginLeft:"8px", width: 100 }}>
              <Select.Option value="run">Auto</Select.Option>
              <Select.Option value="pause">Manual</Select.Option>
            </Select>
          </div>
          </Flex>
            </div>

            <div className="oc-acp-table">
              <AcpTableHeader />
              <div className="oc-acp-body">
                {accounts.map((account, i) => {
                  const multiplier = multipliers?.[i]?.multiplier;

                  const originalMultiplier =
                    multipliers?.[i]?.originalMultiplier;

                  const priority = multipliers?.[i]?.priority;
                  const { accountId, clientId, maginAvailable } = account;

                  const accMarginInfo = marginRequiredPerAccount?.[accountId];
                  let accMarginrequired = "NA"
                  let expectedFreeMarginAfterTrade = "NA",
                    greenRed = "NA";
                  //let finalMargin = "NA";

                  if (
                    multiplier !== "" &&
                    multiplier != undefined &&
                      accMarginInfo !== "" &&
                      accMarginInfo != undefined
                  ) {
                    accMarginrequired = accMarginInfo.marginRequiredRounded;
                    let finalMargin = accMarginrequired + accMarginInfo?.marginUsed
                    expectedFreeMarginAfterTrade = maginAvailable - accMarginrequired

                    const totalMargin =
                      maginAvailable + accMarginInfo?.marginUsed;

                    const percentage = finalMargin / totalMargin;
                    //console.log("margin % after trade: ", percentage)
                    //if NAN then dontknow
                    if (isNaN(percentage) || maginAvailable < 0) {
                      greenRed = "dontknow"
                    } else {
                      greenRed =
                          percentage < 0.8
                              ? "green"
                              : percentage < 0.95
                                  ? "yellow"
                                  : "red";
                    }
                  }

                  return (
                    <div key={accountId} className={`oc-acp-br oc-acp-br-${i}`}>
                      <div className="oc-acp-br-cell oc-acp-br-cell-a">
                        {clientId}
                      </div>
                      <div className="oc-acp-br-cell oc-acp-br-cell-ma">
                        {formatCurrency(account?.maginAvailable)}
                      </div>
                      <div className="oc-acp-br-cell oc-acp-br-cell-mtplr">
                        {isNotCreateRequest ? (
                          <button
                            onClick={(e) =>
                              changeMultiplier(
                                i,
                                "multiplier",
                                multiplier == originalMultiplier
                                  ? "0"
                                  : `${originalMultiplier || 0}`
                              )
                            }
                            className="all-unset  text-center"
                          >
                            {multiplier || 0}
                          </button>
                        ) : (
                          // <input
                          //   value={multiplier}
                          //   placeholder="123"
                          //   onInput={(e) =>
                          //     changeMultiplier(i, "multiplier", e.target.value)
                          //   }
                          //   className="all-unset  text-center"
                          // />
                          <OverwriteInput
                            placeholder="123"
                            onChange={(value) =>
                              changeMultiplier(i, "multiplier", value)
                            }
                            value={multiplier}
                            className="all-unset  text-center"
                          />
                        )}
                      </div>
                      <div className="oc-acp-br-cell oc-acp-br-cell-pr">
                        {/* <input
                          placeholder="123"
                          value={priority}
                          onInput={(e) =>
                            changeMultiplier(i, "priority", e.target.value)
                          }
                          className="all-unset text-center"
                        /> */}

                        <OverwriteInput
                          placeholder="123"
                          onChange={(value) =>
                            changeMultiplier(i, "priority", value)
                          }
                          value={priority}
                          className="all-unset  text-center"
                        />
                      </div>

                      <div className="oc-acp-br-cell oc-acp-br-cell-mrq">
                        {formatCurrency(accMarginrequired)}{" "}
                      </div>

                      <div className="oc-acp-br-cell oc-acp-br-cell-mao">
                        {formatCurrency(expectedFreeMarginAfterTrade)}{" "}
                      </div>
                      <div
                        className={`oc-acp-br-cell oc-acp-br-cell-check ${greenRed}`}
                      ></div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="oc-acp-submit-row">
              <div className="oc-acp-submit-note">
                ***Note: Multipliers, Priorities
                {isNotCreateRequest || " and Strategy Name"} are required fields
              </div>
              <button
                className="oc-acp-submit"
                disabled={disableAcSubmit}
                onClick={createStrategySubmit}
              >
                Submit
              </button>
            </div>
          </div>
        )}

        {showBulkOrdersPane && (
          <BulkOrdersPopup
            ordersPaneHeight={showOrderPane === false ? 0 : ordersPaneHeight}
            setShowBulkOrdersPane={setShowBulkOrdersPane}
            addBulkOrders={addBulkOrdersMemo}
          />
        )}
      </div>
    </div>
  );
};

const ocop = React.memo(OptionChainAndOrderPane);

export { ocop as OptionChainAndOrderPane };
