const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("Asia/Kolkata");

function groupPositionsByAccount(data) {
  const groupedData = {};

  if (!data) return groupedData;

  for (const item of data) {
    const { accountId, strategy, positions } = item;

    // Initialize account group if it doesn't exist
    if (!groupedData[accountId]) {
      groupedData[accountId] = {
        isSynced: true, // Assume true to start with
        strategies: {},
      };
    }

    // Initialize strategy group within the account if it doesn't exist
    if (!groupedData[accountId].strategies[strategy]) {
      groupedData[accountId].strategies[strategy] = {
        isSynced: true, // Assume true to start with
        positions: [],
        accountId,
      };
    }

    // If any position in this strategy is not synced, set isSynced to false at the strategy level
    if (positions.some((pos) => !pos.isSynced)) {
      groupedData[accountId].strategies[strategy].isSynced = false;
    }

    // If any position in this strategy is not synced, set isSynced to false at the account level
    if (positions.some((pos) => !pos.isSynced)) {
      groupedData[accountId].isSynced = false;
    }

    // Group the positions under the strategy
    groupedData[accountId].strategies[strategy].positions.push(...positions);
  }

  return groupedData;
}

function groupPositionsByStrategy(portfolios: Portfolio[]): GroupedPositions {
  const groupedPositions: GroupedPositions = {};
  if (!portfolios) return {};

  portfolios.forEach((portfolio) => {
    portfolio.strategyInstances &&
      portfolio.strategyInstances.forEach((strategy) => {
        if (!groupedPositions[strategy.strategyInstanceName]) {
          groupedPositions[strategy.strategyInstanceName] = { accounts: [] };
        }

        const { strategyInstances, ...restOfProperties } = portfolio;

        const account = {
          ...restOfProperties,
          accountId: portfolio.accountId,
          accountName: portfolio.accountName,
          positions: strategy.hkbPositions,
          strategyInstanceName: strategy.strategyInstanceName,
          strategy,
        };

        groupedPositions[strategy.strategyInstanceName].accounts.push(account);

        groupedPositions[strategy.strategyInstanceName].strategy = strategy;
      });
  });

  return groupedPositions;
}

function getFormattedQuantity(position: Position) {
  const netQuantity =
    position.buyNetFilledQuantity - position.sellNetFilledQuantity;
  // let quantity = `${netQuantity}, (${position.buyTodayFilledQuantity} / ${position.buyTodaySubmittedQuantity}), (- ${position.sellTodayFilledQuantity} / ${position.sellTodaySubmittedQuantity})`;

  return netQuantity;
}

function getNetQuantity(position: Position) {
  const netQuantity =
    position.buyNetFilledQuantity - position.sellNetFilledQuantity;

  return netQuantity;
}

const hasStrategyMismatch = (strategyInstanceName, positions) => {
  return positions.some((p) => p.strategyInstanceName !== strategyInstanceName);
};

function formatNumber(num: number, minFractionDigit: number = 2, maxFractionDigit: number =2): string {
  if (isNaN(num)) {
    return "NaN";
  }

  // Format the number
  return num.toLocaleString("en-US", {
    minimumFractionDigits: minFractionDigit,
    maximumFractionDigits: maxFractionDigit,
  });
}

const parseOption = (symbol) => {
  const regex = /\b(CE|PE)\s(\d+)/;
  const matches = symbol.match(regex);

  if (matches && matches.length >= 3) {
    // Extracted values from the regex match
    const optionType = matches[1]; // CE or PE
    const strikePrice = Number(matches[2]); // Strike Price

    return { optionType, strikePrice };
  }

  return {};
};

const filterNumericInput = (inputValue: string): string => {
  const regex = /^[0-9]*\.?[0-9]*$/;

  if (regex.test(inputValue) || inputValue === "") {
    return inputValue;
  }

  return inputValue.slice(0, -1);
};

function getFormattedDate(dateString: string) {
  const date = dayjs(dateString);
  if (!date.isValid()) {
    return "NA";
  }

  return date.format("YYYY-MM-DD, HH:mm:ss a");
}

function formatCurrency(num: number, minFractionDigit: number = 2, maxFractionDigit: number =2): string {
  if (isNaN(num)) {
    return "NaN";
  }
  
  const sign = num < 0 ? "-" : "";
  const absNum = Math.abs(num);

  // Crores
  if (absNum >= 10000000) {
    return sign + (absNum / 10000000).toFixed(absNum % 10000000 === 0 ? 0 : 1) + "Cr";
  }
  // Lakhs
  else if (absNum >= 100000) {
    return sign + (absNum / 100000).toFixed(absNum % 100000 === 0 ? 0 : 1) + "L";
  }
  // Thousands
  else if (absNum >= 1000) {
    return sign + (absNum / 1000).toFixed(absNum % 1000 === 0 ? 0 : 1) + "K";
  }
  // Less than 1000
  return sign + formatNumber(absNum, minFractionDigit, maxFractionDigit).toString();
}

export {
  getNetQuantity,
  groupPositionsByStrategy,
  groupPositionsByAccount,
  getFormattedQuantity,
  hasStrategyMismatch,
  formatNumber,
  parseOption,
  filterNumericInput,
  getFormattedDate,
  formatCurrency,
};
