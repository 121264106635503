import request from "@/utils/request";

export default async function updateOrder(order): Promise<any> {
  const { data } = await request(`strategy/instance/state`, {
    method: "PUT",
    body: order,
    timeout: 3000,
  });

  if (data?.status == "error") {
    throw new Error(data?.message);
  }
  return data;
}
