import {
  bsProps,
  optionTypeAbbr,
  reqTypeParamName,
} from "@/constants/optionChain";

export function createStrategyReqBodyAndType(
  accounts,
  orders,
  multipliers,
  strategyName,
  requestType,
  userPreferenceAction
) {
  const accountData = accounts.map((account, ind) => {
    const multiplier = Number(multipliers?.[ind]?.multiplier);
    const userPriority = multipliers?.[ind]?.priority;
    const priority =
      userPriority != undefined && userPriority !== ""
        ? Number(userPriority)
        : undefined;
    const accountId = account?.accountId;

    const out = {
      multiplier,
      accountId,
      user_preference_action: userPreferenceAction || "pause",
    };

    if (priority != undefined) {
      out.priority = priority;
    }
    return out;
  });

  const orderData = orders
    .filter((o) => {
      return o.checked && o.changedPositionType != "M";
    })
    .map((order) => {
      const {
        bs,
        limitPrice,
        lots,
        mProtect,
        strike,
        type,
        hkbInstrumentId,
        ticker,
        lotSize,
        changedPositionType,
      } = order;

      const actionType = changedPositionType == "C" ? "close" : "open";
      const out = {
        action: bsProps[bs]?.action?.[actionType],
        ticker,
        hkbInstrumentId,
        strike,
        quantity: Number(lots) * Number(lotSize),
        orderType: limitPrice == 0 ? "market" : "limit",
        optionType: optionTypeAbbr[type]?.req,
        price: Number(limitPrice),
        orderSide: bsProps[bs]?.orderSide,
      };
      if (mProtect != undefined) {
        out.marketProtect = Number(mProtect);
      }

      if (requestType && requestType === 'close' ) {
        const paramName = reqTypeParamName[requestType];
        out[paramName] = true;
      }

      return out;
    });

  const retValue = {
    accountData,
    strategyInstanceName: strategyName,
    strategyName: strategyName,
    orderLevelMultiplier: 1,
    orderData,
  };

  if (requestType != undefined) {
    const paramName = reqTypeParamName[requestType];
    retValue[paramName] = true;
  }

  return retValue;
}
