import request from "@/utils/request";

export default async function fetchAccounts() {
  const { data: result } = await request(`accounts`, {
    timeout: 3000,
  });

  if (result?.status == "error") {
    throw new Error(result?.message);
  }

  return result ? result.accountsList : [];
}
