export const monthAbbrev = [
  { name: "JAN", tdn: "01" },
  { name: "FEB", tdn: "02" },
  { name: "MAR", tdn: "03" },
  { name: "APR", tdn: "04" },
  { name: "MAY", tdn: "05" },
  { name: "JUN", tdn: "06" },
  { name: "JUL", tdn: "07" },
  { name: "AUG", tdn: "08" },
  { name: "SEP", tdn: "09" },
  { name: "OCT", tdn: "10" },
  { name: "NOV", tdn: "11" },
  { name: "DEC", tdn: "12" },
];

export const paddedDates = (() => {
  const dates = [];
  for (let i = 1; i < 10; i++) {
    dates[i - 1] = `0${i}`;
  }

  for (let i = 10; i < 32; i++) {
    dates[i - 1] = i;
  }
  return dates;
})();

export const bsProps = {
  B: {
    cn: "buy",
    alternative: "S",
    action: { open: "buy_to_open", close: "buy_to_close" },
    orderSide: "buy",
    defaultLPMultiplier: 1.01,
  },
  S: {
    cn: "sell",
    alternative: "B",
    action: "sell_to_open",
    action: { open: "sell_to_open", close: "sell_to_close" },
    orderSide: "sell",
    defaultLPMultiplier: 0.99,
  },
};

export const optionTypeAbbr = {
  c: { name: "CE", alternative: "p", req: "call" },
  C: { name: "CE", alternative: "p", req: "call" },
  p: { name: "PE", alternative: "c", req: "put" },
  P: { name: "PE", alternative: "c", req: "put" },
};

export const reqTypeMethodMap = {
  create: "POST",
  modify: "PUT",
  close: "PUT",
};

export const reqTypeMsgMap = {
  create: "Creation",
  modify: "Update",
  close: "Closure",
};

export const reqTypeParamName = {
  create: "isCreate",
  modify: "isModify",
  close: "isClose",
};

export const dateMarRight = 16; // Keep it here for we dont render the date elements until we know how much space is to be allocated to it. But without rendering it, we cant get styles for it from DOM API. So, we use this to know margin Right of each date in the dateNavBar. We can also try to render an invisible dummy date later to get marginRight from its computedStyles.

export const stockNameMap = {
  288009: "MIDCPNIFTY",
  256265: "NIFTY",
  274441: "BANKEX",
  260105: "BANKNIFTY",
  257801: "FINNIFTY",
  256265: "NIFTY",
  265: "SENSEX",
};

export const numericFieldsRegExp = /^([0-9]*\.?[0-9]*)$/;

export const closeIcon = {
  C: { title: "Switch to Modify", alternative: "M" },
  M: { title: "Switch to Close", alternative: "C" },
};

export const emptyArray = Object.freeze([]);

export const wsMsgMaxFrequency = process.env.WEBSOCKET_MSG_MAX_LATENCY ?? 30; // In seconds

export const wsMsgConnectionInitialDelay = 3; // In seconds

export const maxNumOfDatesTobeShown = 6;
