import React, { useMemo } from "react";
import PositionTable from "../PositionTable";
import PositionOrderTable from "./PositionOrderTable";
import OrderDetails from "../OrderDetails";
import { Flex, Space, Tooltip } from "antd";
import StatusImage from "../StatusImage";
import { formatCurrency, formatNumber } from "@/utils";
import { ExclamationOutlined } from "@ant-design/icons";
import FinancialOverview from "./FinancialOverview";

const PositionDetails = ({ account, activeTab }) => {

  const financialData = {
    totalPL: formatCurrency(account?.strategy.totalPnlAbsValue),
    totalPlPercentage: account?.strategy.marginUsed > 0
    ? `${
        formatNumber((account?.strategy.totalPnlAbsValue /
          account?.strategy.marginUsed) *
        100)
      }%`
    : "-",
    totalRealizedPL:formatCurrency(account?.strategy.totalRealisedPnlAbsValue),
    totalUnrealizedPL:formatNumber(account?.strategy.totalUnRealisedPnlAbsValue),

    todayPL:formatCurrency(account?.strategy.todayPnlAbsValue),
    todayRealisedPL:formatCurrency(account?.strategy.todayRealisedPnlAbsValue),
    todayUnrealizedPL:formatNumber(account?.strategy.todayUnRealisedPnlAbsValue),

    marginUsed:account?.strategy.marginUsed > 0 ? formatCurrency(account?.strategy.marginUsed) : "-",
    marginAvailable:formatCurrency(account?.marginAvailable),

  }
  return (
    <div className={`card`}>
      {activeTab === "position" ? (
        <>
          <Flex align="center" justify="space-between">
            <div className="accountInfoCompact">
              <div className="accountInforMarginRow">
              <h4 className="card-title">
                {account.accountName} -{" "}
                {account?.strategy?.accountLevelMultipler}
              </h4>
              {!account.isLoggedIn && (
                <Tooltip placement="topLeft" title="Not logged in, won't be able to manage account">
                  <ExclamationOutlined
                    style={{ color: "red", fontSize: "18px" }}
                  />
                </Tooltip>
              )}
              </div>
            <div>
              <span className="grey-out"> <Tooltip placement="topLeft" title="Margin Used">{financialData.marginUsed}|</Tooltip> </span>
                <span> <Tooltip placement="topLeft" title="Margin Available">{financialData.marginAvailable}</Tooltip> </span>
              </div>
            </div>


            <FinancialOverview
              data={financialData}
            />
            {/* <Space>
              <Tooltip placement="topLeft" title="Total PL">
                {formatNumber(account?.strategy.totalPnlAbsValue, 0, 0)}
              </Tooltip>
              <Tooltip placement="topLeft" title="PL %">
                <span className="grey-out">
                  , {account?.strategy.marginUsed > 0
                    ? `${
                        formatNumber((account?.strategy.totalPnlAbsValue /
                          account?.strategy.marginUsed) *
                        100)
                      }%`
                    : "-"}
                </span>
              </Tooltip>
              |
              <Tooltip placement="topLeft" title="Realised PL">
              <span className="grey-out">
                {formatCurrency(account?.strategy.totalRealisedPnlAbsValue, 0, 0)},</span></Tooltip>
                <span className="grey-out"></span>
              <Tooltip placement="topLeft" title="Unrealised PL">
               {formatNumber(account?.strategy.totalUnRealisedPnlAbsValue, 0, 0)}

              </Tooltip>
              |
              <Tooltip placement="topLeft" title="Margin Used">
                <span className="grey-out">
                {formatCurrency(account?.strategy.marginUsed, 0, 0)}
                </span>
              </Tooltip>
            </Space> */}
            <StatusImage
              status={account?.strategy.strategyStatus}
              errorStatus={account?.strategy.errorStatus}
            />
          </Flex>
          <PositionTable account={account} />
        </>
      ) : (
        <OrderDetails
          title={account?.accountName}
          positions={account?.positions}
          accountId={account?.accountId}
          strategyInstanceName={account?.strategyInstanceName}
          accountLevelMultipler={account?.strategy?.accountLevelMultipler}
          isReadOnly={account?.strategy?.isReadOnly}
        />
      )}
    </div>
  );
};

export default PositionDetails;
