import { useWebsocket } from "@/hooks/useWebsocket";
import {
  actionButtonClickCountState,
  filterQueryState,
  instrumentIdToNameMapState,
  lastClickedButtonActionState,
  priceLiveStatus,
  selectedInstrumentState,
} from "@/state/positionstate";
import { formatNumber } from "@/utils";
import { wsManager } from "@/utils/websocket";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Flex, Space, notification } from "antd";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import LiveStatusIcon from "../LiveStatusIcon";
import useNotification from "@/hooks/useNotification";
import SearchBar from "../SearchBar";

interface TickerPrice {
  name: string;
  symbol: string;
  ltp: number;
  changePriceAbsolute: number;
  changePricePercentage: number;
  hasOptionChain: boolean;
}

const PriceListContainer = () => {
  const [authState, setAuthState] = useState(null);
  const setInstrumentState = useSetRecoilState(selectedInstrumentState);
  const setLastClickedButtonAction = useSetRecoilState(
    lastClickedButtonActionState
  );
  const setButtonClickCount = useSetRecoilState(actionButtonClickCountState);
  const setInstrumentIdToNameMap = useSetRecoilState(
    instrumentIdToNameMapState
  );


  const [isLive, setPriceLiveStatus] = useRecoilState(priceLiveStatus);
  const { showNotification } = useNotification();

  const [data, setData] = useState({
    tickerPriceList: [
      // {
      //   name: "NIFTY",
      //   hkbInstrumentId: 256265,
      //   symbol: "NIFTY",
      //   ltp: 0,
      //   changePriceAbsolute: 0,
      //   changePricePercentage: 0,
      //   hasOptionChain: true,
      // },
      // {
      //   name: "BANKNIFTY",
      //   hkbInstrumentId: 260105,
      //   symbol: "BANKNIFTY",
      //   ltp: 0,
      //   changePriceAbsolute: 0,
      //   changePricePercentage: 0,
      //   hasOptionChain: true,
      // },
    ],
    lastUpdateTime: null,
  });

  const [tickerPriceList, setTicketPriceList] = useState([]);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [filteredTickerPriceList, setFilteredTickerPriceList] = useState([]);
  const [filterQuery, setFilterQuery] = useRecoilState(filterQueryState);
  const [isDataInitialized, setIsDataInitialized] = useState(false);

  const handleWsMessage = (data) => {
    console.log('tickerprice-len', tickerPriceList.length)
    setData(data);
    setTicketPriceList(data.tickerPriceList);
    setLastUpdateTime(data.lastUpdateTime);

    if (!isDataInitialized && data && data.tickerPriceList) {
      const tickerMap = new Map(
        data.tickerPriceList.map((ticker) => [
          ticker.hkbInstrumentId,
          ticker.name,
        ])
      );

      setInstrumentIdToNameMap(tickerMap);
      setIsDataInitialized(true);
    }
  };

  const handleError = () => {
    showNotification("error", `Experiencing Issues with Live Price Updates`);
  };

  useWebsocket("ws/tickers", handleWsMessage, handleError, setPriceLiveStatus);

  useEffect(() => {
    const storedAuthState = localStorage.getItem("authState");
    if (storedAuthState) {
      setAuthState(JSON.parse(storedAuthState));
    }
    // const handleWsMessage = (event: MessageEvent) => {
    //   const data = JSON.parse(event.data);
    //   setData(data);
    //   if (!isDataInitialized && data && data.tickerPriceList) {
    //     const tickerMap = new Map(
    //       data.tickerPriceList.map((ticker) => [
    //         ticker.hkbInstrumentId,
    //         ticker.name,
    //       ])
    //     );
    //     setInstrumentIdToNameMap(tickerMap);
    //     setIsDataInitialized(true);
    //   }
    // };

    // wsManager.connect({
    //   endpoint: "ws/tickers",
    //   onMessage: handleWsMessage,
    // });

    // return () => {
    //   wsManager.disconnect("ws/tickers");
    // };
  }, []);

  useEffect(() => {
    handleSearch(filterQuery)
  }, [tickerPriceList]);

  const handleNameClick = (hkbInstrumentId) => {
    setInstrumentState(hkbInstrumentId);
    setLastClickedButtonAction(null);
    setButtonClickCount(0);
  };

  const truncate =  (str) => {
    return str.length > 22 ? str.substring(0, 19) + "..." : str;
  }

  const handleSearch = (searchTerm) => {
    if (searchTerm) {
      const filtered = tickerPriceList.filter((item) => {
        console.log("search filter item", item);
        return item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.symbol.toLowerCase().includes(searchTerm.toLowerCase());
      }
      );
      setFilteredTickerPriceList(filtered);
    } else {
      setFilteredTickerPriceList(tickerPriceList);
    }
    setFilterQuery(searchTerm)
  }

  return (
    <div>
      <Flex justify="space-between" style={{ padding: "4px 8px" }}>
        <div className="last-update-time">
          {authState
            ? authState.userData.email
            : "User is not currently logged in"}
        </div>
        <Space>
          <div className="last-update-time">
            Last Updated: {lastUpdateTime && new Date(lastUpdateTime).toLocaleTimeString()}
          </div>
          <LiveStatusIcon isLive={isLive} />
        </Space>
      </Flex>
      <div>
        <SearchBar data={tickerPriceList} onSearch={handleSearch} />
        {/* Display filteredData */}
      </div>
      <div className="div-table-price-pane">
        {filteredTickerPriceList &&
            filteredTickerPriceList.map((ticker) => (
            <div
              className={`div-table-row height30 justify-start ${
                ticker.name === "VIX" ? " disabled-row" : ""
              }`}
              key={ticker.hkbInstrumentId}
            >
              <div
                className={`div-table-cell price-cell-nowrap ${
                  ticker.changePriceAbsolute < 0 ? "negative" : "positive"
                }`}
                role="button"
                onClick={
                  ticker.hasOptionChain
                    ? () => handleNameClick(ticker.hkbInstrumentId)
                    : undefined
                }
              >
                <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                  {truncate(ticker.name)}
                </span>

              </div>
              <div className="div-table-cell text-right">
                {formatNumber(ticker.changePriceAbsolute)}
              </div>
              <div className="div-table-cell text-right">
                {formatNumber(ticker.changePricePercentage)}
              </div>
              <div
                className={`div-table-cell ltp-cell ${
                  ticker.changePriceAbsolute < 0 ? "negative" : "positive"
                }`}
              >
                <Flex justify="space-between">
                  {ticker.changePriceAbsolute < 0 ? (
                    <DownOutlined />
                  ) : (
                    <UpOutlined />
                  )}
                  {formatNumber(ticker.ltp)}
                </Flex>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PriceListContainer;
