import { filterNumericInput, formatNumber } from "@/utils";
import { Input } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";

const OrderPrice = ({ order, accountId, onUpdate }) => {
  const [price, setPrice] = useState(order.price);
  const [isEditing, setEditing] = useState(false);
  const [isFirstKey, setIsFirstKey] = useState(false);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (!isEditing) {
      setPrice(order.price);
    }
  }, [order, isEditing]);

  const handleInputChange = useCallback((e) => {
    const { value: inputValue } = e.target;
    const filteredInput = filterNumericInput(inputValue);
    setPrice(filteredInput);
  }, []);

  const handleKeyDown = useCallback(async (e) => {
    if (e.key === "Escape") {
      setEditing(false);
      setIsFirstKey(false);
      return;
    }
    
    switch (e.key) {
      case "Tab":
      case "Enter":
        const newPrice = parseFloat(price);
        const payload = {
          newPrice,
          hkbOrderInfo: [
            {
              hkbOrderId: order.hkbOrderId,
              accountId: accountId,
            },
          ],
        };
        await onUpdate(payload);
        setEditing(false);
        setIsFirstKey(false);
        break;
      default:
        break;
    }
    
    if (isFirstKey) {
      e.preventDefault();
      const newValue = e.key.match(/\d/) ? e.key : ''; // Assuming numeric input for simplicity
      setPrice(newValue);
      setIsFirstKey(false);
      return;
    }

  }, [price, isFirstKey, accountId, onUpdate]);

  const handleFocus = useCallback(() => {
    setIsFirstKey(true);
  }, []);

  const toggleEdit = useCallback(() => {
    setEditing(true);
    setIsFirstKey(true);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.input.contains(event.target as Node)) {
        setEditing(false);
        setIsFirstKey(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  return isEditing ? (
    <Input
      ref={inputRef}
      value={price}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      style={{ width: "80px" }}
    />
  ) : (
    <span onClick={toggleEdit}>{formatNumber(price)} </span>
  );
};

export default OrderPrice;
