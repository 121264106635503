import request from "@/utils/request";

export default async function updateOrderPrice(payload): Promise<any> {
  const { data } = await request(`strategy/instance/price`, {
    method: "PUT",
    body: payload,
    timeout: 3000,
  });

  if (data?.status == "error") {
    throw new Error(data?.message);
  }
  return data;
}
