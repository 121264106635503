
import { groupPositionsByStrategy } from '@/utils';
import StrategiesTable from './StrategiesTable';
import { useEffect, useState } from 'react';

const StrategyContainer = ({positions}) => {

  const [transformedData, setTransformedData] = useState({});

  useEffect(() => {
    const groupedData = groupPositionsByStrategy(positions);
    setTransformedData(groupedData);
  }, [positions]);
  
  return (
    <StrategiesTable data={transformedData} />
  );
};

export default StrategyContainer;
