import { notificationState } from '@/state/positionstate';
import { message, Button } from 'antd';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

const Notification = () => {
  const notification = useRecoilValue(notificationState);
  const [api, contextHolder] = message.useMessage();

  useEffect(() => {
    if (!notification.text) return;

    // Customize message configuration here if needed
    const config = {
      content: notification.text,
      duration: 2,
      maxCount:1
    };

    switch (notification.type) {
      case 'success':
        api.success(config);
        break;
      case 'error':
        api.error(config);
        break;
      case 'warning':
        api.warning(config);
        break;
      case 'info':
        api.info(config);
        break;
      default:
        console.warn('Unsupported notification type');
    }
  }, [notification, api]);

  // Render the context holder at the top of your component tree
  return contextHolder;
};

export default Notification;
