import React from 'react';
import './FinancialOverview.css'; // Assuming the new CSS file for compact design
import { Tooltip } from 'antd';

const FinancialOverviewCompact: React.FC<{ data }> = ({ data }) => {
  return (
    <div className="financialOverviewCompact">
      <div className="gridLine">
        <span> <Tooltip placement="topLeft" title="Total PL">{data.totalPL} </Tooltip>
          <span className="grey-out"> <Tooltip placement="topLeft" title="PL%">/{data.totalPlPercentage} </Tooltip></span> |
            </span>
        <span> <Tooltip placement="topLeft" title="Unrealised PL">{data.totalUnrealizedPL}</Tooltip> |</span>
          <span className="grey-out"> <Tooltip placement="topLeft" title="Realised PL">{data.totalRealizedPL}</Tooltip> </span>
      </div>
      <div className="gridLine grey-out">
         <span> <Tooltip placement="topLeft" title="Today's PL">{data.todayPL} </Tooltip>
          <span className="grey-out"> <Tooltip placement="topLeft" title="Today's PL%">/{data.totalPlPercentage} </Tooltip></span> |
            </span>
          <span> <Tooltip placement="topLeft" title="Today's Unrealized PL">{data.todayUnrealizedPL}</Tooltip> |</span>
          <span className="grey-out"> <Tooltip placement="topLeft" title="Today's Realized PL">{data.todayRealisedPL}</Tooltip> </span>
      </div>
    </div>
  );
};

export default FinancialOverviewCompact;
