import { FC, memo } from "react";
import PositionGrid from "./PositionGrid";
import { useRecoilValue } from "recoil";
import { positionViewType } from "@/state/layoutState";
import StrategyGrid from "./StrategyGrid";

type TabContentProps = {
  activeTab: string;
  data: any;
  accountId: any;
  isColumnExpanded: boolean;
};

const TabContent: FC<TabContentProps> = ({
  accountId,
  activeTab,
  data,
  isColumnExpanded,
}) => {
  const viewType = useRecoilValue(positionViewType);

  if (viewType === "strategy") {
    return (
      <div className={`tab-content ${isColumnExpanded ? "fixed-height" : ""}`}>
        <PositionGrid
          key={activeTab + accountId}
          data={data.accounts}
          isColumnExpanded={isColumnExpanded}
          activeTab={activeTab}
        />
      </div>
    );
  }

  return (
    <div className={`tab-content ${isColumnExpanded ? "fixed-height" : ""}`}>
      <StrategyGrid
        key={accountId}
        accountId={accountId}
        data={data.strategyInstances}
        isColumnExpanded={isColumnExpanded}
        activeTab={activeTab}
      />
    </div>
  );
};
export default TabContent;

const Orders = memo(() => {
  return <div>Orders Details...</div>;
});
