import fetchBrokerOrders from "@/api/fetchBrokerOrders";
import { cmcStrategy } from "@/api/optionChain";
import updateOrder from "@/api/updateOrder";
import updateOrderPrice from "@/api/updateOrderPrice";
import useDebug from "@/hooks/useDebug";
import useNotification from "@/hooks/useNotification";
import {
  accountByIdState,
  portfolioSelector,
  selectedOrdersState
} from "@/state/positionstate";
import {
  formatNumber,
  parseOption
} from "@/utils";
import { RedoOutlined } from "@ant-design/icons";
import playImage from "@assets/images/User-status-force-run-action.png";
import pauseImage from "@assets/images/User-status-pause-action.png";
import cancelImage from "@assets/images/user-force-cancel-action.png";
import { Button, Checkbox, Flex, Popover, Space, Spin, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import StatusImage from "../StatusImage";
import BrokerOrders from "./BrokerOrders";
import OrderPrice from "./OrderPrice";

const OrderDetails = ({
  title,
  positions,
  accountId,
  strategyInstanceName,
  accountLevelMultipler,
  isReadOnly,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const account = useRecoilValue(accountByIdState(accountId));
  const getPortfolio = useRecoilValue(portfolioSelector);

  const [setSelectedOrdersArray, setSelectedOrdersState] =
    useRecoilState(selectedOrdersState);
  const [selectedOrders, setSelectedOrders] = useState(new Set());

  const [popoverVisible, setPopoverVisible] = useState({});
  const [brokerOrders, setBrokerOrders] = useState([]);
  const [loading, setLoading] = useState({});

  const { showNotification } = useNotification();
  const { appendDebugInfo } = useDebug();

  // useEffect(() => {
  //   appendDebugInfo({
  //     component: "OrderDetails",
  //   });
  // }, []);

  const updatePrice = useCallback(async (payload) => {
    setIsLoading(true);
    try {
      const result = await updateOrderPrice(payload);

      showNotification("success", result.message);
    } catch (error) {
      showNotification("error", error?.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchSymbolData = useCallback(
    async (accountId, hkbInstrumentId) => {
      if (!loading[hkbInstrumentId]) {
        setLoading((prev) => ({ ...prev, [hkbInstrumentId]: true }));
        try {
          const brokerOrders = await fetchBrokerOrders(
            accountId,
            hkbInstrumentId
          );
          setBrokerOrders((prev) => ({
            ...prev,
            [hkbInstrumentId]: brokerOrders,
          }));
        } catch (error) {
          // notification.error({
          //   message: "Error",
          //   description: "Failed to fetch data.",
          // });
          showNotification("error", error?.message);
        } finally {
          setLoading((prev) => ({ ...prev, [hkbInstrumentId]: false }));
        }
      }
    },
    [loading]
  );

  const handlePopoverVisibleChange = useCallback(
    (visible, hkbInstrumentId) => {
      setPopoverVisible((prev) => ({ ...prev, [hkbInstrumentId]: visible }));
      if (visible) {
        fetchSymbolData(accountId, hkbInstrumentId);
      }
    },
    [accountId]
  );

  useEffect(() => {
    // Load initial selections if any
    const initialSelections = setSelectedOrdersArray
      .filter(
        (p) =>
          p.accountId === accountId &&
          p.strategyInstanceName === strategyInstanceName
      )
      .map(
        (p) =>
          `${accountId}-${p.tradingSymbol}-${p.strategyInstanceName}-${p.hkbOrderId}`
      );
    setSelectedOrders(new Set(initialSelections));
  }, [accountId, strategyInstanceName, selectedOrdersState]);

  const isSelected = (position, order) => {
    return selectedOrders.has(
      `${accountId}-${position.tradingSymbol}-${position.strategyInstanceName}-${order.hkbOrderId}`
    );
  };

  const isSelectedAll = (position) => {
    if (!position || !Array.isArray(position.todayHkbOrders)) {
      return false;
    }

    // Filter out canceled orders
    const selectableOrders = position.todayHkbOrders.filter(
      (order) => order.orderStatus !== "Canceled"
    );

    // Check if there are no selectable orders
    if (selectableOrders.length === 0) {
      return true;
    }

    // Check if all selectable orders are selected
    return selectableOrders.every(
      (order) =>
        order &&
        order.hkbOrderId &&
        selectedOrders.has(
          `${accountId}-${position.tradingSymbol}-${position.strategyInstanceName}-${order.hkbOrderId}`
        )
    );
  };

  const handleCheckboxChange = (position, order, isChecked) => {
    const positionKey = `${accountId}-${position.tradingSymbol}-${position.strategyInstanceName}-${order.hkbOrderId}`;
    const newSelectedPositions = new Set(selectedOrders);
    let updatedOrders = [...setSelectedOrdersArray];

    if (order.orderStatus === "Canceled") {
      return;
    }

    if (isChecked) {
      // Check if the selected position's strategy matches with existing selections
      // if (selectedOrders.size > 0 && !isSelected(position)) {
      // if (
      //   isChecked &&
      //   hasStrategyMismatch(position.strategyInstanceName, updatedOrders)
      // ) {
      //   notification.error({
      //     message: "Strategy Mismatch",
      //     description: "You can only select orders with the same strategy.",
      //   });
      //   return;
      // }

      newSelectedPositions.add(positionKey);
      updatedOrders.push({
        accountId: accountId,
        ...order,

        // tradingSymbol: position.tradingSymbol,
        // strategyInstanceName: position.strategyInstanceName,
        // strategyName: position.strategyName,
        // hkbInstrumentId: position.hkbInstrumentId,
        // parentHkbInstrumentId: position.parentHkbInstrumentId,
        // ltp: position.ltp,
      });
    } else {
      newSelectedPositions.delete(positionKey);
      updatedOrders = updatedOrders.filter(
        (p) =>
          p.tradingSymbol !== position.tradingSymbol ||
          p.strategyInstanceName !== position.strategyInstanceName ||
          p.accountId !== accountId
      );
    }

    setSelectedOrders(newSelectedPositions);
    setSelectedOrdersState(updatedOrders);
  };

  const handleSelectAll = (isChecked) => {
    let updatedOrders = [...setSelectedOrdersArray];
    let newSelectedPositions = new Set(selectedOrders);

    if (isChecked) {
      // if (
      //   positions.some((position) =>
      //     hasStrategyMismatch(position.strategyInstanceName, updatedOrders)
      //   )
      // ) {
      //   notification.error({
      //     message: "Strategy Mismatch",
      //     description: "You can only select orders with the same strategy.",
      //   });
      //   return;
      // }
      positions.forEach((position) => {
        position.todayHkbOrders &&
          position.todayHkbOrders.forEach((order) => {
            if (order.orderStatus !== "Canceled") {
              const positionKey = `${accountId}-${position.tradingSymbol}-${position.strategyInstanceName}-${order.hkbOrderId}`;
              if (!newSelectedPositions.has(positionKey)) {
                newSelectedPositions.add(positionKey);

                updatedOrders.push({
                  accountId: accountId,
                  ...order,
                });
              }
            }
          });
      });
    } else {
      newSelectedPositions = new Set();
      updatedOrders = updatedOrders.filter(
        (p) => p.strategyInstanceName !== strategyInstanceName
      );
    }

    setSelectedOrders(newSelectedPositions);
    setSelectedOrdersState(updatedOrders);
  };

  const resetOrderState = () => {
    setSelectedOrders(new Set());
    setSelectedOrdersState([]);
  };

  const updateOrderState = async (newState) => {
    setIsLoading(true);
    const hkbOrderIds = Array.from(selectedOrders).map((orderKey: string) =>
      orderKey.split("-").pop()
    );

    const payload = {
      strategyInstanceName: strategyInstanceName,
      newState: newState,
      hkbOrderIds: hkbOrderIds,
    };

    try {
      const result = await updateOrder(payload);
      resetOrderState();
      showNotification("success", result.message);
    } catch (error) {
      showNotification("error", error?.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const retryOrder = async () => {
    setIsLoading(true);
    const requestType = "modify";

    try {
      const portfolio = getPortfolio(accountId);
      const strategyInstances = portfolio?.strategyInstances || [];
      const strategyInstance = strategyInstances.find(
        (strategy) => strategy.strategyInstanceName === strategyInstanceName
      );
      const multiplier =
        strategyInstance?.accountLevelMultipler ??
        account?.defaultMultiplier ??
        1;

      const strategyName = strategyInstance?.strategyName || strategyInstanceName

      const accountData = [
        {
          multiplier,
          accountId: account.accountId,
          user_preference_action: "pause",
          priority: account.priority || 0,
        },
      ];

      const orderData = setSelectedOrdersArray
        .filter((order) => {
          return (
            order.accountId === accountId &&
            // order.orderStatus === "completed-with-error" &&
            order.strategyInstanceName === strategyInstanceName
          );
        })
        .map((order) => {
          const option = parseOption(order.tradingSymbol);

          return {
            action: `${order.orderSide}_to_open`,
            // ticker: order.tradingSymbol,
            hkbInstrumentId: order.hkbInstrumentId,
            strike: option.strikePrice,
            quantity: order.quantity / multiplier,
            orderType: order.orderType,
            optionType: option.optionType === "CE" ? "call" : "put",
            price: order.triggerPrice,
            orderSide: order.orderSide,
            marketProtect: order.marketProtection,
          };
        });

      if (!orderData || orderData.length === 0) {
        showNotification("warning", "No orders found.");
        return;
      }

      const reqBody = {
        accountData,
        strategyInstanceName: strategyInstanceName,
        strategyName: strategyName,
        orderLevelMultiplier: 1,
        orderData,
        isModify: true,
      };

      await cmcStrategy(reqBody, requestType);
      showNotification("success", `Order successfully updated.`);

      resetOrderState();
    } catch (error) {
      showNotification("error", error?.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Flex gap="small" wrap="wrap" justify="space-between">
        <h4 className="card-title">
          {title} - {accountLevelMultipler}
        </h4>
        <Space>
          <Tooltip placement="topLeft" title="Duplicate Order">
          <Button
            onClick={retryOrder}
            type="link"
            style={{ border: "none", paddingLeft: 8, boxShadow: "none" }}
            icon={<RedoOutlined />}
            disabled={isReadOnly}
          />
          </Tooltip>

          <Flex gap="small" wrap="wrap">
            <Tooltip placement="topLeft" title="Execute Order">
            <img
              src={playImage}
              alt="Play"
              // onClick={() => updateOrderState("run")}
              onClick={!isReadOnly ? () => updateOrderState("run") : undefined} // Only assign onClick handler if isReadOnly is false
            />
            </Tooltip>
            <Tooltip placement="topLeft" title="Pause Order">
            <img
              src={pauseImage}
              alt="Pause"
              onClick={!isReadOnly ? () => updateOrderState("Pause") : undefined}
            />
            </Tooltip>
            <Tooltip placement="topLeft" title="Cancel Order">
            <img
              src={cancelImage}
              alt="Cancel"
              onClick={!isReadOnly ? () => updateOrderState("Cancel") : undefined}
            />
              </Tooltip>
          </Flex>
        </Space>
      </Flex>
      <table className="portfolio-table">
        <thead>
          <tr>
            <th>
              <Checkbox
                disabled={isReadOnly} 
                checked={
                  positions &&
                  positions.length > 0 &&
                  positions.every(isSelectedAll)
                }
                onChange={(e) => handleSelectAll(e.target.checked)}
              />
            </th>
            <th>Symbol</th>
            <th className="text-center"><Tooltip placement="topLeft" title="Buy or Sell">S </Tooltip></th>
            <th> <Tooltip placement="topLeft" title="Quantity"> QTY</Tooltip></th>
            <th> <Tooltip placement="topLeft" title="Avg Trade Price"> AP </Tooltip></th>
            <th> <Tooltip placement="topLeft" title="Limit or Market">Type</Tooltip></th>
            <th> <Tooltip placement="topLeft" title="Limit Price"> LP </Tooltip></th>
            <th>LTP</th>
            <th> <Tooltip placement="topLeft" title="Market Protect">MP</Tooltip></th>
          </tr>
        </thead>
        <tbody>
          {positions &&
            positions.map(
              (position) =>
                position.todayHkbOrders &&
                position.todayHkbOrders.map((order) => (
                  <tr key={order.hkbOrderId}>
                    <td>
                      <Checkbox
                         disabled={isReadOnly}
                        checked={isSelected(position, order)}
                        onChange={(e) =>
                          handleCheckboxChange(
                            position,
                            order,
                            e.target.checked
                          )
                        }
                        disabled={order.orderStatus === "Canceled"}
                      />
                    </td>
                    <td>
                      <Space>
                        <Popover
                          overlayClassName="popover"
                          placement="bottomLeft"
                          content={
                            loading[position.hkbInstrumentId] ? (
                              <p>Loading...</p>
                            ) : (
                              <BrokerOrders
                                hkbOrderId={order.hkbOrderId}
                                orders={brokerOrders[order.hkbOrderId]}
                              />
                            )
                          }
                          // title="Details"
                          trigger="click"
                          open={popoverVisible[order.hkbOrderId]}
                          onOpenChange={(visible) => {
                            handlePopoverVisibleChange(
                              visible,
                              order.hkbOrderId
                            );
                            if (visible) {
                              appendDebugInfo({
                                hkbOrderId: order.hkbOrderId,
                                brokerOrders: brokerOrders[order.hkbOrderId],
                              });
                            }
                          }}
                        >
                          <span style={{ cursor: "pointer" }}>
                            {position.tradingSymbol}
                          </span>
                        </Popover>

                        <StatusImage
                          status={order.orderStatus}
                          errorStatus={order.errorStatus}
                        />
                      </Space>
                    </td>
                    <td className="text-center">
                      {order.orderSide && order.orderSide[0]}
                    </td>
                    <td className="text-right">
                      {order.filledQuantity}/{order.quantity}
                    </td>
                    <td className="text-right">
                      {formatNumber(order.averageExecutionPrice)}
                    </td>
                    <td>{order.orderType && order.orderType[0]}</td>
                    <td className="text-right">
                      <OrderPrice
                        order={order}
                        onUpdate={updatePrice}
                        accountId={accountId}
                      />
                    </td>
                    <td className="text-right">{formatNumber(position.ltp)}</td>
                    <td className="text-right">
                      {formatNumber(order.marketProtection)}
                    </td>
                  </tr>
                ))
            )}
        </tbody>
      </table>
    </Spin>
  );
};

export default OrderDetails;
