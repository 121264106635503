import { UserState } from "@/state/authState";

export const saveAuthState = (authState: UserState): void => {
    localStorage.setItem('authState', JSON.stringify(authState));
};

export const getAuthState = (): UserState | null => {
    const authState = localStorage.getItem('authState');
    return authState ? JSON.parse(authState) : null;
};

export const saveAccessToken = (token: string): void => {
    localStorage.setItem('accessToken', token);
};

export const getAccessToken = (): string | null => {
    return localStorage.getItem('accessToken');
};