import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { Spin, notification } from "antd";

import "./styles.css";
import { useStockName } from "@/hooks/useStockName";
import { actionButtonClickCountState, lastClickedButtonActionState } from "@/state/positionstate";
import { fetchOptionChain } from "@/api/optionChain";
import { ApiResponseContext } from "./contexts";

import { OptionChainAndOrderPane } from "./optionChainAndOrderPane";

import { stockNameMap } from "@/constants/optionChain";

import { LoaderPopup } from "./descendants";
import useNotification from "@/hooks/useNotification";
import Notification from "../Notification";
// To be done: Separating of hooks from the component and moving constants to another file, resizing of window, different screen sizes, margin and padding adjustment, replacing Nav divs with <button>, cleanup of classnames and styles, reduce duplication of table code by moving headers into arrays

// {
//   "accountId": "zerodha_Zuz3434",
//   "clientId": "Zuz3434",
//   "brokerName": "zerodha",
//   "brokerProtocol": "kite",
//   "marginUsed": 70000,
//   "maginAvailable": 7000,
//   "defaultMultiplier": 3,
//   "priority": 1,
//   "enabled": false
// }

const OptionChainWrapper = (props) => {
  // console.log("data sent to optionchain pane", props);
  const { hkbInstrumentId, positions, allPositions, strategyInstanceName, strategyName } = props;
  const { showNotification } = useNotification()

  const stockName =
    useStockName(hkbInstrumentId) || stockNameMap[hkbInstrumentId] || "Stock";

  const prevInstumentIdRef = useRef(null);

  const [childProps, setChildProps] = useState({
    data: null,
    instrumentId: null,
  });

  // const [childProps2, setChildProps2] = useState({
  //   lastButtonAction: null,
  //   curPositions: [],
  //   strategyName: "",
  // });

  const lastClickedButtonAction = useRecoilValue(lastClickedButtonActionState);
  const actionButtonClickCount = useRecoilValue(actionButtonClickCountState);
  
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const instrumentId = hkbInstrumentId;

    async function fetchData() {
      setIsLoading(true); 
      try {
        if (
          (typeof instrumentId === "string" && instrumentId.length > 0) ||
          (typeof instrumentId === "number" && instrumentId > 0)
        ) {
          console.log(`about to fetch data for ${instrumentId}`);
          const result = await fetchOptionChain(
            instrumentId,
            abortController.signal
          );
          if (result?.status == "error") {
            throw new Error(
              result?.message || `Error while fetching data for ${stockName}`
            );
          }

          setChildProps({
            data: result,
            instrumentId,
          });

          // setChildProps2({
          //   curPositions: positions,
          //   lastButtonAction: lastClickedButtonAction,
          //   strategyName: strategyInstanceName,
          // });

          prevInstumentIdRef.current = instrumentId;
        }
        setIsLoading(false);  
      } catch (e) {
        if (process.env.DEV_SERVER_USER == "developer") {
          try {
            const dataModule = await import(
              `./SampleData/${stockNameMap[instrumentId]}`
            );

            const result = dataModule.default;

            setChildProps({
              data: result,
              instrumentId,
            });

            // setChildProps2({
            //   curPositions: positions,
            //   lastButtonAction: lastClickedButtonAction,
            //   strategyName: strategyInstanceName,
            // });
            prevInstumentIdRef.current = instrumentId;

            // notification.error({
            //   message: "Using Dummy Data for development machine",
            //   description: "Due to unavailbility of data from Server",
            // });
            showNotification("error","Due to unavailbility of data from Server")

          } catch (e2) {
            // notification.error({
            //   message: "Error",
            //   description: e.message,
            // });
            showNotification("error",e.message)
          }
        } else {
          // notification.error({
          //   message: "Error",
          //   description: e.message,
          // });

          showNotification("error",e.message)
        
        }
        setIsLoading(false);  
      }
    }

    fetchData();

    return () => {
      // Cancel the request when instrumentId changes
      abortController.abort();
    };
  }, [hkbInstrumentId]);

  // useEffect(() => {
  //   return () => {
  //     console.log("OptionChain About to be unmounted");
  //   };
  // }, []);

  if (
    !(
      (typeof hkbInstrumentId === "string" && hkbInstrumentId.length > 0) ||
      (typeof hkbInstrumentId === "number" && hkbInstrumentId > 0)
    )
  ) {
    return <Notification />;
  }

  let { data, instrumentId } = childProps;

  // let { curPositions, lastButtonAction, strategyName } = childProps2;

  // if (
  //   prevInstumentIdRef.current == hkbInstrumentId &&
  //   (positions != curPositions || lastButtonAction != lastClickedButtonAction)
  // ) {
  //   setChildProps2({
  //     curPositions: positions,
  //     lastButtonAction: lastClickedButtonAction,
  //     strategyName: strategyInstanceName,
  //   });
  // }

  if (!data || isLoading) {
    return (
      <div className="oc-whole loading">
        {/* <LoaderPopup /> */}
        <Spin size="large" />
      </div>
    );
  }

  // if (prevInstumentIdRef.current === hkbInstrumentId) {
  //   curPositions = positions;
  //   strategyName = strategyInstanceName || "";
  //   lastButtonAction = lastClickedButtonAction;
  //   // When instrumentId changes we should pass positions to the child Component only after fetcing the data for that instrumentId.
  //   // But when the instrumentId does nt change, we should pass down the positions as they are
  // }
  return (
    <ApiResponseContext.Provider value={data}>
      <OptionChainAndOrderPane
        key={`${instrumentId}${actionButtonClickCount}`}
        hkbInstrumentId={instrumentId}
        positions={positions}
        allPositions={allPositions}
        lastClickedButtonAction={lastClickedButtonAction}
        strategyInstanceName={strategyInstanceName}
        strategyName={strategyName}
      />
    </ApiResponseContext.Provider>
  );
};
const OptionChainWrapperMemo = OptionChainWrapper;

export default OptionChainWrapperMemo;
