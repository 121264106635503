import React from 'react';
import { Card, Layout, message } from 'antd';
import GoogleSignIn from '@/components/GoogleSignIn';

const { Content } = Layout;

const LoginPage = () => {
    return (
        <Layout>
            <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Card  bordered={false} style={{ width: 300 }}>
                  <GoogleSignIn />
                </Card>
            </Content>
        </Layout>
    );
};

export default LoginPage;
