import { useEffect } from "react";

import {
  bsProps,
  dateMarRight,
  maxNumOfDatesTobeShown,
  monthAbbrev,
  paddedDates,
  wsMsgConnectionInitialDelay,
  wsMsgMaxFrequency,
} from "@/constants/optionChain";
import { wsManager } from "@/utils/websocket";
import useNotification from "@/hooks/useNotification";

export function useAddPositionOrder(
  lastClickedButtonAction,
  instrumentId,
  optionChainData,
  selectedPositions,
  setAccounts,
  setRequestType,
  setShowSelectAccountsPane,
  setShowOrderPane,
  setOrders,
  setStrategyName,
  dataByHkbId,
  prevInstumentIdRef,
  receivedStrategyName
) {
  const { showNotification } = useNotification();

  const hasValidQuantities = (positions) => {
    const allHavePositiveQuantities = positions.every(
      (position) => position.quantity > 0
    );

    const allZeroQuantities = positions.every(
      (position) => position.quantity === 0
    );

    if (allZeroQuantities) {
      showNotification(
        "error",
        "All orders have empty quantity. Cannot proceed."
      );
      return false;
    } else if (!allHavePositiveQuantities) {
      showNotification("warning", "Some of the orders have empty quantity.");
      return true;
    }
    return true;
  };

  useEffect(() => {
    const shouldReturnEarly =
      lastClickedButtonAction?.toLowerCase() === "close" &&
      (!Array.isArray(selectedPositions) ||
        selectedPositions.length === 0 ||
        !hasValidQuantities(selectedPositions));

    if (shouldReturnEarly) {
      return;
    }

    setOrders((orders) => {
      const newPositionOrders = [];
      const ordersToBeReplaced = [];

      let curOrdersWithUpdatedPositions = [],
        curOrdersWithRemovedPositions = [],
        numOfOrdersToBeDeleted = 0;

      let numOfSelectedPositions = 0;

      if (Array.isArray(selectedPositions)) {
        numOfSelectedPositions = selectedPositions.length;
      }

      const lastAction = lastClickedButtonAction?.toLowerCase?.();

      if (numOfSelectedPositions > 0) {
        const dataToOrderMap = {};

        orders.forEach((o, i) => {
          const { hkbInstrumentId, bs } = o;
          if (dataToOrderMap[hkbInstrumentId] == undefined) {
            dataToOrderMap[hkbInstrumentId] = {};
          }

          dataToOrderMap[hkbInstrumentId][bs] = i;
          curOrdersWithUpdatedPositions.push({ ...o });
        });

        const optionInfoList = optionChainData?.optionInfoList;
        const newPositionOrdersMap = {}; // This var exists to identify if any two or more of the currently selected positions have same data; If so, the first one out of them will create an order and the remaining are ignored.
        const positionType =
          lastAction == "close" ? "C" : lastAction == "modify" ? "M" : "C"; // Second C to be replaced by Adjust later

        const positionHkbIdMap = {};

        selectedPositions.forEach((sp, index) => {
          const quantity = sp?.quantity;
          const hkbInstrumentId = sp?.hkbInstrumentId;

          const bs = quantity > 0 ? "S" : quantity < 0 ? "B" : undefined; //undefined;

          if (bs && newPositionOrdersMap?.[hkbInstrumentId]?.[bs] !== true) {
            if (positionHkbIdMap[hkbInstrumentId] == undefined) {
              positionHkbIdMap[hkbInstrumentId] = {};
            }

            positionHkbIdMap[hkbInstrumentId][bs] = true;

            const existingOrderIndex = dataToOrderMap?.[hkbInstrumentId]?.[bs];

            if (existingOrderIndex != undefined) {
              const existingOrder = orders[existingOrderIndex];
              if (
                existingOrder?.isAPosition &&
                existingOrder?.positionType == positionType
              ) {
                return;
                // If an existing position is of type "Close", prevent it from being added again.
                // This could happen when user selects positions independent of each other, i.e he keeps close button for different positions at different times
              } else {
                ordersToBeReplaced.push(existingOrder?.ticker); // If the orders are from option Chain, replace with the ones from positions but inform the user.
              }
            }

            const dataItemIndex = dataByHkbId[hkbInstrumentId]?.initialIndex;
            const expDateKey = dataByHkbId[hkbInstrumentId]?.dtKey;
            const dataItem = optionInfoList[dataItemIndex];
            const strike = dataItem?.strike;
            const optionType = dataItem?.optionType;
            const lotSize = dataItem?.lotSize;
            const ticker = dataItem?.ticker;
            const ltp = dataItem?.ltp;

            const expDate = dataItem["expiryDate"];
            const dt = new Date(expDate);
            const dtText = `${paddedDates[dt.getDate() - 1]} ${
              monthAbbrev[dt.getMonth()]["name"]
            }  '${dt.getFullYear() % 1000}`;

            const lots = Math.abs(Number(quantity) / lotSize);

            const positionOrder = {
              hkbInstrumentId,
              bs,
              lots,
              lotsOriginal: lots,
              type: optionType,
              dtKey: expDateKey,
              expDateText: dtText,
              checked: positionType != "M",
              strike,
              lotSize,
              ticker,
              isAPosition: true,
              positionType,
              changedPositionType: positionType,
              mProtect: 1,
              limitPrice: (
                Math.ceil(bsProps[bs].defaultLPMultiplier * ltp * 20 - 0.5) / 20
              ).toFixed(2),
            };

            if (existingOrderIndex != undefined) {
              curOrdersWithUpdatedPositions[existingOrderIndex] = positionOrder;
            } else {
              newPositionOrders.push(positionOrder);
              let newPositionOrderMap = newPositionOrdersMap[hkbInstrumentId];
              if (!newPositionOrderMap) {
                newPositionOrderMap = newPositionOrdersMap[hkbInstrumentId] =
                  {};
              }
              newPositionOrderMap[bs] = true;
            }
          }
        });

        // Below line deletes those positions that were chosen by the user during previous render but currently they have been dropped, howevver the number of positions he has chosen is not zero in this case because such a case would be similar to selecting the stock name from price pane.
        curOrdersWithUpdatedPositions = curOrdersWithUpdatedPositions.filter(
          ({ bs, hkbInstrumentId, isAPosition }) => {
            return !isAPosition || positionHkbIdMap?.[hkbInstrumentId]?.[bs];
          }
        );

        numOfOrdersToBeDeleted =
          orders.length - curOrdersWithUpdatedPositions.length;

        setShowOrderPane(true);
        setRequestType(lastAction);
        // As long as selectedPositions length is non zero, Show Order pane even if there is no non-zero quantity position and irrespective of whether the instumentId and prevInstumentId match or not.
      } else {
        setRequestType(lastAction ?? "create");
        // if (instrumentId != prevInstumentId) {
        //   setShowOrderPane(false);
        //   // Dont show order pane when selectedpositions length is zero and instrumentId has changed.
        //   // However, when instrumentId has nt changed, maintain current visibility state of order pane.
        // } else {
        // This could be happen when user opens a stock through positions pane and then clicks on the stock name in price pane, i.e instrumentId is same positions become 0.
        orders.forEach((o) => {
          if (!o.isAPosition) {
            curOrdersWithRemovedPositions.push({ ...o });
          }
        });
       
        setShowOrderPane(
          curOrdersWithRemovedPositions.length > 0 || lastAction != undefined
        );
        // }
      }

      setStrategyName(receivedStrategyName);

      let finalVal;
      if (
        newPositionOrders.length > 0 ||
        ordersToBeReplaced.length > 0 ||
        numOfOrdersToBeDeleted > 0
      ) {
        // newPositionOrders represents positionOrders that have been newly added by user from positions pane, it does nt include the positions that were already selected by him during a previous render
        // ordersToBeReplaced represents thos orders from optionChain pane whose rows be replaced by the positions fron positions pane because of a clash between the data of such orders and positions
        // numOfOrdersToBeDeleted represents those positions that were chosen by the user during previous but currently they have been dropped, howevver the number of positions he has chosen is not zero in this case because such a case would be similar to selecting the stock name from price pane.
        finalVal = [...curOrdersWithUpdatedPositions, ...newPositionOrders];
      } else if (numOfSelectedPositions < 1) {
        finalVal = curOrdersWithRemovedPositions; // This could be happen when user opens a stock through positions apne and then clicks on the stock name in price pane.
      } else {
        finalVal = orders; // This could happen when quantities of all positions selected by user are zero.
      }

      if (ordersToBeReplaced.length > 0) {
        // notification.warning({
        //   message: "Warning !",
        //   description: `Orders for tickers ${ordersToBeReplaced.join(
        //     ", "
        //   )} from optionChain are replaced by the corresponding positions`,
        // });

        showNotification(
          "warning",
          `Orders for tickers ${ordersToBeReplaced.join(
            ", "
          )} from optionChain are replaced by the corresponding positions`
        );
      }

      prevInstumentIdRef.current = instrumentId;

      return finalVal;
    });

    return () => {
      setAccounts([]);
      setShowSelectAccountsPane(false);
    };
  }, [
    instrumentId,
    optionChainData,
    selectedPositions,
    dataByHkbId,
    lastClickedButtonAction,
  ]);
}

export function addOrdersForPayOffGraphFromPositions(lastClickedButtonAction,
  instrumentId,
  optionChainData,
  allpositions,
  dataByHkbId,
setOrdersFromAllPositions){

    useEffect(() =>{
      const newOrders = [];
    // console.log("positions from Modify, Close",allpositions)
      if ((lastClickedButtonAction?.toLowerCase() === "close" || 
        lastClickedButtonAction?.toLowerCase() === "modify" ) &&
        Array.isArray(allpositions) && allpositions.length !== 0 ){

      for (let i = 0; i < allpositions.length; i++) {
      
        let o = {}
        const hkbInsId = allpositions[i]?.hkbInstrumentId
        const dataItemIndex = dataByHkbId[hkbInsId]?.initialIndex;
        const optionInfoList = optionChainData?.optionInfoList;
        const dataItem = optionInfoList[dataItemIndex];
        const lotSize = dataItem?.lotSize

        o.hkbInstrumentId = allpositions[i]?.hkbInstrumentId
        o.type = dataItem?.optionType;
        o.dtKey= dataByHkbId[hkbInsId]?.dtKey
        o.expDateText = allpositions[i]?.expiry
        o.checked = false
        o.strike = dataItem?.strike
        o.ticker = dataItem?.ticker
        o.lotSize = lotSize
        o.mProtect = 1
        if (allpositions[i]?.buyNetFilledQuantity > allpositions[i]?.sellNetFilledQuantity){
          o.bs = "B"
          o.limitPrice = allpositions[i]?.averageBuyPrice
          o.lots = (allpositions[i]?.buyNetFilledQuantity - allpositions[i]?.sellNetFilledQuantity)/lotSize
        }else{
          o.bs = "S"
          o.limitPrice = allpositions[i]?.averageSellPrice
          o.lots = (allpositions[i]?.sellNetFilledQuantity - allpositions[i]?.buyNetFilledQuantity)/lotSize
        }
        newOrders.push(o);
      }
 
    }

    setOrdersFromAllPositions(newOrders)
    },[
    instrumentId,
    optionChainData,
    dataByHkbId,
    allpositions,
    lastClickedButtonAction,
    ]);
}

export function useBlueStripNav(curExpDateData, osTableBodyRef) {
  useEffect(() => {
    const osTableBody = osTableBodyRef.current;
    if (curExpDateData != null && osTableBody) {
      const blueStrikeIndex = curExpDateData?.blueStrikeIndex;

      if (blueStrikeIndex > 12) {
        const indexOfRowToScroll = blueStrikeIndex - 12;

        const blueStrikeRow = osTableBody.querySelector(
          `#oc-br-${indexOfRowToScroll}`
        );

        if (blueStrikeRow) {
          blueStrikeRow.scrollIntoView();
        }
      }
    }
    // }, [expDates, selectedDateIndex, curExpDateData]);
    // Citing expDates and selectedDateIndex in dependencies array is not needed as curExpDateData will automatically change if either of these change.
  }, [curExpDateData]);
}

export function useDateNavWidth(
  optionChainData,
  dateElemWidthRef,
  datesElemMaxWidthRef,
  setOsDatesClassName,
  totalDateElemsRef,
  totalDatesFitRef
) {
  useEffect(() => {
    // date nav setup to calc. width of dates

    if (optionChainData != undefined) {
      const expDatesSec = document.querySelector(".oc-exp-dates-so-sec");
      const dateElems = expDatesSec.querySelectorAll(".oc-exp-date");
      totalDateElemsRef.current = dateElems.length;
      const dateElem = dateElems[0];
      dateElemWidthRef.current = dateElem.offsetWidth + dateMarRight;
      const datesElem = expDatesSec.querySelector(".oc-exp-dates");
      const datesElemWidth = datesElem.offsetWidth;
      totalDatesFitRef.current = Math.floor(
        (datesElemWidth + dateMarRight) / dateElemWidthRef.current
      );
      // datesElemMaxWidthRef.current =
      //   totalDatesFitRef.current * dateElemWidthRef.current - dateMarRight;
      datesElemMaxWidthRef.current =
        maxNumOfDatesTobeShown * dateElemWidthRef.current - dateMarRight;
      setOsDatesClassName("oc-exp-dates visible");
    }

    return () => {};
  }, [optionChainData]);
}

let firstWSAttempt = true;

export function useWebsocketSetup(
  instrumentId,
  optionChainData,
  selectedDateIndex,
  combineHttpAndWebsocketData,
  setLiveUpdateStatus,
  setWsStatus
) {
  const { showNotification } = useNotification();

  function handleWsError(event) {
    // notification.warning({
    //   message: "Error",
    //   description: `Facing issues with live updates for the selected Stock`,
    // });
    // setWsStatus(`Facing issues with live updates for the selected Stock`);
    showNotification(
      "error",
      `Facing issues with live updates for the selected Stock`
    );
  }

  useEffect(() => {
    //  websocket setup for optionChain
    if (optionChainData != undefined) {
      let sto,
        numOfMessages = 0;

      function timeoutCall() {
        setLiveUpdateStatus(true);

        clearTimeout(sto);
        sto = setTimeout(() => {
          console.log(
            `Have not received websocket data for the past ${wsMsgMaxFrequency} seconds`,
            numOfMessages
          );
          numOfMessages = 0;
          setLiveUpdateStatus(false);

          wsManager.reconnect(
            `ws/optionchain`,
            handleWsMessage,
            handleWsError,
            openHandler,
            `hkbInstrumentId=${instrumentId}&expiry=${qs}`
          );
        }, wsMsgMaxFrequency * 1000);
      }

      function handleWsMessage(event) {
        const data = JSON.parse(event.data);

        if (data.status === "success") {
          combineHttpAndWebsocketData(data, instrumentId, selectedDateIndex);
        } else {
          console.log("Server error on websocket data", data?.debugInfo ?? "");
        }
        timeoutCall();
      }

      function openHandler(event) {
        if (firstWSAttempt) {
          firstWSAttempt = false;
          // notification.info({
          //   message: "Success !",
          //   description: `Live updates enabled`,
          // });
          // setWsStatus(`Live updates enabled`);
          showNotification("success", `Live updates enabled`);
        }
        timeoutCall();
      }

      const selectedDate = new Date(
        optionChainData.expiryDateList[selectedDateIndex]
      );

      const qs = `${selectedDate.getFullYear()}-${
        monthAbbrev[selectedDate.getMonth()]["tdn"]
      }-${paddedDates[selectedDate.getDate() - 1]}`;

      wsManager.connect({
        endpoint: `ws/optionchain`,
        onMessage: handleWsMessage,
        errorHandler: handleWsError,
        openHandler: openHandler,
        queryParams: `hkbInstrumentId=${instrumentId}&expiry=${qs}`,
        initialTimeout: wsMsgConnectionInitialDelay * 1000,
      });

      return () => {
        clearTimeout(sto);
        setLiveUpdateStatus(false);
        wsManager.disconnect(
          `ws/optionchain`,
          `hkbInstrumentId=${instrumentId}&expiry=${qs}`
        );
      };
    }
  }, [instrumentId, optionChainData, selectedDateIndex]);
}

let firstDepthWSAttempt = true;
export function useDepthWebsocketSetup(instrumentId, setTpl) {
  function handleWsError(event) {
    // notification.warning({
    //   message: "Error",
    //   description: `Facing issues with live updates for the selected Stock`,
    // });
    // setWsStatus(`Facing issues with live updates for the selected Stock`);
  }

  useEffect(() => {
    //  websocket setup for optionChain
    let sto,
      numOfMessages = 0;

    function timeoutCall() {
      // setLiveUpdateStatus(true);

      clearTimeout(sto);
      sto = setTimeout(() => {
        console.log(
          `Have not received websocket depth data for the past ${wsMsgMaxFrequency} seconds`,
          numOfMessages
        );
        numOfMessages = 0;
        // setLiveUpdateStatus(false);

        wsManager.reconnect(
          `ws/tickers`,
          handleWsMessage,
          handleWsError,
          openHandler,
          `hkbIds=${instrumentId}`
        );
      }, wsMsgMaxFrequency * 1000);
    }

    function handleWsMessage(event) {
      const data = JSON.parse(event.data);

      if (data.status === "success") {
        setTpl(data?.tickerPriceList || []);
      } else {
        console.log("Server error on websocket data", data?.debugInfo ?? "");
      }
      timeoutCall();
    }

    function openHandler(event) {
      if (firstDepthWSAttempt) {
        firstDepthWSAttempt = false;
        // notification.info({
        //   message: "Success !",
        //   description: `Live updates enabled`,
        // });
        // setWsStatus(`Live updates enabled`);
      }
      timeoutCall();
    }

    wsManager.connect({
      endpoint: `ws/tickers`,
      onMessage: handleWsMessage,
      errorHandler: handleWsError,
      openHandler: openHandler,
      queryParams: `hkbIds=${instrumentId}`,
    });

    return () => {
      clearTimeout(sto);
      // setLiveUpdateStatus(false);
      wsManager.disconnect(`ws/tickers`, `hkbIds=${instrumentId}`);
    };
  }, []);
}
