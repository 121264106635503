import { cmcStrategy } from "@/api/optionChain";
import { numericFieldsRegExp, reqTypeMsgMap } from "@/constants/optionChain";
import useNotification from "@/hooks/useNotification";
import { useCallback, useEffect, useState } from "react";
import { AcpTableHeader, LoaderPopup } from "./descendants";
import { createStrategyReqBodyAndType } from "./dtos";
import { useFetchAccounts } from "./useFetchAccounts";
import { useMarginRequirements } from "./useMarginRequirements";
import debounce from "lodash.debounce";
import {
  selectedInstrumentState,
  showStrategyCreationPanelState,
} from "@/state/positionstate";
import { useRecoilValue, useSetRecoilState } from "recoil";
import OverwriteInput from "../OverwriteInput";
import {formatCurrency} from "../../utils";
import { Flex, Select, Tooltip } from "antd";

const REQUEST_TYPE = "create";

const StrategyCreationPanel = ({ ordersPaneHeight }) => {
  const selectedInstrument = useRecoilValue(selectedInstrumentState);
  const [userPreference, setUserPreference] = useState("run");

  const [strategyName, setStrategyName] = useState("");
  const [debouncedStrategyName, setDebouncedStrategyName] =
    useState(strategyName);

  const [strategySubmitInProgress, setStrategySubmitInProgress] =
    useState(false);
  const { accounts, multipliers: accountMultipliers } = useFetchAccounts();

  const [multipliers, setMultipliers] = useState(accountMultipliers);
  const marginRequiredPerAccount = useMarginRequirements(
    accounts,
    multipliers,
    debouncedStrategyName
  );
  const setShowStrategyCreationPanel = useSetRecoilState(
    showStrategyCreationPanelState
  );

  useEffect(() => {
    setMultipliers(accountMultipliers);
  }, [accountMultipliers]);

  const { showNotification } = useNotification();

  const handleStrategyNameChange = useCallback((e) => {
    setStrategyName(e.target.value);
  }, []);

  useEffect(() => {
    const debouncedUpdate = debounce((value) => {
      setDebouncedStrategyName(value);
    }, 500);

    debouncedUpdate(strategyName);

    return () => {
      debouncedUpdate.cancel();
    };
  }, [strategyName]);

  const changeMultiplier = useCallback((index, prop, value) => {
    const trimmedValue = value.trim();
    if (numericFieldsRegExp.test(trimmedValue) || trimmedValue === "") {
      setMultipliers((multipliers) =>
        multipliers.map((m, i) => (i === index ? { ...m, [prop]: value } : m))
      );
    } else {
      showNotification("error", "Only numeric values allowed");
    }
  }, []);

  const executeStrategySetup = async () => {
    setStrategySubmitInProgress(true);

    //passing empty orders as be now supports that too
    try {
      const reqBody = createStrategyReqBodyAndType(
        accounts,
        [],
        multipliers,
        strategyName,
        REQUEST_TYPE,
        userPreference
      );


      reqBody["parentHkbInstrumentId"] = selectedInstrument;
      await cmcStrategy(reqBody, REQUEST_TYPE);
      showNotification("success", `Strategy created successfully.`);
      // setDisableSubmit(true);
      setShowStrategyCreationPanel(false);
    } catch (e) {
      showNotification(
        "error",
        e.message || `Strategy ${reqTypeMsgMap[REQUEST_TYPE]} Failed`
      );
    } finally {
      setStrategySubmitInProgress(false);
    }
  };

  const handleClose = (e) => {
    setShowStrategyCreationPanel(false);
  };

  const handleUserPreferenceChange = (value) => {
    setUserPreference(value);
  };

  return (
    <div className="strategy-creation-panel">
      <div
        className="oc-asp"
        style={{
          height: `calc(100vh - ${ordersPaneHeight})`,
        }}
      >
        {strategySubmitInProgress && <LoaderPopup />}

        <button className="oc-acp-close-btn" onClick={handleClose}>
          <span className="oc-acp-close-btn-icon">&#x276C;</span> Back
        </button>
        <div className="oc-acp-title-and-name">
          <div className="oc-acp-title">Account Selection</div>
<Flex justify="space-between">
          <div className="oc-acp-strategy-name-sec">
            <label
              htmlFor="strategy-name-inp"
              className="oc-acp-strategy-name-label"
            >
              Strategy Name:
            </label>{" "}
            <input
              id="strategy-name-inp"
              className="oc-acp-strategy-name-inp"
              onInput={handleStrategyNameChange}
              defaultValue={strategyName}
            />
          </div>
          <div className="oc-acp-strategy-name-sec" style={{marginLeft:'15px'}}>
            <label
              htmlFor="strategy-name-inp"
              className="oc-acp-strategy-name-label"
            >
              <Tooltip placement="topLeft" title="Auto: execute orders as soon as it's submitted | Manual: save orders and you will manually trigger from strategy section later">
              Order Exec Mode:
              </Tooltip>
            </label>{" "}
            <Select value={userPreference} onChange={handleUserPreferenceChange} style={{marginLeft:"8px", width: 100 }}>
              <Select.Option value="run">Auto</Select.Option>
              <Select.Option value="pause">Manual</Select.Option>
            </Select>
          </div>
        </Flex>

        </div>
        <div className="oc-acp-table">
          <AcpTableHeader />
          <div className="oc-acp-body">
            {accounts.map((account, i) => {
              const multiplier = multipliers?.[i]?.multiplier;


              // const originalMultiplier = multipliers?.[i]?.originalMultiplier;

              const priority = multipliers?.[i]?.priority;
              const { accountId, clientId, maginAvailable } = account;
              console.log("account", account);

              const finalMargin = maginAvailable
              let greenRed =
                  maginAvailable < 0? "dontknow"
                      :maginAvailable < 5 ? "red"
                          : "green"


              const accMarginInfo = marginRequiredPerAccount?.[accountId];
              const accMarginrequired =
                accMarginInfo?.marginRequiredRounded ?? "NA";


              return (
                <div key={accountId} className={`oc-acp-br oc-acp-br-${i}`}>
                  <div className="oc-acp-br-cell oc-acp-br-cell-a">
                    {clientId}
                  </div>
                  <div className="oc-acp-br-cell oc-acp-br-cell-ma">
                    {formatCurrency(account?.maginAvailable)}
                  </div>
                  <div className="oc-acp-br-cell oc-acp-br-cell-mtplr">
                    {/* <input
                      value={multiplier}
                      placeholder="123"
                      onInput={(e) =>
                        changeMultiplier(i, "multiplier", e.target.value)
                      }
                     className="all-unset  text-center" 
                    /> */}
                    <OverwriteInput
                      placeholder="123"
                      onChange={(value) =>
                        changeMultiplier(i, "multiplier", value)
                      }
                      value={multiplier}
                      className="all-unset  text-center"
                    />
                  </div>
                  <div className="oc-acp-br-cell oc-acp-br-cell-pr">
                    {/* <input
                      placeholder="123"
                      value={priority}
                      onInput={(e) =>
                        changeMultiplier(i, "priority", e.target.value)
                      }
                      className="all-unset text-center"
                    /> */}
                    <OverwriteInput
                      placeholder="123"
                      onChange={(value) =>
                        changeMultiplier(i, "priority", value)
                      }
                      value={priority}
                      className="all-unset  text-center"
                    />
                  </div>

                  <div className="oc-acp-br-cell oc-acp-br-cell-mrq">
                    {formatCurrency(accMarginrequired)}{" "}
                  </div>

                  <div className="oc-acp-br-cell oc-acp-br-cell-mao">
                    {formatCurrency(finalMargin)}{" "}
                  </div>
                  <div
                    className={`oc-acp-br-cell oc-acp-br-cell-check ${greenRed}`}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="oc-acp-submit-row">
          <div className="oc-acp-submit-note">
            ***Note: Multipliers, Priorities and Strategy Name are required
          </div>
          <button
            className="oc-acp-submit"
            disabled={strategyName.length < 1}
            onClick={executeStrategySetup}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default StrategyCreationPanel;
