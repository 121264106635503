import { useState, useCallback, useEffect, useRef } from 'react';

const useResizablePanel = (parentRef, initialWidthPercent = 30, minWidthPercent = 10, maxWidthPercent = 60) => {
  const [widthPercent, setWidthPercent] = useState(initialWidthPercent);
  const [isResizing, setIsResizing] = useState(false);

  const startResizing = useCallback(() => setIsResizing(true), []);
  const stopResizing = useCallback(() => setIsResizing(false), []);

  const handleMouseMove = useCallback((e) => {
    if (!isResizing || !parentRef.current) return;

    const parentWidth = parentRef.current.offsetWidth;
    let newWidthPercent = ((e.clientX - parentRef.current.getBoundingClientRect().left) / parentWidth) * 100;
    newWidthPercent = Math.min(Math.max(newWidthPercent, minWidthPercent), maxWidthPercent);
    setWidthPercent(newWidthPercent);
  }, [isResizing, minWidthPercent, maxWidthPercent, parentRef]);

  useEffect(() => {
    if (isResizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', stopResizing);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', stopResizing);
    };
  }, [isResizing, handleMouseMove, stopResizing]);

  return { widthPercent, startResizing };
};

export default useResizablePanel;
