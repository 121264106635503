import { GoogleOAuthProvider } from "@react-oauth/google";
import Routes from "./routes";
import "./style.css";
import { userState } from "./state/authState";
import { useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { getAuthState } from "./utils/localStorageUtil";
import { Spin } from "antd";
import DebugPopup from "./components/DebugPopup";
import useDebugMode from "./hooks/useDebugMode";
export const App = () => {
  const setUser = useSetRecoilState(userState);
  const [loading, setLoading] = useState(true);
  useDebugMode()

  const clientId = process.env.GOOGLE_CLIENT_ID;

  useEffect(() => {
    const storedAuthState = getAuthState();
    
    if (storedAuthState) {
      setUser(storedAuthState);
    }
    setLoading(false);
  }, [setUser]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Routes />
      <DebugPopup />
    </GoogleOAuthProvider>
  );
};

export default App;
