import { Col, Row } from "antd";
import StrategyDetails from "../StrategyDetails";

const calculateColSpan = (isColumnExpanded, activeTab) => {
  if (isColumnExpanded) {
    return activeTab === "position" ? 8 : 12;
  }
  return 24;
};

const StrategyGrid = ({ data, accountId, isColumnExpanded, activeTab }) => {
  const colSpan = calculateColSpan(isColumnExpanded, activeTab);

  return (
    <Row gutter={16}>
      {data.map((strategy, index) => (
        <Col span={colSpan} key={index + strategy.strategyInstanceName}>
          <StrategyDetails
            accountId={accountId}
            key={strategy.strategyInstanceName}
            strategy={strategy}
            activeTab={activeTab}
          />
        </Col>
      ))}
    </Row>
  );
};

export default StrategyGrid;
