import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { selectedPositionsState } from "@/state/positionstate";
import { Checkbox, Flex, Space } from "antd";
import {
  formatNumber,
  getFormattedQuantity,
  getNetQuantity,
  hasStrategyMismatch,
} from "@/utils";
import { notification } from "antd";
import StatusImage from "../StatusImage";
import useNotification from "@/hooks/useNotification";

const StrategyPositionTable = ({ strategy, accountId }) => {
  const [selectedPositionsArray, setSelectedPositionsState] = useRecoilState(
    selectedPositionsState
  );
  const [selectedPositions, setSelectedPositions] = useState(new Set());
  const { showNotification } = useNotification()

  const positions = strategy.hkbPositions;

  useEffect(() => {
    // Load initial selections if any
    const initialSelections = selectedPositionsArray
      .filter(
        (p) =>
          p.accountId === accountId &&
          p.strategyInstanceName === strategy.strategyInstanceName
      )
      .map((p) => `${accountId}-${p.tradingSymbol}-${p.strategyInstanceName}`);
    setSelectedPositions(new Set(initialSelections));
  }, [accountId, strategy, selectedPositionsState]);

  const isSelected = (position) => {
    return selectedPositions.has(
      `${accountId}-${position.tradingSymbol}-${position.strategyInstanceName}`
    );
  };

  const handleCheckboxChange = (position, isChecked) => {
    const positionKey = `${accountId}-${position.tradingSymbol}-${position.strategyInstanceName}`;
    const newSelectedPositions = new Set(selectedPositions);
    let updatedPositions = [...selectedPositionsArray];

    if (isChecked) {
      // Check if the selected position's strategy matches with existing selections
      // if (selectedPositions.size > 0 && !isSelected(position)) {
      if (
        isChecked &&
        hasStrategyMismatch(position.strategyInstanceName, updatedPositions)
      ) {
        // notification.error({
        //   message: "Strategy Mismatch",
        //   description: "You can only select positions with the same strategy.",
        // });
        showNotification("error","You can only select positions with the same strategy.")
        return;
      }

      newSelectedPositions.add(positionKey);
      updatedPositions.push({
        ...position,
        quantity: getNetQuantity(position),
        accountId: accountId,
      });
    } else {
      newSelectedPositions.delete(positionKey);
      updatedPositions = updatedPositions.filter(
        (p) =>
          p.tradingSymbol !== position.tradingSymbol ||
          p.strategyInstanceName !== position.strategyInstanceName ||
          p.accountId !== accountId
      );
    }

    setSelectedPositions(newSelectedPositions);
    setSelectedPositionsState(updatedPositions);
  };

  const handleSelectAll = (isChecked) => {
    let updatedPositions = [...selectedPositionsArray];
    let newSelectedPositions = new Set(selectedPositions);

    if (isChecked) {
      if (
        positions.some((position) =>
          hasStrategyMismatch(position.strategyInstanceName, updatedPositions)
        )
      ) {
        // notification.error({
        //   message: "Strategy Mismatch",
        //   description: "You can only select positions with the same strategy.",
        // });
        showNotification("error", "You can only select positions with the same strategy.")
        return;
      }
      positions.forEach((position) => {
        const positionKey = `${accountId}-${position.tradingSymbol}-${position.strategyInstanceName}`;
        if (!newSelectedPositions.has(positionKey)) {
          newSelectedPositions.add(positionKey);

          updatedPositions.push({
            ...position,
            quantity: getNetQuantity(position),
            accountId: accountId,
          });
        }
      });
    } else {
      newSelectedPositions = new Set();
      updatedPositions = updatedPositions.filter(
        (p) => p.strategyInstanceName !== strategy.strategyInstanceName
      );
    }

    setSelectedPositions(newSelectedPositions);
    setSelectedPositionsState(updatedPositions);
  };

  return (
    <table className="portfolio-table">
      <thead>
        <tr>
          <th>
            <Checkbox
              disabled={strategy.isReadOnly}
              checked={
                positions && positions.length > 0 && positions.every(isSelected)
              }
              onChange={(e) => handleSelectAll(e.target.checked)}
            />
          </th>
          <th>Symbol</th>
          <th>LTP</th>
          <th>AP</th>
          <th>QTY</th>
          <th>P/L</th>
        </tr>
      </thead>
      <tbody>
        {positions &&
          positions.map((position) => (
            <tr key={position.tradingSymbol}>
              <td>
                <Checkbox
                disabled={strategy.isReadOnly}
                  checked={isSelected(position)}
                  onChange={(e) =>
                    handleCheckboxChange(position, e.target.checked)
                  }
                />
              </td>
              <td>
                <Space >
                  {position.tradingSymbol}
                  <StatusImage status={position.positionStatus} errorStatus={position.errorStatus} />
                </Space>
              </td>
              <td className="text-right">{formatNumber(position.ltp)}</td>
              <td className="text-right">{formatNumber(position.averageBuyPrice)}/{formatNumber(position.averageSellPrice)}</td>
              <td className="text-right">{getFormattedQuantity(position)}</td>
              <td className="text-right">{formatNumber(position.pnl)}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default StrategyPositionTable;
