import React, { useState, useEffect } from "react";

interface OverwriteInputProps {
  value?: string;
  onChange: (value) => void;
  className?: string;
  type?: string;
  placeholder:any
  disabled?: boolean
}

const OverwriteInput: React.FC<OverwriteInputProps> = ({
  value = "",
  onChange,
  className,
  type,
  placeholder,
  disabled = false
}) => {
//   const [internalValue, setInternalValue] = useState(value);
  const [isFirstKey, setIsFirstKey] = useState(true);

//   useEffect(() => {
//     setInternalValue(value);
//   }, [value]);

  const handleFocus = () => {
    setIsFirstKey(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    let newValue = inputValue;

    if (isFirstKey) {
      newValue = inputValue.replace(value, '')

      setIsFirstKey(false);
    }

    onChange(newValue);
  };

  return (
    <input
      type={type}
      value={value}
      onFocus={handleFocus}
      onChange={handleChange}
      className={className}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default OverwriteInput;
