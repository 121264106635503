// DebugPopup.tsx
import { debugInfoState, isDebugModeState } from '@/state/positionstate';
import { Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CloseOutlined } from '@ant-design/icons';

const DebugPopup: React.FC = () => {
  const [debugInfo, setDebugInfo] = useRecoilState(debugInfoState);
  const isDebugMode = useRecoilValue(isDebugModeState);
  // Initial position in the center of the screen
  const [position, setPosition] = useState({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
  const [dragging, setDragging] = useState(false);
  // Track the initial click position
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });

  // Function to start dragging
  const onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setDragging(true);
    // Record the initial mouse down position
    setStartPos({
      x: event.clientX - position.x,
      y: event.clientY - position.y,
    });
    event.preventDefault(); // Prevent default to avoid text selection during drag
  };

  // Function to handle mouse move updates
  useEffect(() => {
    const onMouseMove = (event: MouseEvent) => {
      if (!dragging) return;
      setPosition({
        x: event.clientX - startPos.x,
        y: event.clientY - startPos.y,
      });
    };

    if (dragging) {
      document.addEventListener('mousemove', onMouseMove);
    }

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [dragging, startPos]);

  // Function to stop dragging
  const onMouseUp = () => {
      setDragging(false);
  };

  // Attach the mouseup listener to the window to ensure it catches all cases
  useEffect(() => {
    window.addEventListener('mouseup', onMouseUp);

    return () => {
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, []);


  if (!isDebugMode || debugInfo.length === 0) return null;

  const allKeys = debugInfo.reduce((acc, { info }) => {
    Object.keys(info).forEach(key => {
      if (acc.indexOf(key) === -1) acc.push(key);
    });
    return acc;
  }, []);

  // Dynamically generate columns based on all unique keys
  const columns = allKeys.map(key => ({
    title: key.charAt(0).toUpperCase() + key.slice(1),
    // dataIndex: ['info', key],
    key,
    render: (record: any) => {
        const value = record[key]
        console.log('value :>> ',key, value);
      const content = typeof value === 'object' ? JSON.stringify(value, null, 2) : value;
      return content?.length > 100 ? (
        <Tooltip title={content}>
          <span>{`${content.substring(0, 97)}...`}</span>
        </Tooltip>
      ) : (
        <span>{content}</span>
      );
    },
  }));


  // Add timestamp as the first column
  columns.unshift({
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: (timestamp: Date) => timestamp.toLocaleString(),
  });


  return (
    <div
    style={{
      position: 'fixed',
      left: `${position.x}px`,
      top: `${position.y}px`,
      zIndex: 1000,
      width: 'auto',
      maxHeight: '80%',
      overflow: 'auto',
      backgroundColor: '#fff',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      padding: '10px',
      borderRadius: '4px',
      cursor: dragging ? 'grabbing' : 'grab',
    }}
    onMouseDown={onMouseDown}
  >
      <div style={{ marginBottom: '10px', textAlign: 'right' }}>
        <CloseOutlined
                              onClick={() => setDebugInfo([])}
                            />
      </div>
      <Table
      className="portfolio-table"
      columns={columns}
      dataSource={debugInfo.map((entry, index) => ({
        ...entry.info,
        key: index,
        timestamp: entry.timestamp.toLocaleString(),
      }))}
      pagination={false}
      scroll={{ x: 'max-content' }}
    />
    </div>
  );
};

export default DebugPopup;
