// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* index.css */
.tab-container {
  width: 100%;

}

.tab-list {
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  height: 28px;
}

.tab-button {
  /* padding: 16px 20px; */
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
  transition: border-color 0.3s;
  margin-bottom: -1px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
}

.tab-button:hover {
  color: #1890ff;
}

.tab-button:focus {
  outline: none;
}

.tab-button:disabled {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
  margin-bottom: -1px;
  cursor: default;
}

.tab-content {
  /* width: 100%; */
  /* padding: 8px; */
  /* border: 1px solid #e8e8e8; */
  border-top: none;
  margin-top: 4px;
  /* max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden; */
  
}

.fixed-height {
  /* max-height: 500px; */
  /* overflow: auto; */
  /* overflow-y: auto;
  overflow-x: hidden; */
}

.accounts-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  /* padding: 16px; */
  /* Padding around the grid */

  align-items: flex-start; /* Align items vertically at the start */
  justify-content: flex-start; /* Align items horizontally at the start */
}

.action-button{
   padding-left: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Tabs/styles.css"],"names":[],"mappings":"AAAA,cAAc;AACd;EACE,WAAW;;AAEb;;AAEA;EACE,aAAa;EACb,gCAAgC;EAChC,YAAY;AACd;;AAEA;EACE,wBAAwB;EACxB,eAAe;EACf,gBAAgB;EAChB,SAAS;EACT,UAAU;EACV,6BAA6B;EAC7B,mBAAmB;EACnB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,gCAAgC;EAChC,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf;;uBAEqB;;AAEvB;;AAEA;EACE,uBAAuB;EACvB,oBAAoB;EACpB;uBACqB;AACvB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,mBAAmB;EACnB,4BAA4B;;EAE5B,uBAAuB,EAAE,wCAAwC;EACjE,2BAA2B,EAAE,0CAA0C;AACzE;;AAEA;GACG,kBAAkB;AACrB","sourcesContent":["/* index.css */\n.tab-container {\n  width: 100%;\n\n}\n\n.tab-list {\n  display: flex;\n  border-bottom: 1px solid #e8e8e8;\n  height: 28px;\n}\n\n.tab-button {\n  /* padding: 16px 20px; */\n  cursor: pointer;\n  background: none;\n  border: 0;\n  outline: 0;\n  transition: border-color 0.3s;\n  margin-bottom: -1px;\n  font-size: 16px;\n  color: rgba(0, 0, 0, 0.65);\n}\n\n.tab-button:hover {\n  color: #1890ff;\n}\n\n.tab-button:focus {\n  outline: none;\n}\n\n.tab-button:disabled {\n  color: #1890ff;\n  border-bottom: 2px solid #1890ff;\n  margin-bottom: -1px;\n  cursor: default;\n}\n\n.tab-content {\n  /* width: 100%; */\n  /* padding: 8px; */\n  /* border: 1px solid #e8e8e8; */\n  border-top: none;\n  margin-top: 4px;\n  /* max-height: 500px;\n  overflow-y: scroll;\n  overflow-x: hidden; */\n  \n}\n\n.fixed-height {\n  /* max-height: 500px; */\n  /* overflow: auto; */\n  /* overflow-y: auto;\n  overflow-x: hidden; */\n}\n\n.accounts-grid {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 16px;\n  /* padding: 16px; */\n  /* Padding around the grid */\n\n  align-items: flex-start; /* Align items vertically at the start */\n  justify-content: flex-start; /* Align items horizontally at the start */\n}\n\n.action-button{\n   padding-left: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
