import { FunctionComponent } from "react";
import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";
import { App } from "./App";

const Index: FunctionComponent = () => {
  return (
    <RecoilRoot>
      <App />
    </RecoilRoot>
  );
};

const domNode = document.getElementById("root");
const root = createRoot(domNode);
root.render(<Index />);
