import { getAccessToken } from "./localStorageUtil";
import { Modal } from 'antd';

class WebSocketManager {
  private host: string;
  private port: string;
  private connections: { [key: string]: WebSocket } = {};
  private timers = {};
  private closeCallbacks = {};

  wsPath = process.env.WEBSOCKET_PATH_PREFIX ?? "/v2";

  constructor() {
    this.host = process.env.WEBSOCKET_HOST || window.location.hostname;
    this.port = process.env.WEBSOCKET_PORT || window.location.port; //"8090";
  }

  connect({
    endpoint,
    onMessage,
    errorHandler = () => {},
    openHandler = () => {},
    queryParams = "a=1",
    initialTimeout,
  }: {
    endpoint: string;
    onMessage: (event: MessageEvent) => void;
    errorHandler?: (event: Event) => void;
    openHandler?: (event: Event) => void;
    queryParams?: string;
    initialTimeout?: number;
  }) {
    let token = getAccessToken(); // Retrieve the token from local storage

    if (!token) {
      console.error("No token available for WebSocket connection");
      return;
    }

    const handleWebSocketMessage = (event) => {
      try {
        const data = JSON.parse(event.data);

        if (data.httpStatusCode === 401 || data.httpStatusCode === 403) {
          // If the message indicates an authentication error, redirect to login
          Modal.error({
            zIndex:10000,
            title: 'Authentication Error',
            content: `${data.message}. Please log in again.`,
            onOk: () => {
              // Clear authentication data and redirect to login
              this.clearAuthenticationData();
              this.redirectToLogin();
            },
            onCancel: () => {
              // Clear authentication data and redirect to login
              this.clearAuthenticationData();
              this.redirectToLogin();
            }
          });
          return;
        }

        onMessage(event);
      } catch (e) {
        console.error("Error parsing WebSocket message:", e);
      }
    };

    const pathAndParams = `${endpoint}?${queryParams}`;
    let numOfAttempts = 0,
      timeoutDuration = 5000;

    const connectWebSocket = () => {
      if (!this.connections[pathAndParams]) {
        numOfAttempts++;

        let scheme = location.protocol === "https:" ? "wss" : "ws";

        const randNum = Math.floor(Math.random() * 100000 + 1);
        let uniqueId =
          process.env.ENVIRONMENT == "dev"
            ? `${process.env.DEVELOPER_NAME || "Developer"}s_Machine_` + randNum
            : `Prod_Machine_${randNum}`;

        let storedAuthState = localStorage.getItem("authState");
        let email = "";
        if (storedAuthState) {
          const jsonData = JSON.parse(storedAuthState);
          email = jsonData.userData.email;
        }

        let ws = new WebSocket(
          `${scheme}://${this.host}:${this.port}${
            this.wsPath
          }/${pathAndParams}&authToken=${encodeURIComponent(
            token
          )}&hostId=${uniqueId}&authAccount=${email}`
        );

        ws.onopen = (e) => {
          numOfAttempts = 0;
          openHandler(e);
        };

        ws.onmessage = handleWebSocketMessage;

        ws.onclose = (e) => {
          clearTimeout(this.timers[pathAndParams]);
          delete this.connections[pathAndParams];
          delete this.timers[pathAndParams];
          const closeCallback = this.closeCallbacks[pathAndParams];
          delete this.closeCallbacks[pathAndParams];
          closeCallback?.();
        };

        ws.onerror = (error) => {
          console.error("WebSocket error:", error);

          if (numOfAttempts == 5) {
            errorHandler(error);
          }

          if (numOfAttempts % 10 == 0) {
            timeoutDuration = Math.min(timeoutDuration + 5000, 30000);
          }

          // delete this.connections[pathAndParams];
          this.disconnect(endpoint, queryParams, () => {
            this.timers[pathAndParams] = setTimeout(() => {
              connectWebSocket();
            }, timeoutDuration);
          });
        };

        this.connections[pathAndParams] = ws;
      }
    };

    if (initialTimeout == undefined) {
      connectWebSocket();
    } else {
      this.timers[pathAndParams] = setTimeout(() => {
        connectWebSocket();
      }, initialTimeout);
    }
  }

  reconnect(
    endpoint: string,
    onMessage: (event: MessageEvent) => void,
    errorHandler = (event: Event) => {},
    openHandler = (event: Event) => {},
    queryParams = "a=1"
  ): void {
    const pathAndParams = `${endpoint}?${queryParams}`;

    if(this.connections[pathAndParams]) {
      this.disconnect(endpoint, queryParams, () => {
        this.connect({
          endpoint,
          onMessage,
          errorHandler,
          openHandler,
          queryParams,
        });
      });
    } else {
      this.connect({
        endpoint,
        onMessage,
        errorHandler,
        openHandler,
        queryParams,
      });
    }
  }

  disconnect(
    endpoint: string,
    queryParams = "a=1",
    closeCallback = () => {}
  ): void {
    const pathAndParams = `${endpoint}?${queryParams}`;

    if (this.connections[pathAndParams]) {
      this.closeCallbacks[pathAndParams] = closeCallback;
      this.connections[pathAndParams].close();
      // delete this.connections[pathAndParams];
      // clearTimeout(this.timers[pathAndParams]);
      // delete this.timers[pathAndParams];
    }
  }

  clearAuthenticationData() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("authState");
  }

  redirectToLogin() {
    window.location.href = "/";
  }
}

export const wsManager = new WebSocketManager();
