import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Layout, notification } from 'antd';

import OptionChain from '@/components/OptionChain';
import PositionViewSelector from '@/components/PositionViewSelector';
import PriceListContainer from '@/components/PriceListContainer';
import PositionContainer from './PositionContainer';

import {
  selectedInstrumentState,
  selectedPositionsState,
  actionButtonClickCountState,
  lastClickedButtonActionState,
  defaultActionState,
  showStrategyCreationPanelState,
  allPositionsSelectedStrategyState,
} from '@/state/positionstate';
import useResizablePanel from '@/hooks/useResizablePanel';
import useNotification from '@/hooks/useNotification';

const { Content } = Layout;

const MINIMUM_WINDOW_HEIGHT = 300;

const Home = () => {
  const selectedInstrument = useRecoilValue(selectedInstrumentState);
  const positions = useRecoilValue(selectedPositionsState);
  const allPositions = useRecoilValue(allPositionsSelectedStrategyState);
  const buttonClickCount = useRecoilValue(actionButtonClickCountState);
  const parentRef = useRef(null);
  const { widthPercent: leftMenuWidthPercent, startResizing } = useResizablePanel(parentRef);

  const lastClickedButtonAction = useRecoilValue(lastClickedButtonActionState);
  const defaultActionValues = useRecoilValue(defaultActionState);
  const setShowStrategyCreationPanel = useSetRecoilState(showStrategyCreationPanelState);



  const [optionChainProps, setOptionChainProps] = useState({});
  const [positionContainerHeight, setPositionContainerHeight] = useState(0);
  const topContainerRef = useRef(null);

  const { showNotification } = useNotification()

  useEffect(() => {
    if (buttonClickCount === 0) {
      setOptionChainProps({ positions: undefined, allPositions: undefined, hkbInstrumentId: selectedInstrument });
      return;
    }

    if (!positions || positions.length === 0) {
      if (lastClickedButtonAction === "Modify") {
        setOptionChainProps({ positions: [], allPositions, ...defaultActionValues });
        return
      }
      // notification.error({ message: "Invalid Position", description: "Please select positions." });
      showNotification("error", "Please select positions.")
      return;
    }

    const { strategyInstanceName, strategyName } = positions[0];

    setOptionChainProps({ positions, allPositions, hkbInstrumentId: selectedInstrument, strategyInstanceName, strategyName });
  }, [buttonClickCount, selectedInstrument]);

  const calculateContainerHeight = () => window.innerHeight - MINIMUM_WINDOW_HEIGHT;

  useLayoutEffect(() => {
    const updatePositionContainerHeight = () => setPositionContainerHeight(calculateContainerHeight());
    updatePositionContainerHeight();

    window.addEventListener("resize", updatePositionContainerHeight);
    return () => window.removeEventListener("resize", updatePositionContainerHeight);
  }, []);

  useEffect(() => {
    setShowStrategyCreationPanel(false)
  }, [selectedInstrument]);

  return (
    <Layout style={{ minHeight: '100vh' }} ref={parentRef}>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
        <div id="left-menu" style={{ flex: '0 0 auto', width: `${leftMenuWidthPercent}%` }}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div ref={topContainerRef} style={{ overflow: 'auto' }}>
              <PriceListContainer />
              <PositionViewSelector />
            </div>
            <div style={{ height: `${positionContainerHeight}px`, overflow: 'auto' }}>
              <PositionContainer />
            </div>
          </div>
        </div>
        <div className="resizer" onMouseDown={startResizing}></div>

        <Content style={{ flex: '1 1 auto' }}>
          <OptionChain {...optionChainProps} />
        </Content>
      </div>
    </Layout>
  );
};

export default Home;
