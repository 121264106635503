import request from "@/utils/request";

export default async function fetchBrokerOrders(accountId, hkbOrderId) {
  let storedAuthState = localStorage.getItem("authState");
  let email = '';
  if (storedAuthState) {
    const jsonData = JSON.parse(storedAuthState)
    email= jsonData.userData.email
  }

  try {
    const { data: response } = await request(
      `orders/broker?accountId=${accountId}&${email}`,
      {
        timeout: 3000,
      }
    );

    // Check if response is valid and has a status of 'success'
    if (!response || response.status !== "success") {
      throw new Error("Invalid response from server");
    }

    if (!Array.isArray(response.brokerOrders)) {
      return [];
    }

    console.log('response.brokerOrders :>> ', response.brokerOrders);

    // Filter broker orders by tradingSymbol
    const filteredOrders = response.brokerOrders.filter(
      (order) => {
        console.log('order.hkbOrderId :>> ', order.hkbOrderId , hkbOrderId,order.hkbOrderId == hkbOrderId);
        return order.hkbOrderId == hkbOrderId
      }
    );

    console.log('filteredOrders :>> ', filteredOrders);

    return filteredOrders;
  } catch (error) {
    // Handle any errors
    console.error("Error fetching broker orders:", error.message);
    throw error; // Rethrow the error to be handled by the caller
  }
}
