// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 0 0 0px;
  }
  
  .portfolio-table th, .portfolio-table td {
    height: 30px;
    text-align: center;
    border-bottom: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
  }
  
  .portfolio-table th {
    text-align: center;
    vertical-align: middle;
    background-color: #efefed;
    font-weight: bold;
    color: #000;
    border-right: 1px solid #f0f0f0;
  }
  
  .portfolio-table td:first-child {
    text-align: center;
    vertical-align: middle;
  }
  
  .portfolio-table th:last-child, .portfolio-table td:last-child {
    border-right: none;
  }
  
  .portfolio-table tr:hover {
    background-color: #f0f0f0;
  }
  .ant-layout {
    background: #f0f0f0;
  }`, "",{"version":3,"sources":["webpack://./src/components/PositionTable/styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,yBAAyB;EAC3B;;EAEA;IACE,YAAY;IACZ,kBAAkB;IAClB,gCAAgC;IAChC,+BAA+B;EACjC;;EAEA;IACE,kBAAkB;IAClB,sBAAsB;IACtB,yBAAyB;IACzB,iBAAiB;IACjB,WAAW;IACX,+BAA+B;EACjC;;EAEA;IACE,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;EACA;IACE,mBAAmB;EACrB","sourcesContent":[".portfolio-table {\n    width: 100%;\n    border-collapse: separate;\n    border-spacing: 0 0 0 0px;\n  }\n  \n  .portfolio-table th, .portfolio-table td {\n    height: 30px;\n    text-align: center;\n    border-bottom: 1px solid #f0f0f0;\n    border-right: 1px solid #f0f0f0;\n  }\n  \n  .portfolio-table th {\n    text-align: center;\n    vertical-align: middle;\n    background-color: #efefed;\n    font-weight: bold;\n    color: #000;\n    border-right: 1px solid #f0f0f0;\n  }\n  \n  .portfolio-table td:first-child {\n    text-align: center;\n    vertical-align: middle;\n  }\n  \n  .portfolio-table th:last-child, .portfolio-table td:last-child {\n    border-right: none;\n  }\n  \n  .portfolio-table tr:hover {\n    background-color: #f0f0f0;\n  }\n  .ant-layout {\n    background: #f0f0f0;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
