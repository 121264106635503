import { useEffect, useState, FC } from "react";
import TabButton from "./TabButton";
import TabContent from "./TabContent";
import "./styles.css";
import { ActionButtonGroup } from "../ActionButtonGroup";
import { positionViewType } from "@/state/layoutState";
import { useRecoilValue } from "recoil";

interface TabsProps {
  data: any;
  accountId?: string;
  isColumnExpanded: boolean;
  selectedTab?: string;
  onChange?: (activeTab: string) => void;
}



const ActionButtonGroupWrapper = ({ strategyDetails }) => {
  let positions = [];
  const viewType = useRecoilValue(positionViewType);
  if (
    strategyDetails &&
    strategyDetails.accounts &&
    strategyDetails.accounts.length > 0 &&
    strategyDetails.accounts[0] &&
    Array.isArray(strategyDetails.accounts[0].positions)
  ) {
    positions = strategyDetails.accounts[0].positions;
  }

  if (viewType === "strategy") {
    return (
      <ActionButtonGroup
        parentHKBInstrumentId={strategyDetails?.strategy?.parentHKBInstrumentId}
        strategyName={strategyDetails?.strategy?.strategyName}
        strategyInstanceName={strategyDetails?.strategy?.strategyInstanceName}
        disabled={strategyDetails?.strategy?.isReadOnly}
        allPositions={strategyDetails?.strategy?.hkbPositions}
      />
    );
  }
};

const Tabs: FC<TabsProps> = ({
  data,
  accountId = "",
  isColumnExpanded,
  selectedTab = "position",
  onChange = () => { },
}) => {
  // const [activeTab, setActiveTab] = useState(selectedTab);

  // useEffect(() => {
  //   onChange(activeTab);
  // }, [activeTab, onChange]);

  return (
    <div className="tab-container">
      <div className="tab-list">
        <TabButton
          title="Position"
          activeTab={selectedTab}
          setActiveTab={onChange}
        />
        <TabButton
          title="Orders"
          activeTab={selectedTab}
          setActiveTab={onChange}
        />
        {selectedTab === "position" &&
          <div className="action-button">
            <ActionButtonGroupWrapper strategyDetails={data} />
          </div>}
      </div>
      <TabContent
        key={selectedTab}
        isColumnExpanded={isColumnExpanded}
        accountId={accountId}
        activeTab={selectedTab}
        data={data}
      />
    </div>
  );
};

export default Tabs;
