import PositionDetails from "@/components/PositionDetail";
import { Col, Row } from "antd";

const calculateColSpan = (isColumnExpanded, activeTab) => {
  if (isColumnExpanded) {
    return activeTab === "position" ? 8 : 12;
  }
  return 24;
};

const PositionGrid = ({ data, isColumnExpanded, activeTab }) => {
  const colSpan = calculateColSpan(isColumnExpanded, activeTab);

  return (
    <Row gutter={16}>
      {data.map((account, index) => {
        const uniqueKey = `${activeTab}-${account.accountId}-${index}`;
        return (
          <Col span={colSpan} key={uniqueKey}>
            <PositionDetails
              activeTab={activeTab}
              account={account}
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default PositionGrid;
