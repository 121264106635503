import { atom } from 'recoil';

export interface UserState {
    isLoggedIn: boolean;
    userData: {
        email?: string;
        name?: string;
    } | null;
}

export const userState = atom<UserState>({
    key: 'userState',
    default: {
        isLoggedIn: false,
        userData: null,
    },
});
